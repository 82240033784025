import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'

export default {
  getAllCountries: (params) => {
    return repository.get(`/data/countries.json`)
  },
  getCountriesByRegion: (params) => {
    return repository.get(`/data/countries-${params.continent}.json`)
  },
  postForgotPassword: (params) => {
    return repository.post(`${prefix}/forgot-password`, params)
  },
  postNewPassword: (params) => {
    return repository.post(`${prefix}/new-password`, params)
  },
  postRegister: (params) => {
    return repository.post(`${prefix}/register`, params)
  }
}
