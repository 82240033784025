import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getDashBoards: () => {
    return repository.get(`${prefix}${resource}/dashboards`)
  },
  getDashBoardById: (dashboardId) => {
    return repository.get(`${prefix}${resource}/dashboards/${dashboardId}`)
  },
  putDashboards: (dashboardId, params) => {
    return repository.put(
      `${prefix}${resource}/dashboards/${dashboardId}`,
      params
    )
  },
  postDashboardProfile: (dashboardId, params) => {
    return repository.post(
      `${prefix}${resource}/dashboards/${dashboardId}/profiles`,
      params
    )
  },
  putDashboardProfileById: (dashboardId, profileId, params) => {
    return repository.put(
      `${prefix}${resource}/dashboards/${dashboardId}/profiles/${profileId}`,
      params
    )
  },
  deleteDashboardProfileById: (dashboardId, profileId) => {
    return repository.delete(
      `${prefix}${resource}/dashboards/${dashboardId}/profiles/${profileId}`
    )
  },
  getDomains: (dashboardId) => {
    return repository.get(
      `${prefix}${resource}/dashboards/${dashboardId}/domains`
    )
  },
  putDashboardsCurrent: (dashboardId) => {
    return repository.put(
      `${prefix}${resource}/dashboards/${dashboardId}/current-dashboard`
    )
  },
  putDashboardsImages: (data, headers) => {
    return repository.put(`${prefix}${resource}/dashboards/images`, data, {
      headers
    })
  }
}
