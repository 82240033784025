<template>
  <nav
    v-scroll="handleScroll"
    :class="{ _isFixed: checkNav(), active: open }"
    class="GlobalNavigation"
  >
    <div class="GlobalNavigation_Logo">
      <a href="/">
        <img src="/img/logo-bom.svg" class="GlobalNavigation_LogoImage" />
      </a>
      <p class="GlobalNavigation_LogoMessage">Produced by<br />CotoLab. Inc.</p>
    </div>
    <div class="GlobalNavigation_ButtonsSp">
      <a href="/login">ログイン</a>
      <a href="/">無料登録</a>
    </div>
    <ul class="GlobalNavigation_List">
      <li>
        <a @click="anchor(6)">STATEMENT</a>
      </li>
      <li>
        <a @click="anchor(4)">
          PRICING / FUNCTION
        </a>
      </li>
      <li>
        <a href="https://note.com/b_o_m/" target="_blank">NOTE</a>
      </li>
      <li>
        <a
          target="_blank"
          href="https://tayori.com/faq/35b154be3f7a947d36c4ad53953bf4f96d3cbc1c"
        >
          HELP
        </a>
      </li>
      <li class="GlobalNavigation_MarginTopForSp">
        <a href="https://x.com/BOM_MusicTool" target="_blank">
          <XLogo class="GlobalNavigation_LogoPc" />
          <XLogoSP class="GlobalNavigation_LogoSp" />
        </a>
      </li>
      <li class="GlobalNavigation_ListInfoSP">
        <n-link to="/">運営会社</n-link>
        <n-link to="/">プライバシーポリシー</n-link>
        <n-link to="/">お問い合わせ</n-link>
      </li>
    </ul>
    <div class="GlobalNavigation_ButtonsPc">
      <Button>
        <template #content>
          <a class="GlobalNavigation_ButtonsLogin" @click="serviceClose()">
            ログイン
          </a>
        </template>
      </Button>
      <Button>
        <template #content>
          <a class="GlobalNavigation_ButtonsRegister" href="/register">
            無料登録
          </a>
        </template>
      </Button>
    </div>
    <button
      class="GlobalNavigation_MenuButtonSP"
      :class="{ active: open }"
      @click="open = !open"
    >
      <span />
    </button>
  </nav>
</template>
<script>
import { mapMutations } from 'vuex'
import Button from './TopPageButton.vue'
import XLogo from '@/static/img/icon-x-white.svg'
import XLogoSP from '@/static/img/icon-x-white-sp.svg'
export default {
  components: {
    XLogo,
    XLogoSP,
    Button
  },
  data() {
    return {
      showMenu: false,
      scrollY: 0,
      navHeight: 79,
      open: false
    }
  },
  methods: {
    handleScroll(e, el) {
      if (process.client) {
        this.scrollY = window.scrollY
      }
    },
    returnShowMenu() {
      return (this.showMenu = !this.showMenu)
    },
    anchor(section) {
      this.open = false
      this.updateSection({ section })
      sessionStorage.setItem('topPageCurrentSection', section)
      if (this.$route.path === '/statement') {
        window.location.href = '/'
      }
    },
    checkNav() {
      if (this.scrollY > this.navHeight && window.innerWidth < 751) {
        return true
      }
      return false
    },
    serviceClose() {
      alert('2024年8月16日をもって提供を終了いたしました。')
    },
    ...mapMutations('toppage', ['updateSection'])
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';

.GlobalNavigation {
  position: fixed;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 15px 11px;
  @media screen and (min-width: 1206px) {
    right: 0;
    left: 0;
    width: 1206px;
    padding: 29px 0 0;
    margin: auto;
  }
  @media screen and (max-width: 750px) {
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    background: #000;
    transition: height 0.3s ease-out;
  }
  @media screen and (min-width: 751px) {
    height: auto;
  }
  &_Logo,
  &_List,
  &_Buttons {
    display: flex;
    align-items: center;
  }
  &_Logo {
    margin-bottom: 17px;
    @media screen and (min-width: 751px) {
      margin-bottom: 0;
    }
  }
  &_LogoImage {
    width: 125px;
    @media screen and (min-width: 751px) {
      width: auto;
    }
  }
  &.active {
    height: 100vh;
    .GlobalNavigation_ButtonsSp,
    .GlobalNavigation_List {
      display: flex;
    }
  }
  &_LogoMessage {
    margin-left: 25px;
    font-size: 10px;
    line-height: 14px;
    @media screen and (min-width: 751px) {
      margin-left: 25px;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.1em;
    }
  }
  &_List {
    @media screen and (min-width: 751px) {
      margin-left: -77px;
    }
    @media screen and (max-width: 750px) {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 0;
    }
    li {
      margin: 0;
      @media screen and (max-width: 750px) {
        margin: 30px 9px 0;
      }
      a {
        font-size: 30px;
        font-weight: bold;
        cursor: pointer;
        @media screen and (min-width: 1206px) {
          font-size: 14px;
          letter-spacing: 0.1em;
        }
      }
      &:nth-child(1) {
        @media screen and (min-width: 751px) {
          margin-right: 38px;
        }
      }
      &:nth-child(2) {
        @media screen and (min-width: 751px) {
          margin-right: 40px;
        }
      }
      &:nth-child(3) {
        @media screen and (min-width: 751px) {
          margin-right: 34px;
        }
      }
      &:nth-child(4) {
        @media screen and (min-width: 751px) {
          margin-right: 50px;
        }
      }
    }
  }
  &_ButtonsPc {
    display: flex;
    @media screen and (max-width: 750px) {
      display: none;
    }
    .GlobalNavigation_ButtonsLogin {
      background: rgba(255, 255, 255, 0.2);
    }
    .GlobalNavigation_ButtonsRegister {
      color: #ffc107;
      background: rgba(255, 193, 7, 0.2);
      border: 1px solid #ffc107 !important;
    }
  }
  &_ButtonsSp {
    display: none;
    @media screen and (min-width: 320px) {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
    a {
      display: block;
      width: 50%;
      padding: 18.5px 0;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.1em;
      &:first-child {
        position: relative;
        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          content: '';
          transform: translateY(-50%);
          @media screen and (min-width: 320px) {
            height: 49px;
            border-right: 1px solid #fff;
          }
        }
      }
      &:last-child {
        color: #ffc107;
      }
    }
  }
  &_ListInfoSP {
    @media screen and (min-width: 751px) {
      display: none;
    }
    a {
      display: block;
      margin-top: 15px;
      font-size: 14px !important;
      font-weight: normal !important;
    }
  }
  &_MenuButtonSP {
    position: absolute;
    top: 22px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 18px;
    height: 16px;
    transition: all 0.3s ease-out;
    @media screen and (min-width: 751px) {
      display: none;
    }
    &:before,
    &:after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      background: #fff;
      transform-origin: left;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    span {
      display: block;
      width: 12px;
      height: 2px;
      background: #fff;
    }
    &.active {
      &:before {
        top: 0;
        transform: rotate(45deg);
      }
      &:after {
        bottom: 2px;
        transform: rotate(-45deg);
      }
      span {
        opacity: 0;
      }
    }
  }
  &_LogoPc {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  &_LogoSp {
    @media screen and (min-width: 751px) {
      display: none;
    }
  }
  &_MarginTopForSp {
    @media screen and (max-width: 750px) {
      margin-top: 79px !important;
    }
  }
}

._isFixed {
  .GlobalNavigation_ButtonsPc {
    right: 50px;
    width: 186px;
    @media screen and (max-width: 750px) {
      position: absolute;
      display: flex;
      justify-content: space-between;
    }
    .ButtonComponent {
      margin-left: 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 30px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 10px;
      }
    }
  }
  .GlobalNavigation_LogoMessage {
    display: none;
  }
  .GlobalNavigation_Logo {
    margin: 1vw 0 5vw;
  }
  .GlobalNavigation_LogoImage {
    width: 99px;
    @media screen and (min-width: 751px) {
      width: auto;
    }
  }
}
</style>
