<template>
  <div class="NoAuthorised">
    <TheFlashMessage v-show="$store.state.flashMessages.length > 0" />
    <nuxt />
  </div>
</template>
<script>
import { CheckLoginStrategy } from '@/middleware/auth'
import TheFlashMessage from '@/components/TheFlashMessage'
export default {
  name: 'NoAuthorised',
  components: {
    TheFlashMessage
  },
  middleware: CheckLoginStrategy,
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      const error = this.$route.query.error
      if (error) {
        switch (error) {
          case 'auth':
            this.$store.dispatch('showFlashMessage', {
              text: '一定時間経過のためログアウト',
              type: 'error'
            })
            break
          default:
            break
        }
      }
    })
  }
}
</script>
