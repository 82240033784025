export const state = () => ({
  currentSection: ''
})

export const mutations = {
  updateSection(state, { section }) {
    state.currentSection = section
  }
}

export const getters = {
  currentSection: (state) => {
    return state.currentSection
  }
}
