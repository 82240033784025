import { repository } from '../plugins/axios'

export default {
  getNote() {
    const result = repository.get(
      'https://api.tera-chan.com/api/ignore_cors/v1.php?resource=https://note.com/b_o_m/rss'
    )
    return result
  }
}
