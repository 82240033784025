import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/payment'

export default {
  postCheckout: (params) => {
    return repository.post(`${prefix}${resource}/checkout`, params)
  }
}
