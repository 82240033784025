import { repository } from '../plugins/axios'
const endpoint = 'https://api.music.apple.com'
const jsCdnEndpoint = 'https://js-cdn.music.apple.com'

export default {
  getMusicKitFromCdn: () => {
    return repository.get(`${jsCdnEndpoint}/musickit/v1/musickit.js`)
  },
  getCatalogInfo: (
    urlParamBefore,
    urlParamAfter,
    targetType,
    headers,
    params = {}
  ) => {
    return repository.get(
      `${endpoint}/v1/catalog/${urlParamBefore}/${targetType}/${urlParamAfter}`,
      {
        params,
        headers
      }
    )
  },
  postPresave: (targetType, targetId, headers, params = {}) => {
    return repository.post(
      `${endpoint}/v1/me/library?ids[${targetType}]=${targetId}`,
      {
        params,
        headers
      }
    )
  }
}
