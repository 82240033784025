import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getLinksInsights: (params) => {
    return repository.get(`${prefix}${resource}/links/insights`, {
      params
    })
  },
  getLinkInsightsById: (linkId, params) => {
    return repository.get(`${prefix}${resource}/links/${linkId}/insights`, {
      params
    })
  },
  getLinksInsightsFile: (params) => {
    return repository.get(`${prefix}${resource}/links/insights/file`, {
      params
    })
  },
  getLinksInsightsFileById: (linkId, params) => {
    return repository.get(
      `${prefix}${resource}/links/insights/${linkId}/file`,
      {
        params
      }
    )
  },
  getLinksInsightsTopService: (params) => {
    return repository.get(`${prefix}${resource}/links/insights/top-service`, {
      params
    })
  },
  getLinkInsightsTopServiceById: (linkId, params) => {
    return repository.get(
      `${prefix}${resource}/links/insights/${linkId}/top-service`,
      {
        params
      }
    )
  },
  getLinksInsightsTopRegions: (params) => {
    return repository.get(`${prefix}${resource}/links/insights/top-regions`, {
      params
    })
  },
  getLinkInsightsTopRegionsById: (linkId, params) => {
    return repository.get(
      `${prefix}${resource}/links/insights/${linkId}/top-regions`,
      {
        params
      }
    )
  },
  getLinksInsightsTopReferences: (params) => {
    return repository.get(
      `${prefix}${resource}/links/insights/top-references`,
      {
        params
      }
    )
  },
  getLinkInsightsTopReferencesById: (linkId, params) => {
    return repository.get(
      `${prefix}${resource}/links/insights/${linkId}/top-references`,
      {
        params
      }
    )
  },
  getLinksInsightsTopLinks: (params) => {
    return repository.get(`${prefix}${resource}/links/insights/top-links`, {
      params
    })
  }
}
