import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getDataCoordinations: () => {
    return repository.get(`${prefix}${resource}/data-coordinations`)
  },
  deleteDataCoordinations: (dashboardId) => {
    return repository.delete(
      `${prefix}${resource}/data-coordinations/${dashboardId}`
    )
  },
  getDataCoordinationLinks: (dashboardId) => {
    return repository.get(
      `${prefix}${resource}/data-coordinations/${dashboardId}/links`
    )
  },
  getDataCoordinationLinksByStatus: (dashboardId, status) => {
    return repository.get(
      `${prefix}${resource}/data-coordinations/${dashboardId}/links?status=${status}`
    )
  },
  getDataCoordinationApplications: () => {
    return repository.get(
      `${prefix}${resource}/data-coordinations/applications`
    )
  },
  postDataCoordinationApplications: (params) => {
    return repository.post(
      `${prefix}${resource}/data-coordinations/applications`,
      params
    )
  },
  putDataCoordinationApplications: (dashboardId, params) => {
    return repository.put(
      `${prefix}${resource}/data-coordinations/applications/${dashboardId}`,
      params
    )
  },
  deleteDataCoordinationsApplications: (dashboardId) => {
    return repository.delete(
      `${prefix}${resource}/data-coordinations/applications/${dashboardId}`
    )
  },
  getDataCoordinationsDetached: () => {
    return repository.get(`${prefix}${resource}/data-coordinations/detached`)
  },
  getDataCoordinationDetachedLinks: (dashboardId) => {
    return repository.get(
      `${prefix}${resource}/data-coordinations/detached/${dashboardId}/links`
    )
  },
  getDataCoordinationDetachedLinksByStatus: (dashboardId, status) => {
    return repository.get(
      `${prefix}${resource}/data-coordinations/detached/${dashboardId}/links?status=${status}`
    )
  }
}
