<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-toggle target="mr-auto nav_collapse"></b-navbar-toggle>
    <b-collapse id="nav_collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/admin/">管理画面トップ</b-nav-item>
        <b-nav-item href="/admin/users">ユーザ一覧</b-nav-item>
        <b-nav-item href="/admin/statistics">統計情報</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    <b-navbar-nav class="ml-auto">
      <b-button variant="primary" size="sm" @click="logout"
        >ログアウト
      </b-button>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
export default {
  methods: {
    async logout() {
      this.$router.push({ path: '/admin/login' })
      try {
        await this.$auth.logout('admin')
      } catch (e) {
        window.alert(`ログアウトに失敗しました(${e})`)
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';

.navbar-dark .navbar-nav .nav-link {
  color: var(--c-white-base);
  &:hover {
    opacity: 0.7;
  }
}
</style>
