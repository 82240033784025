<template>
  <div>
    mockup
    <TheGlobalHeader v-if="isLogin" />
    <TheGlobalHeaderAdmin v-if="isLoginAdmin" />
    <nuxt />
  </div>
</template>

<script>
import Vue from 'vue'
import BotstrapVue from 'bootstrap-vue'
import TheGlobalHeader from '@/components/TheGlobalHeaderMock.vue'
import TheGlobalHeaderAdmin from '@/components/TheGlobalHeaderAdmin.vue'
Vue.use(BotstrapVue)

export default {
  head() {
    return {
      title: 'testest',
      script: [
        { src: 'https://code.jquery.com/jquery-3.3.1.slim.min.js' },
        {
          src:
            'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js'
        },
        {
          src:
            'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js'
        }
      ],
      link: [
        {
          rel: 'stylesheet',
          href:
            'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
        }
      ]
    }
  },
  components: {
    TheGlobalHeader,
    TheGlobalHeaderAdmin
  },
  computed: {
    isLogin() {
      return this.$route.path.match(/user/) !== null
    },
    isLoginAdmin() {
      return (
        this.$route.path.match(/admin/) !== null &&
        this.$route.path.match(/admin\/login/) === null
      )
    }
  }
}
</script>
