import Vue from 'vue'
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

Vue.component('LineChart', {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      unWatchFunc: null
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
    this.$nextTick(() => {
      this.unWatchFunc = this.$watch('chartData', () => {
        const el = document.getElementById('line-chart')
        if (el) {
          const startColor = this.chartData.datasets[0].backgroundColor
          const endColor = 'rgba(0, 0, 0, 0.1)'
          const ctx = el.getContext('2d')
          const gradient = ctx.createLinearGradient(0, 1, 0, 350)
          gradient.addColorStop(0, startColor)
          gradient.addColorStop(0.65, endColor)
          const chartCommonStyle = {
            backgroundColor: gradient,
            backgroundOverlayMode: 'source-over'
          }
          this.chartData.datasets[0] = {
            ...this.chartData.datasets[0],
            ...chartCommonStyle
          }
          this.renderChart(this.chartData, this.options)
        }
        this.$emit('emit-chartjs-params', {
          chartObj: this.$data._chart,
          canvasEl: this.$refs.canvas
        })
      })
    })
  },
  beforeDestroy() {
    this.unWatchFunc()
  }
})
