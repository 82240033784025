var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TheGlobalHeader"},[_c('div',{staticClass:"TheGlobalHeader_Wrapper"},[_c('div',{staticClass:"TheGlobalHeader_Logo"},[_c('n-link',{attrs:{"to":{ path: ("/user/" + (this.$route.params.userId) + "/analytics") }}},[_c('Logo',{staticClass:"TheGlobalHeader_LogoImage"})],1)],1),_vm._v(" "),_c('div',{staticClass:"TheGlobalHeader_Navigation"},[_c('n-link',{staticClass:"TheGlobalHeader_NavigationLink",class:{ _isActive: _vm.$route.name === 'user-userId-analytics' },attrs:{"to":{
          path: ("/user/" + (this.$route.params.userId) + "/analytics?type=pageview&filterRegion=global")
        }}},[_vm._v("\n        アナリティクス\n      ")]),_vm._v(" "),_c('n-link',{staticClass:"TheGlobalHeader_NavigationLink",class:{ _isActive: _vm.$route.name === 'user-userId' },attrs:{"to":{ path: ("/user/" + (this.$route.params.userId) + "/") }}},[_vm._v("\n        リンク一覧\n      ")])],1),_vm._v(" "),(!_vm.isEdit)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickDashboard),expression:"onClickDashboard"}],staticClass:"TheGlobalHeader_SelectUser"},[_c('a',{staticClass:"TheGlobalHeader_SelectButton",class:{ _isActive: _vm.selectDashboard === true },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.selectDashboard = _vm.selectDashboard !== true}}},[_c('img',{staticClass:"TheGlobalHeader_SelectUserImage",attrs:{"src":_vm.currentDashboardImage,"alt":"ダッシュボード画像"}}),_vm._v(" "),_c('span',{staticClass:"TheGlobalHeader_SelectUserName"},[_vm._v("\n          "+_vm._s(_vm.$store.getters.currentDashboardDefaultProfile.displayName ||
              _vm.$store.state.user.displayName)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"TheGlobalHeader_SelectListWrapper"},[_c('div',{staticClass:"TheGlobalHeader_ListHeadline"},[_vm._v("\n            ログインアカウント\n          ")]),_vm._v(" "),_vm._l((_vm.$store.state.dashboards),function(dashboard){return _c('div',{key:dashboard.daashboardId,staticClass:"TheGlobalHeader_AccountList",class:{
              _isActive:
                _vm.$store.state.user.currentDashboardId === dashboard.dashboardId
            },on:{"click":function($event){return _vm.changeCurrentDashboard(dashboard.dashboardId)}}},[_c('img',{staticClass:"TheGlobalHeader_AccountImage",attrs:{"src":_vm.defaultProfile(dashboard).imageUrl || '/img/no-image.png',"alt":"ユーザー画像"}}),_vm._v(" "),_c('span',{staticClass:"TheGlobalHeader_AccountName"},[_vm._v("\n              "+_vm._s(_vm.defaultProfile(dashboard).displayName ||
                  _vm.$store.state.user.displayName)+"\n            ")])])})],2)])]):_vm._e(),_vm._v(" "),(!_vm.isEdit)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickPreferences),expression:"onClickPreferences"}],staticClass:"TheGlobalHeader_Preferences"},[_c('div',{staticClass:"TheGlobalHeader_PreferencesButton",class:{ _isActive: _vm.selectPreferences === true },on:{"click":function($event){_vm.selectPreferences = _vm.selectPreferences !== true}}},[_c('IconPreference',{staticClass:"TheGlobalHeader_PreferencesImage"}),_vm._v(" "),_c('div',{staticClass:"TheGlobalHeader_PreferencesWrapper"},[_c('div',{staticClass:"TheGlobalHeader_ListHeadline"},[_vm._v("\n            ユーザー設定\n          ")]),_vm._v(" "),_c('div',{staticClass:"TheGlobalHeader_PreferencesList",on:{"click":function($event){return _vm.logout()}}},[_vm._v("\n            ログアウト\n          ")]),_vm._v(" "),_c('div',{on:{"click":function($event){return _vm.member()}}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              メンバー管理\n            ")])]),_vm._v(" "),_c('div',{staticClass:"TheGlobalHeader_ListHeadline"},[_vm._v("\n            アカウント設定\n          ")]),_vm._v(" "),_c('n-link',{attrs:{"to":{
              path: ("/user/" + (_vm.$route.params.userId) + "/account/profile")
            }}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              プロフィール設定\n            ")])]),_vm._v(" "),_c('n-link',{attrs:{"to":("/user/" + (_vm.$route.params.userId) + "/account/preferences")}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              アカウント設定管理\n            ")])]),_vm._v(" "),_c('n-link',{attrs:{"to":("/user/" + (_vm.$route.params.userId) + "/connect")}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              データ連携管理\n            ")])]),_vm._v(" "),_c('div',{staticClass:"TheGlobalHeader_ListHeadline"},[_vm._v("\n            その他\n          ")]),_vm._v(" "),_c('n-link',{attrs:{"to":("/user/" + (_vm.$route.params.userId) + "/linkfire-import")}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              Linkfireインポート\n            ")])]),_vm._v(" "),_c('n-link',{attrs:{"to":("/user/" + (_vm.$route.params.userId) + "/linkcore-import")}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              LinkCoreインポート\n            ")])]),_vm._v(" "),_c('n-link',{attrs:{"to":("/user/" + (_vm.$route.params.userId) + "/sfp-import")}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              Spotify for Podcastersインポート\n            ")])]),_vm._v(" "),_vm._m(0)],1)],1)]):_c('div',{staticClass:"TheGlobalHeader_Edit"},[_c('n-link',{attrs:{"to":{ path: ("/user/" + (_vm.$route.params.userId) + "/") }}},[_c('button',{staticClass:"TheGlobalHeader_Cancel"},[_vm._v("\n          キャンセル\n        ")])]),_vm._v(" "),_c('button',{staticClass:"TheGlobalHeader_Preview",on:{"click":function($event){return _vm.preview()}}},[_vm._v("\n        プレビュー\n      ")]),_vm._v(" "),_c('button',{staticClass:"TheGlobalHeader_Save",on:{"click":function($event){return _vm.update()}}},[_vm._v("\n        更新\n      ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://form.run/@bom--1592908099","target":"_blank"}},[_c('div',{staticClass:"TheGlobalHeader_PreferencesList"},[_vm._v("\n              お問い合わせ\n            ")])])}]

export { render, staticRenderFns }