<template>
  <div class="TeaserDefault">
    <template v-if="$route.query.inDevelop">
      <div
        class="TeaserDefault_Modal"
        :class="{ _isModal: $route.query.inDevelop }"
      >
        <div class="TeaserDefault_ModalContent">
          <div class="TeaserDefault_Head">
            スマホ版は現在開発中
          </div>
          <div class="TeaserDefault_Body">
            <p class="TeaserDefault_ModalText">
              現在PC版のみ公開されています<br />
              スマホ版対応は今しばらくお待ち下さい
            </p>
            <div class="TeaserDefault_ButtonWrapper">
              <button class="TeaserDefault_Cancel" @click="close">
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="
        $route.name !== 'blog-posts-postId' &&
          $route.name !== 'sp-blog-posts-postId'
      "
      class="TeaserDefault_Background"
    ></div>
    <header class="TeaserDefault_Header">
      <TeaserNavigationBar />
    </header>
    <main class="TeaserDefault_Main">
      <nuxt />
    </main>
    <TheTeaserFooter />
  </div>
</template>
<script>
import TeaserNavigationBar from '~/components/TeaserNavigationBar'
import TheTeaserFooter from '~/components/TheTeaserFooter'

export default {
  components: {
    TeaserNavigationBar,
    TheTeaserFooter
  },
  methods: {
    close() {
      const query = Object.assign({}, this.$route.query)
      delete query.inDevelop
      this.$router.push({ query })
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TeaserDefault {
  padding: 0 0 50px 0;
  @media (min-width: 751px) {
    min-width: var(--width-pc-container);
    padding: initial;
  }
  &_Background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 254px;
    background: linear-gradient(rgba(30, 30, 30, 0), rgba(33, 33, 33, 1)),
      url('/img/background-head-teaser-top-sp.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 751px) {
      min-width: var(--width-pc-container);
      height: auto;
      min-height: 704px;
      background: linear-gradient(rgba(33, 33, 33, 0.54), rgba(33, 33, 33, 1)),
        url('/img/background-head-teaser-top-pc.jpg');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
    }
  }
  &_Header {
    position: sticky;
    top: 0;
    z-index: var(--zi-teaser-header);
  }
  &_HeaderImage {
    height: 93px;
    @media (min-width: 751px) {
      width: 100%;
      height: 234px;
      object-fit: cover;
    }
  }
  &_Main {
    position: relative;
    margin: auto;
    /* @media (min-width: 751px) {
      max-width: var(--width-pc-container);
    } */
  }
  &_FixedNavigation {
    &._isFixed {
      position: sticky;
      top: 0;
      left: 0;
      background-color: var(--c-black-base);
    }
  }
  &_HeaderMessage {
    display: none;
    @media (min-width: 751px) {
      position: absolute;
      top: 50%;
      display: block;
      width: 100%;
      font-family: Avenir;
      font-size: 60px;
      font-weight: 900;
      color: transparent;
      text-align: center;
      letter-spacing: 0.2em;
      mix-blend-mode: overlay;
      -webkit-text-stroke: 2px var(--c-white-base);
    }
  }
  &_Modal {
    &._isModal {
      @util position(fixed, 0 0 0 0);
      z-index: var(--zi-themodal);
      width: 100%;
      height: 100%;
      margin: auto;
      background-color: var(--c-black-1);
    }
  }
  &_ModalContent {
    @util center;
    min-width: 90%;
    @media (min-width: 751px) {
      min-width: 403px;
    }
  }
  &_Head {
    padding: 11.5px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    text-align: center;
    background-color: var(--c-green-3);
    @util border-top-radius(10px);
    @media (min-width: 751px) {
      padding: 11.5px 0;
      font-size: 18px;
      @util border-top-radius(10px);
    }
  }
  &_Body {
    padding: 26px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-black-base);
    border-right: 1px solid var(--c-gray-3);
    border-bottom: 1px solid var(--c-gray-3);
    border-left: 1px solid var(--c-gray-3);
    @util border-bottom-radius(10px);
    @media (min-width: 751px) {
      padding: 26px 0;
      font-size: 18px;
      @util border-bottom-radius(10px);
      border-right: 1px solid var(--c-gray-3);
      border-bottom: 1px solid var(--c-gray-3);
      border-left: 1px solid var(--c-gray-3);
    }
  }
  &_ModalText {
    padding: 0 27px 33px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    line-height: 1.71;
    color: var(--c-white-base);
    @media (min-width: 751px) {
      padding: 0 27px 33px;
      font-size: 14px;
    }
  }
  &_ButtonWrapper {
    width: 180px;
    margin: auto;
    @media (min-width: 751px) {
      width: 180px;
    }
  }
  &_Cancel {
    width: 100%;
    height: 50px;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 2px solid var(--c-gray-1);
    border-radius: 50px;
    @media (min-width: 751px) {
      height: 50px;
      margin: 0 15px 0 0;
      font-size: 14px;
      border: 2px solid var(--c-gray-1);
      border-radius: 50px;
    }
  }
}
</style>
