<template>
  <nav class="TeaserMenu">
    <div class="TeaserMenu_Background" @click="onClickCloseButton" />
    <div class="TeaserMenu_Board">
      <div class="TeaserMenu_Header">
        <span class="TeaserMenu_Heading">MENU</span>
        <button
          class="TeaserMenu_CloseButton"
          @click="onClickCloseButton"
        ></button>
      </div>
      <div class="TeaserMenu_ButtonWrapper">
        <n-link to="/login">
          <button class="TeaserMenu_Login">
            ログイン
            <IconUser class="TeaserMenu_IconUser" />
          </button>
        </n-link>
        <n-link to="/register">
          <button class="TeaserMenu_Register">無料登録</button>
        </n-link>
      </div>
      <ul class="TeaserMenu_List">
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="/">
            <span class="TeaserMenu_Text">トップページ</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="/about"
            ><span class="TeaserMenu_Text">B.O.Mについて</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="/pricing"
            ><span class="TeaserMenu_Text">料金プラン/機能一覧</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="/blog/posts"
            ><span class="TeaserMenu_Text">ブログ</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a
            class="TeaserMenu_Link"
            target="_blank"
            href="https://tayori.com/faq/35b154be3f7a947d36c4ad53953bf4f96d3cbc1c"
            ><span class="TeaserMenu_Text">ヘルプセンター</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a
            class="TeaserMenu_Link"
            href="https://form.run/@bom--1592908099"
            target="_blank"
            ><span class="TeaserMenu_Text">お問い合わせ</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="https://cotolab.com" target="_blank"
            ><span class="TeaserMenu_Text">運営会社</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="/privacy-policy"
            ><span class="TeaserMenu_Text">プライバシーポリシー</span></a
          >
        </li>
        <li class="TeaserMenu_Item">
          <a class="TeaserMenu_Link" href="/specified-commerial-transaction"
            ><span class="TeaserMenu_Text">特定商取引法に基づく表記</span></a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import IconUser from '@/static/img/icon-user.svg'

export default {
  components: {
    IconUser
  },
  methods: {
    onClickCloseButton() {
      this.$emit('click-close')
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TeaserMenu {
  position: absolute;
  z-index: var(--zi-teaser-menu);
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  &_Background {
    flex: 1;
    height: 100%;
    background-color: var(--c-black-base);
    opacity: 0.8;
  }
  &_Board {
    width: 320px;
    height: 100%;
    background-color: var(--c-black-base);
  }
  &_Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    margin-bottom: 1px;
    background-color: var(--c-black-2);
  }
  &_Heading {
    margin-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: var(--c-gray-3);
    letter-spacing: 0.03em;
  }
  &_CloseButton {
    width: 50px;
    height: 100%;
    &::before {
      position: absolute;
      top: 26px;
      left: 11px;
      display: block;
      width: 18px;
      height: 18px;
      content: '';
      border-top: 2px solid var(--c-white-base);
      transform: rotate(45deg);
    }
    &::after {
      position: absolute;
      top: 26px;
      left: 22px;
      display: block;
      width: 18px;
      height: 18px;
      content: '';
      border-top: 2px solid var(--c-white-base);
      transform: rotate(-45deg);
    }
  }
  &_List {
    width: 100%;
    height: 72vh;
    overflow: scroll;
  }
  &_Item {
    height: 60px;
    margin-bottom: 1px;
    background-color: var(--c-black-2);
    &:nth-of-type(5) {
      margin-bottom: 16px;
    }
  }
  &_Link {
    display: flex;
    width: 100%;
    height: 100%;
  }
  &_Text {
    margin: auto 0 auto 15px;
    font-weight: bold;
    letter-spacing: 0.03em;
  }
  &_ButtonWrapper {
    display: flex;
    justify-content: space-between;
    padding: 13px 15px;
  }
  &_Login {
    position: relative;
    width: 140px;
    height: 50px;
    padding: 0 0 0 14.1px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 2px solid var(--c-gray-3);
    border-radius: 50px;
  }
  &_Register {
    width: 140px;
    height: 50px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-green-4);
    border: 2px solid var(--c-green-3);
    border-radius: 50px;
  }
  &_IconUser {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 29.8px;
    margin: auto;
    fill: var(--c-white-base);
    transition: all 250ms ease-in-out;
    &._isFixed {
      @media (min-width: 751px) {
        fill: var(--c-gray-3);
        transition: all 250ms ease-in-out;
      }
    }
  }
}
</style>
