<template>
  <div class="TheModal">
    <div v-if="$route.query.modal === 'upgrade'" class="TheModal_Content">
      <div class="TheModal_Head">
        プラン適応外
      </div>
      <div class="TheModal_Body">
        <p class="TheModal_ModalText">
          現在のプランでは使用できません<br />
          使用するにはプランのアップグレードをお願いします。
        </p>
        <div class="TheModal_ButtonWrapper">
          <a href="/pricing" target="_blank">
            <button class="TheModal_Exec">
              プラン比較へ
            </button>
          </a>
          <button class="TheModal_Cancel" @click="close">
            閉じる
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      const query = Object.assign({}, this.$route.query)
      delete query.modal
      this.$router.push({ query })
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '@/assets/css/variables';

.TheModal {
  @util position(fixed, 0 0 0 0);
  z-index: var(--zi-themodal);
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: var(--c-black-1);
  &_Content {
    @util center;
    min-width: 289px;
    max-width: 403px;
  }
  &_Head {
    padding: 11.5px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    text-align: center;
    background-color: var(--c-green-3);
    @util border-top-radius(10px);
  }
  &_Body {
    padding: 26px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-black-base);
    border-right: 1px solid var(--c-gray-3);
    border-bottom: 1px solid var(--c-gray-3);
    border-left: 1px solid var(--c-gray-3);
    @util border-bottom-radius(10px);
  }
  &_ButtonWrapper {
    width: 180px;
    margin: auto;
  }
  &_DeleteButton {
    width: 230px;
    padding: 17px 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-red-base);
    border-radius: 50px;
  }
  &_Cancel {
    width: 100%;
    height: 50px;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 2px solid var(--c-gray-1);
    border-radius: 50px;
  }
  &_Exec {
    width: 100%;
    height: 50px;
    margin: 0 0 12px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 2px var(--c-red-base) solid;
    border-radius: 50px;
  }
  &_ModalText {
    padding: 0 27px 33px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    line-height: 1.71;
    color: var(--c-white-base);
  }
}
</style>
