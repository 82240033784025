<template>
  <div class="TheGlobalHeader">
    <div class="TheGlobalHeader_Wrapper">
      <div class="TheGlobalHeader_Logo">
        <n-link :to="{ path: `/user/${this.$route.params.userId}/analytics` }">
          <Logo class="TheGlobalHeader_LogoImage" />
        </n-link>
      </div>
      <div class="TheGlobalHeader_Navigation">
        <n-link
          :class="{ _isActive: $route.name === 'user-userId-analytics' }"
          :to="{
            path: `/user/${this.$route.params.userId}/analytics?type=pageview&filterRegion=global`
          }"
          class="TheGlobalHeader_NavigationLink"
        >
          アナリティクス
        </n-link>
        <n-link
          :class="{ _isActive: $route.name === 'user-userId' }"
          :to="{ path: `/user/${this.$route.params.userId}/` }"
          class="TheGlobalHeader_NavigationLink"
        >
          リンク一覧
        </n-link>
        <!-- <n-link
          :class="{ _isActive: $route.name === 'user-userId-campaigns' }"
          :to="{ path: `/user/${this.$route.params.userId}/campaigns` }"
          class="TheGlobalHeader_NavigationLink"
        >
          キャンペーンページ
        </n-link> -->
      </div>
      <div
        v-if="!isEdit"
        v-click-outside="onClickDashboard"
        class="TheGlobalHeader_SelectUser"
      >
        <a
          :class="{ _isActive: selectDashboard === true }"
          class="TheGlobalHeader_SelectButton"
          href="javascript:void(0)"
          @click="selectDashboard = selectDashboard !== true"
        >
          <img
            :src="currentDashboardImage"
            class="TheGlobalHeader_SelectUserImage"
            alt="ダッシュボード画像"
          />
          <span class="TheGlobalHeader_SelectUserName">
            {{
              $store.getters.currentDashboardDefaultProfile.displayName ||
                $store.state.user.displayName
            }}
          </span>
          <div class="TheGlobalHeader_SelectListWrapper">
            <div class="TheGlobalHeader_ListHeadline">
              ログインアカウント
            </div>
            <div
              v-for="dashboard in $store.state.dashboards"
              :key="dashboard.daashboardId"
              class="TheGlobalHeader_AccountList"
              :class="{
                _isActive:
                  $store.state.user.currentDashboardId === dashboard.dashboardId
              }"
              @click="changeCurrentDashboard(dashboard.dashboardId)"
            >
              <img
                :src="defaultProfile(dashboard).imageUrl || '/img/no-image.png'"
                class="TheGlobalHeader_AccountImage"
                alt="ユーザー画像"
              />
              <span class="TheGlobalHeader_AccountName">
                {{
                  defaultProfile(dashboard).displayName ||
                    $store.state.user.displayName
                }}
              </span>
            </div>
          </div>
        </a>
      </div>
      <div
        v-if="!isEdit"
        v-click-outside="onClickPreferences"
        class="TheGlobalHeader_Preferences"
      >
        <div
          :class="{ _isActive: selectPreferences === true }"
          class="TheGlobalHeader_PreferencesButton"
          @click="selectPreferences = selectPreferences !== true"
        >
          <IconPreference class="TheGlobalHeader_PreferencesImage" />
          <div class="TheGlobalHeader_PreferencesWrapper">
            <div class="TheGlobalHeader_ListHeadline">
              ユーザー設定
            </div>
            <div class="TheGlobalHeader_PreferencesList" @click="logout()">
              ログアウト
            </div>
            <div @click="member()">
              <div class="TheGlobalHeader_PreferencesList">
                メンバー管理
              </div>
            </div>
            <div class="TheGlobalHeader_ListHeadline">
              アカウント設定
            </div>
            <n-link
              :to="{
                path: `/user/${$route.params.userId}/account/profile`
              }"
            >
              <div class="TheGlobalHeader_PreferencesList">
                プロフィール設定
              </div>
            </n-link>
            <n-link :to="`/user/${$route.params.userId}/account/preferences`">
              <div class="TheGlobalHeader_PreferencesList">
                アカウント設定管理
              </div>
            </n-link>
            <n-link :to="`/user/${$route.params.userId}/connect`">
              <div class="TheGlobalHeader_PreferencesList">
                データ連携管理
              </div>
            </n-link>
            <div class="TheGlobalHeader_ListHeadline">
              その他
            </div>
            <n-link :to="`/user/${$route.params.userId}/linkfire-import`">
              <div class="TheGlobalHeader_PreferencesList">
                Linkfireインポート
              </div>
            </n-link>
            <n-link :to="`/user/${$route.params.userId}/linkcore-import`">
              <div class="TheGlobalHeader_PreferencesList">
                LinkCoreインポート
              </div>
            </n-link>
            <n-link :to="`/user/${$route.params.userId}/sfp-import`">
              <div class="TheGlobalHeader_PreferencesList">
                Spotify for Podcastersインポート
              </div>
            </n-link>
            <a href="https://form.run/@bom--1592908099" target="_blank">
              <div class="TheGlobalHeader_PreferencesList">
                お問い合わせ
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="TheGlobalHeader_Edit">
        <n-link :to="{ path: `/user/${$route.params.userId}/` }">
          <button class="TheGlobalHeader_Cancel">
            キャンセル
          </button>
        </n-link>
        <button class="TheGlobalHeader_Preview" @click="preview()">
          プレビュー
        </button>
        <button class="TheGlobalHeader_Save" @click="update()">
          更新
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import Logo from '@/static/img/logo.svg'
import IconPreference from '@/static/img/icon-preference.svg'
const MeRepository = RepositoryFactory.get('me')
export default {
  components: {
    IconPreference,
    Logo
  },
  data() {
    return {
      selectDashboard: false,
      selectPreferences: false
    }
  },
  computed: {
    currentDashboardImage() {
      const index = this.$store.state.dashboards.findIndex(
        (dashboard) =>
          dashboard.dashboardId === this.$store.state.user.currentDashboardId &&
          dashboard.profiles[0].imageUrl
      )
      if (index === -1) {
        return '/img/no-image.png'
      }
      return this.$store.state.dashboards[index].profiles[0].imageUrl
    },
    isEdit() {
      return this.$route.path.match(/edit/) !== null
    },
    userImage() {
      return this.$store.state.user.imageUrl
        ? this.$store.state.user.imageUrl
        : 'http://placehold.jp/3d4070/ffffff/300x300.png?text=User'
    }
  },
  methods: {
    async changeCurrentDashboard(dashboardId) {
      if (this.$store.state.user.currentDashboardId === dashboardId) {
        return
      }
      try {
        const response = await MeRepository.putDashboardsCurrent(dashboardId)
        if (response.data.result !== 'true') {
          throw new Error('切り替えに失敗しました')
        }
        location.reload()
      } catch (e) {
        console.error(e)
      }
    },
    defaultProfile(dashboard) {
      return dashboard.profiles.find(
        (profile) => profile.isDefaultProfile === true
      )
    },
    async logout() {
      try {
        await this.$auth.logout('user')
        this.$router.push({ path: '/login' })
      } catch (e) {
        window.alert(`ログアウトに失敗しました(${e})`)
      }
    },
    member() {
      this.$router.push({ path: `/user/${this.$route.params.userId}/member` })
    },
    onClickDashboard() {
      this.selectDashboard = false
    },
    onClickPreferences() {
      this.selectPreferences = false
    },
    preview() {
      this.$router.push({ query: { isPreviewLink: true } })
    },
    update() {
      this.$store.dispatch('updateLink', { linkId: this.$route.query.linkId })
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '@/assets/css/variables';

.TheGlobalHeader {
  background-color: var(--c-black-base);
  border-bottom: 2px solid var(--c-lightgray-base);
  &_Wrapper {
    display: grid;
    grid-template-rows: 98px;
    grid-template-columns: 65px 500px 1fr 68px;
    width: var(--width-pc-container);
    margin: auto;
  }
  &_Logo {
    display: inline-block;
    align-self: center;
  }
  &_LogoImage {
  }
  &_Navigation {
    display: inline;
    align-self: center;
  }
  &_NavigationLink {
    display: inline-block;
    padding: 8px 0;
    margin-left: 60px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    line-height: 17px;
    color: var(--c-gray-base);
    &:hover {
      color: var(--c-white-base);
    }
    &._isActive {
      position: relative;
      color: var(--c-red-base);
      &:after {
        position: absolute;
        bottom: -3px;
        display: block;
        width: 100%;
        height: 3px;
        content: ' ';
        background-color: var(--c-red-base);
      }
    }
  }
  &_SelectUser {
    display: flex;
    align-self: center;
    justify-self: end;
    width: 260px;
  }
  &_SelectButton {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 9px 15px;
    font-size: 14px;
    color: var(--c-white-base);
    letter-spacing: 0.01em;
    background-color: var(--c-black-2);
    border-radius: 5px;
    &:after {
      position: absolute;
      top: 0;
      right: 14px;
      bottom: 0;
      display: block;
      width: 9px;
      height: 6px;
      margin: auto;
      content: '';
      background-image: url('/img/icon-arrow-red.png');
      background-size: 9px 6px;
    }
    &._isActive > .TheGlobalHeader_SelectListWrapper {
      display: block;
    }
  }
  &_SelectUserImage {
    width: 34px;
    height: 34px;
    object-fit: cover;
    margin: 0 15px 0 0;
    border-radius: 50px;
  }
  &_SelectUserName {
    overflow: hidden;
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_SelectListWrapper {
    position: absolute;
    top: 52px;
    right: 0;
    z-index: var(--zi-sort-list-wrapper);
    display: none;
    width: 100%;
    height: 286.5px;
    padding: 7.5px 0;
    margin: 10px 0 0;
    overflow: scroll;
    list-style: none;
    background-color: var(--c-black-base);
    border: 1px solid var(--c-gray-1);
    border-radius: 5px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_SelectList {
    padding: 15px 0px;
    font-size: 14px;
    color: var(--c-white-base);
    cursor: pointer;
  }
  &_PreferencesWrapper {
    position: absolute;
    top: 52px;
    right: 0;
    z-index: var(--zi-sort-list-wrapper);
    display: none;
    width: 283px;
    height: auto;
    padding: 7.5px 0px 13.5px;
    margin: 10px 0 0;
    overflow: scroll;
    list-style: none;
    background-color: var(--c-black-base);
    border: 1px solid var(--c-gray-1);
    border-radius: 5px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_Preferences {
    align-self: center;
    width: 52px;
    margin-left: auto;
  }
  &_PreferencesList {
    position: relative;
    padding: 9px 20px;
    &:after {
      position: absolute;
      top: 0;
      right: 21px;
      bottom: 0;
      display: block;
      width: 9px;
      height: 6px;
      margin: auto;
      content: '';
      background-image: url('/img/icon-arrow-red.png');
      background-size: 9px 6px;
      border: 0;
      transform: rotate(-90deg);
    }
    &:hover {
      background-color: var(--c-gray-1);
    }
  }
  &_PreferencesButton {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 15px;
    font-size: 14px;
    color: var(--c-white-base);
    letter-spacing: 0.01em;
    cursor: pointer;
    background-color: var(--c-black-2);
    border-radius: 50px;
    &._isActive > .TheGlobalHeader_PreferencesWrapper {
      display: block;
    }
  }
  &_PreferencesImage {
    width: 20px;
    fill: var(--c-white-base);
    stroke: var(--c-white-base);
  }
  &_ListHeadline {
    padding: 15px 20px;
    font-size: 10px;
    font-weight: var(--font-weight-medium);
    color: var(--c-gray-3);
  }
  &_UserImage {
    @util circle(44px, transparent);
  }
  &_Edit {
    display: flex;
    align-self: center;
    justify-content: space-between;
    justify-self: end;
    width: 370px;
  }
  &_Cancel {
    width: 110px;
    padding: 18px 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 1px solid var(--c-gray-3);
    border-radius: 50px;
  }
  &_Preview {
    width: 110px;
    padding: 18px 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-green-base);
    border: 1px solid var(--c-green-base);
    border-radius: 50px;
  }
  &_Save {
    width: 110px;
    padding: 18px 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-red-base);
    border-radius: 50px;
  }
  &_AccountList {
    position: relative;
    padding: 9px 20px;
    &:hover {
      background-color: var(--c-gray-1);
    }
    &._isActive {
      color: var(--c-red-base);
    }
  }
  &_AccountImage {
    float: left;
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0;
    overflow: hidden;
    border-radius: 50px;
  }
  &_AccountName {
    display: block;
    overflow: hidden;
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    &._isActive {
      color: var(--c-red-base);
    }
  }
}
</style>
