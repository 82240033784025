<template>
  <div class="Default">
    <TheModal v-if="$route.query.modal" />
    <TheFlashMessage v-show="$store.state.flashMessages.length > 0" />
    <!-- Layout適用: ログイン済 && LINK編集画面(PC版)でない && analytics画面(SP版)でない -->
    <template
      v-if="isLogin && !isPcLinkEdit && !isPcCampaignEdit && !isSpAnalytics"
    >
      <!-- header適用: create画面でない場合 -->
      <template
        v-if="
          !isCreate && !isLinkfireImport && !isLinkcoreImport && !isSfpImport
        "
      >
        <header class="Default_Header">
          <template v-if="$device.isDesktop">
            <TheGlobalHeader />
          </template>
          <template v-else>
            <TheGlobalHeaderSp />
          </template>
        </header>
      </template>
      <main class="Default_Main">
        <nuxt />
      </main>
      <footer
        v-if="
          !isCreate && !isLinkfireImport && !isLinkcoreImport && !isSfpImport
        "
        class="Default_Footer"
      >
        <template v-if="$device.isDesktop">
          <TheGlobalFooter />
        </template>
        <template v-else>
          <TheGlobalFooterSp />
        </template>
      </footer>
    </template>
    <template v-else>
      <nuxt />
    </template>
    <template v-if="!isLoggedIn">
      <ReLoginModal />
    </template>
  </div>
</template>
<script>
import { CheckIsLoginUser } from '@/middleware/auth'
import TheGlobalHeader from '@/components/TheGlobalHeader'
import TheGlobalHeaderSp from '@/components/sp/TheGlobalHeader'
import TheGlobalFooter from '@/components/TheGlobalFooter'
import TheGlobalFooterSp from '@/components/sp/TheGlobalFooter'
import TheFlashMessage from '@/components/TheFlashMessage'
import TheModal from '@/components/TheModal'
import ReLoginModal from '@/components/ReLoginModal'

export default {
  middleware: CheckIsLoginUser,
  components: {
    TheGlobalHeader,
    TheGlobalHeaderSp,
    TheGlobalFooter,
    TheGlobalFooterSp,
    TheFlashMessage,
    TheModal,
    ReLoginModal
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isLogin() {
      return this.$route.path.match(/user/) !== null
    },
    isLoggedIn() {
      return this.$store.state.isLogIn
    },
    isPcCampaignEdit() {
      // SP版の場合はfalse
      if (this.$route.path.match(/\/sp\/.*/) !== null) {
        return false
      }
      if (this.$route.query.page) {
        return true
      }
      return false
    },
    isPcLinkEdit() {
      // SP版の場合はfalse
      if (this.$route.path.match(/\/sp\/.*/) !== null) {
        return false
      }
      // PC版で、且つisPreviewLinkが存在する(=LINK編集画面)場合のみtrue
      if (this.$route.query.isPreviewLink) {
        return true
      }
      return false
    },
    isSpAnalytics() {
      return this.$route.path.match(/sp\/.*\/analytics/) !== null
    },
    isCreate() {
      return this.$route.path.match(/create/) !== null
    },
    isLinkfireImport() {
      return this.$route.path.match(/linkfire-import/) !== null
    },
    isLinkcoreImport() {
      return this.$route.path.match(/linkcore-import/) !== null
    },
    isSfpImport() {
      return this.$route.path.match(/sfp-import/) !== null
    },
    isProd() {
      // TODO: SP版完成してアクセスOKになったら削除
      return (
        process.env.NODE_ENV === 'production' &&
        process.env.BASE_URL === 'https://bomm.la'
      )
    }
  },
  created() {
    this.$store.commit('updateLoginInfo', {
      logged: this.$store.state.auth.loggedIn
    })
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.Default {
  &_Header {
    min-height: 18px;
    @media (min-width: 751px) {
      min-height: auto;
    }
  }
  &_Main {
    min-height: calc(100vh - 52.5px - 25px);
    @media (min-width: 751px) {
      min-height: auto;
      margin: 0 auto 70px;
    }
  }
  &_Footer {
    min-height: 17px;
    @media (min-width: 751px) {
      min-height: auto;
    }
  }
}
</style>
