import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getLinks: (params) => {
    return repository.get(`${prefix}${resource}/links`, {
      params
    })
  },
  postLinks: (params) => {
    return repository.post(`${prefix}${resource}/links`, params)
  },
  getLinkById: (linkId) => {
    return repository.get(`${prefix}${resource}/links/detail/${linkId}`)
  },
  putLinksById: (id) => {
    return repository.put(`${prefix}${resource}/links/detail/${id}`)
  },
  putLinksByIdWithParams: (id, params) => {
    return repository.put(`${prefix}${resource}/links/detail/${id}`, params)
  },
  deleteLinks: (linkId) => {
    return repository.delete(`${prefix}${resource}/links/detail/${linkId}`)
  },
  getLinkFileById: (linkId, params) => {
    return repository.get(`${prefix}${resource}/links/${linkId}/file`, {
      params
    })
  },
  getLinkUrlById: (linkId) => {
    return repository.get(`${prefix}${resource}/links/detail/${linkId}/url`)
  },
  getLinkFormats: (linkId) => {
    return repository.get(`${prefix}${resource}/links/detail/${linkId}/formats`)
  },
  postLinkFormat: (linkId, params) => {
    return repository.post(
      `${prefix}${resource}/links/detail/${linkId}/formats`,
      params
    )
  },
  putLinksImages: (params, options = {}) => {
    return repository.put(`${prefix}${resource}/links/images`, params, options)
  },
  postLinksImagesUrl: (params) => {
    return repository.post(`${prefix}${resource}/links/images/url`, params)
  }
}
