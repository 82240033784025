<template>
  <div ref="TopContent" class="TopMain_Content">
    <div v-if="loaded">
      <TheLoading :loaded="loaded" />
    </div>
    <div class="TeaserTop section1">
      <template v-if="$route.query.inDevelop">
        <div
          class="TeaserTop_Modal"
          :class="{ _isModal: $route.query.inDevelop }"
        >
          <div class="TeaserTop_ModalContent">
            <div class="TeaserTop_Head">
              スマホ版は現在開発中
            </div>
            <div class="TeaserTop_Body">
              <p class="TeaserTop_ModalText">
                現在PC版のみ公開されています<br />
                スマホ版対応は今しばらくお待ち下さい
              </p>
              <div class="TeaserTop_ButtonWrapper">
                <button class="TeaserTop_Cancel" @click="close">
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div class="TeaserTop_Background"></div> -->
      <header class="TeaserTop_Header">
        <GlobalNavigation />
      </header>
      <main class="TeaserTop_Main">
        <nuxt />
      </main>
      <!-- <canvas id="defaultCanvas0" class="p5Canvas" /> -->
    </div>
  </div>
</template>
<script>
import GlobalNavigation from '~/components/GlobalNavigation'
import TheLoading from '~/components/TheLoading'

export default {
  components: {
    GlobalNavigation,
    TheLoading
  },
  data() {
    return {
      script: ['p5.min.js', 'p5.dom.min.js', 'ml5.min.js', 'sketch.js'],
      loadedScript: [],
      loaded: true
    }
  },
  watch: {
    loadedScript(newScript, _) {
      if (
        this.loadedScript.length > 0 &&
        this.loadedScript.length < this.script.length
      ) {
        this.createScript()
      }
    }
  },
  created() {
    if (process.browser) {
      this.createScript()
    }
  },
  mounted() {
    this.isLoaded()
  },
  beforeDestroy() {
    this.removeScript()
  },
  methods: {
    close() {
      const query = Object.assign({}, this.$route.query)
      delete query.inDevelop
      this.$router.push({ query })
    },
    createScript() {
      const ele = document.createElement('script')
      ele.src = `/script/${this.script[this.loadedScript.length]}`
      ele.onload = () => {
        this.loadedScript.push(ele)
      }
      document.body.appendChild(ele)
    },
    removeScript() {
      this.loadedScript.forEach((item) => {
        document.body.removeChild(`<script src=${item}>`)
      })
      const doc = document.getElementsByTagName('canvas')[0]
      doc.remove()
    },
    isLoaded() {
      const loadTimer = setInterval(() => {
        if (
          this.$refs.TopContent &&
          this.$refs.TopContent.classList.contains('loaded')
        ) {
          this.loaded = false
          clearInterval(loadTimer)
        }
      }, 100)
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TeaserTop {
  @media (min-width: 751px) {
    padding: initial;
  }
  &_Background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 254px;
    background: #000;
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 751px) {
      min-width: var(--width-pc-container);
      height: auto;
      min-height: 704px;
      background: #000;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
    }
  }
  &_Header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: var(--zi-teaser-header);
    margin: auto;
  }
  &_FixedNavigation {
    &._isFixed {
      position: sticky;
      top: 0;
      left: 0;
      background-color: var(--c-black-base);
    }
  }
  &_HeaderMessageSp {
    /* position: absolute; */
    top: 30%;
    margin-left: 16px;
    font-size: 18px;
    font-weight: bold;
    line-height: 2;
    color: var(--c-white-base);
    letter-spacing: 0.07em;
    @media (min-width: 751px) {
      display: none;
    }
  }
  &_Modal {
    &._isModal {
      @util position(fixed, 0 0 0 0);
      z-index: var(--zi-themodal);
      width: 100%;
      height: 100%;
      margin: auto;
      background-color: var(--c-black-1);
    }
  }
  &_ModalContent {
    @util center;
    min-width: 403px;
  }
  &_Head {
    padding: 11.5px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    text-align: center;
    background-color: var(--c-green-3);
    @util border-top-radius(10px);
  }
  &_Body {
    padding: 26px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border-right: 1px solid var(--c-gray-3);
    border-bottom: 1px solid var(--c-gray-3);
    border-left: 1px solid var(--c-gray-3);
    @util border-bottom-radius(10px);
  }
  &_ModalText {
    padding: 0 27px 33px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    line-height: 1.71;
    color: var(--c-white-base);
  }
  &_ButtonWrapper {
    width: 180px;
    margin: auto;
  }
  &_Cancel {
    width: 100%;
    height: 50px;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 2px solid var(--c-gray-1);
    border-radius: 50px;
  }
}
.TheLoading {
  z-index: 9999999;
  background: #000;
}
</style>
