import { repository } from '../plugins/axios'
const endpoint = 'https://api.spotify.com/v1'

export default {
  getInfo: (urlParamBefore, urlParamAfter, headers) => {
    return repository.get(`${endpoint}/${urlParamBefore}s/${urlParamAfter}`, {
      headers
    })
  }
}
