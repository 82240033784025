<template>
  <div class="NonRegistered">
    <TheModal v-if="$route.query.modal" />
    <TheFlashMessage v-show="$store.state.flashMessages.length > 0" />
    <nuxt />
  </div>
</template>
<script>
import TheFlashMessage from '@/components/TheFlashMessage'
import TheModal from '@/components/TheModal'

export default {
  name: 'NonRegistered',
  components: {
    TheFlashMessage,
    TheModal
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.NonRegistered {
  &_Header {
    min-height: 18px;
    @media (min-width: 751px) {
      min-height: auto;
    }
  }
  &_Main {
    min-height: calc(100vh - 52.5px - 25px);
    @media (min-width: 751px) {
      min-height: auto;
      margin: 0 auto 70px;
    }
  }
  &_Footer {
    min-height: 17px;
    @media (min-width: 751px) {
      min-height: auto;
    }
  }
}
</style>
