import { RepositoryFactory } from '../repositories/RepositoryFactory'
const MeRepository = RepositoryFactory.get('me')

/**
 * @desc Userのダッシュボード画面におけるLoginチェック
 * 未ログイン: userのログイン画面(desktop/sp)に遷移
 * ログイン済(user): なにもしない
 * ログイン済(admin): admin画面にリダイレクト
 */
export const CheckIsLoginUser = ({ store, redirect, isDesktop }) => {
  try {
    // if (!store.state.auth.loggedIn) {
    //   if (isDesktop) {
    //     return redirect(302, '/login', { error: 'auth' })
    //   }
    //   return redirect(302, '/sp/login', { error: 'auth' })
    // }
    if (store.state.auth.strategy === 'admin') {
      return redirect('/admin')
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * @desc Adminのダッシュボード画面におけるLoginチェック
 * 未ログイン: adminのログイン画面に遷移
 * ログイン済(user): user画面(desktop/sp)にリダイレクト
 * ログイン済(admin): なにもしない
 */
export const CheckIsLoginAdmin = ({ store, redirect, isDesktop }) => {
  try {
    if (!store.state.auth.loggedIn) {
      return redirect(302, '/admin/login', { error: 'auth' })
    }
    if (store.state.auth.strategy === 'user') {
      if (isDesktop) {
        return redirect('/user')
      }
      return redirect('/sp/user')
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * @desc Login画面におけるLoginチェック。ログイン済である場合に各ダッシュボードに遷移
 * ログイン済である場合のみ遷移。エラーはコンソール。
 */
export const CheckLoginStrategy = async ({ store, redirect, isDesktop }) => {
  try {
    const response = await MeRepository.getIsLogin()
    if (response.isLogin && store.state.auth.loggedIn) {
      if (store.state.auth.strategy === 'admin') {
        return redirect('/admin')
      }
      if (store.state.auth.strategy === 'user') {
        if (isDesktop) {
          return redirect('/user')
        }
        return redirect('/sp/user')
      }
    }
  } catch (e) {
    console.log(e)
  }
}
