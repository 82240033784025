<template>
  <div class="ReLoginModal">
    <div class="ReLoginModal_Content">
      <div class="ReLoginModal_Head">
        再認証
      </div>
      <div class="ReLoginModal_Body">
        <p class="ReLoginModal_ModalText">
          ログインの有効期限が切れました。<br />
          パスワードの再入力をお願いいたします。
        </p>
        <div class="ReLoginModal_ContentWrapper">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form
              class="ReLoginModal_Form"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <div class="ReLoginModal_Input">
                <label class="ReLoginModal_InputLabel">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="パスワード"
                    rules="strong-password|required"
                  >
                    <input
                      v-model="reLoginPassword"
                      class="ReLoginModal_InputElement"
                      type="password"
                      placeholder="Password"
                    />
                    <p v-if="errors[0]" class="ReLoginModal_InputError">
                      {{ errors[0] }}
                    </p>
                    <p
                      v-if="reLoginPasswordErr"
                      class="ReLoginModal_InputError"
                    >
                      パスワードが違います
                    </p>
                  </ValidationProvider>
                </label>
              </div>
              <div class="ReLoginModal_ButtonWrapper">
                <button type="button" class="ReLoginModal_Back" @click="goTo">
                  ログイン画面に戻る
                </button>
                <button
                  type="submit"
                  class="ReLoginModal_Submit"
                  @click="close"
                >
                  再認証
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MeRepository = RepositoryFactory.get('me')

export default {
  data() {
    return {
      reLoginPassword: null,
      reLoginPasswordErr: false
    }
  },
  methods: {
    close() {
      const query = Object.assign({}, this.$route.query)
      delete query.modal
      this.$router.push({ query })
    },
    async onSubmit() {
      this.reLoginPasswordErr = false
      try {
        const response = await this.$auth.loginWith('user', {
          data: {
            email: this.$store.state.user.email,
            password: this.reLoginPassword
          }
        })

        await this.$auth.$storage.setLocalStorage(
          'auth._token.user',
          response.data.accessToken
        )
        if (!response.data.code) {
          this.$store.commit('updateLoginInfo', { logged: true })
        }

        if (response.data.code === 400) {
          this.reLoginPasswordErr = true
          throw new Error('PWが誤っています')
        }
        if (response.status === 401 && process.browser) {
          window.postMessage('logged', 'http://localhost:3000/')
        }
        const user = await MeRepository.getMe()
        if (!user.data.code === 400) {
          throw new Error('ユーザ情報取得エラー')
        }
        this.$store.commit('setUser', { user: user.data })
        const dashboards = await MeRepository.getDashBoards()
        if (!dashboards.data.code === 400) {
          throw new Error('ダッシュボード情報取得エラー')
        }
        this.$store.commit('setDashboards', { dashboards: dashboards.data })
      } catch (e) {
        this.$store.dispatch('showFlashMessage', {
          text: `ログインに失敗しました`,
          type: 'error',
          description: e
        })
      }
    },
    goTo() {
      if (process.browser) {
        window.location.href = '/login'
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '@/assets/css/variables';

.ReLoginModal {
  @util position(fixed, 0 0 0 0);
  z-index: 9999;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: var(--c-black-1);
  &_Content {
    @util center;
    min-width: 289px;
    max-width: 423px;
    @media screen and (min-width: 751px) {
      width: 485px;
      min-width: 0;
      max-width: auto;
      height: 323px;
    }
  }
  &_Head {
    padding: 11.5px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    text-align: center;
    background-color: #18be75;
    @util border-top-radius(10px);
    @media screen and (min-width: 751px) {
      padding: 12px 0;
      font-size: 18px;
      @util border-top-radius(10px);
    }
  }
  &_Body {
    padding: 26px 27px 33px;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-black-base);
    border-right: 1px solid var(--c-gray-3);
    border-bottom: 1px solid var(--c-gray-3);
    border-left: 1px solid var(--c-gray-3);
    @util border-bottom-radius(10px);
    @media screen and (min-width: 751px) {
      padding: 35px 34px 46px;
      @util border-bottom-radius(10px);
    }
  }
  &_ButtonWrapper {
    width: 180px;
    margin: auto;
    @media screen and (min-width: 751px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 370px;
    }
    a {
      display: flex;
    }
  }
  &_DeleteButton {
    width: 230px;
    padding: 17px 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-red-base);
    border-radius: 50px;
  }
  &_Back,
  &_Submit {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    @media screen and (min-width: 751px) {
      width: 180px;
      height: auto;
      padding: 18px 0;
      margin-top: 0;
      font-size: 14px;
      border-style: solid;
      border-width: 2px;
      border-radius: 50px;
    }
  }
  &_Back {
    margin-top: 20px;
    border-color: var(--c-gray-1);
    @media screen and (min-width: 751px) {
      margin-top: 0;
    }
  }
  &_Submit {
    margin-top: 10px;
    border-color: #1cbe27;
    @media screen and (min-width: 751px) {
      margin-top: 0;
    }
  }
  &_ModalText {
    padding: 0 0 22px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    line-height: 1.71;
    color: var(--c-white-base);
    @media screen and (min-width: 751px) {
      padding: 0 0 20px;
      font-size: 14px;
      text-align: center;
    }
  }
  &_Input {
    @media screen and (min-width: 751px) {
      margin-bottom: 30px;
    }
  }
  &_InputElement {
    box-sizing: border-box;
    width: 100%;
    padding: 11.5px 13.5px;
    font-size: 18px;
    color: var(--c-white-base);
    background-color: var(--c-black-2);
    border: none;
    border-radius: 5px;
    &::placeholder {
      font-weight: var(--font-weight-medium);
      color: var(--c-gray-1);
      border-radius: 5px;
    }
    @media screen and (min-width: 751px) {
      padding: 16px 10px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
  &_InputLabel {
    span {
      display: flex;
      flex-direction: column;
    }
  }
  &_InputError {
    padding: 5px 0 0;
    margin-left: 0;
    font-size: 12px;
    color: var(--c-red-base);
    @media screen and (min-width: 751px) {
      padding: 5px 0 0;
      margin: 0;
      font-size: 12px;
    }
  }
}
</style>
