<template>
  <div class="TheFlashMessage">
    <transition-group tag="div">
      <div
        v-for="(message, index) in $store.state.flashMessages"
        :key="index + 1"
        class="TheFlashMessage_Wrapper"
        :class="{
          _isSuccess: message.type === 'success',
          _isError: message.type === 'error'
        }"
      >
        <div class="TheFlashMessage_Text">
          {{ message.text }}
          <img
            v-if="message.type === 'error'"
            class="TheFlashMessage_IconError"
            src="/img/icon-error.svg"
            @click="deleteMessage(index)"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  methods: {
    deleteMessage(index) {
      this.$store.commit('deleteFlashMessage', { index })
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '@/assets/css/variables';
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.TheFlashMessage {
  position: fixed;
  top: 13px;
  left: 0;
  z-index: var(--zi-flashmessage);
  width: 185px;
  height: auto;
  &_Wrapper {
    position: relative;
    left: 0;
    display: block;
    width: 185px;
    margin: 0 0 8px;
    border: 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 250ms ease-in-out;
    &.v-enter-active {
      left: -185px;
      transition: all 250ms ease-in-out;
    }
    &._isSuccess {
      background-color: var(--c-green-7);
      border-color: var(--c-green-3);
      .TheFlashMessage_Text {
        color: var(--c-white-base);
      }
    }
    &._isError {
      background-color: var(--c-red-10);
      border-color: var(--c-red-11);
    }
  }
  &_CheckMark {
    width: 65px;
    margin: 309.8px auto 0;
  }
  &_Path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    stroke-width: 8px;
  }
  &_Text {
    padding: 7.3px 8.5px 8.7px 30px;
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    line-height: 1.83;
    word-break: break-word;
  }
  &_IconError {
    position: absolute;
    bottom: 9.2px;
    left: 10.7px;
    width: 16px;
    cursor: pointer;
  }
}
</style>
