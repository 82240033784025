<template>
  <footer class="TheGlobalFooter">
    <div class="TheGlobalFooter_LogoWrapper">
      <img
        class="TheGlobalFooter_Logo"
        src="/img/logo-bom-heading.png"
        alt="B.O.M BOOST YOUR CREATIVITY"
      />
      <a
        href="https://x.com/BOM_MusicTool"
        target="_blank"
        class="TheGlobalFooter_LinkX"
      >
        <IconX class="TheGlobalFooter_IconX" />
      </a>
    </div>
    <div class="TheGlobalFooter_List">
      <div class="TheGlobalFooter_CopyrightPc">
        ©CotoLab. Inc. 2020. All Rights Reserved.
      </div>
      <div class="TheGlobalFooter_MainList">
        <div class="TheGlobalFooter_Item">
          <n-link class="TheGlobalFooter_Link" to="/about"
            ><span class="TheGlobalFooter_Text">B.O.Mとは</span></n-link
          >
        </div>
        <div class="TheGlobalFooter_Item">
          <n-link class="TheGlobalFooter_Link" to="/pricing"
            ><span class="TheGlobalFooter_Text"
              >料金プラン/機能一覧</span
            ></n-link
          >
        </div>
        <div class="TheGlobalFooter_Item">
          <n-link class="TheGlobalFooter_Link" to="/blog/posts"
            ><span class="TheGlobalFooter_Text">ブログ</span></n-link
          >
        </div>
        <div class="TheGlobalFooter_Item">
          <a
            class="TheGlobalFooter_Link"
            target="_blank"
            href="https://tayori.com/faq/35b154be3f7a947d36c4ad53953bf4f96d3cbc1c"
            ><span class="TheGlobalFooter_Text">ヘルプセンター</span></a
          >
        </div>
      </div>

      <div class="TheGlobalFooter_Item">
        <a
          class="TheGlobalFooter_Link"
          href="https://cotolab.com"
          target="_blank"
        >
          <span class="TheGlobalFooter_Text">運営会社</span>
        </a>
      </div>
      <div class="TheGlobalFooter_Item">
        <n-link class="TheGlobalFooter_Link" to="/privacy-policy"
          ><span class="TheGlobalFooter_Text"
            >プライバシーポリシー</span
          ></n-link
        >
      </div>
      <div class="TheGlobalFooter_Item">
        <a
          class="TheGlobalFooter_Link"
          href="https://form.run/@bom--1592908099"
          target="_blank"
          ><span class="TheGlobalFooter_Text">お問い合わせ</span></a
        >
      </div>
    </div>
    <span class="TheGlobalFooter_CopyrightSp"
      >©CotoLab. Inc. 2020. All Rights Reserved.</span
    >
  </footer>
</template>
<script>
import IconX from '@/static/img/icon-x.svg'
export default {
  components: {
    IconX
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TheGlobalFooter {
  position: relative;
  display: block;
  width: var(--width-pc-container);
  margin: auto;
  text-align: center;
  @media (min-width: 751px) {
    display: flex;
    flex-wrap: wrap;
  }
  &_About {
    width: 100%;
    height: 509px;
    @media (min-width: 751px) {
      position: relative;
      height: 514px;
    }
  }
  &_Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &_Description {
    position: absolute;
    top: 0;
    height: auto;
    margin: 56px 24px;
    @media (min-width: 751px) {
      width: 100%;
      margin: 90px 0 0;
    }
  }
  &_DescriptionLogoPc {
    display: none;
    @media (min-width: 751px) {
      display: inline;
      width: 215px;
      height: 75px;
      margin: 0 auto 39px;
    }
  }
  &_DescriptionLogoSp {
    width: 89.91px;
    height: 57.98px;
    margin: 0 auto 51.3px;
    @media (min-width: 751px) {
      display: none;
    }
  }
  &_DescriptionTextSp {
    margin-bottom: 51px;
    font-size: 16px;
    line-height: 2;
    text-align: left;
    letter-spacing: 0.1em;
    @media (min-width: 751px) {
      display: none;
    }
  }
  &_DescriptionTextPc {
    display: none;
    @media (min-width: 751px) {
      display: block;
      margin-bottom: 48px;
      font-size: 18px;
      line-height: 2;
      text-align: center;
      letter-spacing: 0.2em;
    }
  }
  &_AboutButton {
    display: block;
    width: 229px;
    height: 50px;
    padding: 16px 48px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    border: 2px solid var(--c-red-base);
    border-radius: 32px;
    @media (min-width: 751px) {
      width: 575px;
      height: 50px;
      padding: 15px 60px 17px;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.05em;
      border-width: 2px;
    }
  }
  &_LogoWrapper {
    @media (min-width: 751px) {
      display: flex;
      width: 100%;
      margin: 71px 0 73px 0;
    }
  }
  &_Logo {
    width: 133.67px;
    padding-top: 44px;
    margin: 0 auto 30px;
    @media (min-width: 751px) {
      width: 133.67px;
      height: 52.62px;
      padding: 0;
      margin: 0;
    }
  }
  &_List {
    margin: 0 24px 50px;
    @media (min-width: 751px) {
      display: inline-flex;
      flex: 1;
      align-items: center;
      margin: 0 0 113px 0;
    }
  }
  &_Item {
    margin-bottom: 45px;
    @media (min-width: 751px) {
      margin-bottom: 0;
      margin-left: 34px;
    }
  }
  &_Text {
    font-size: 18px;
    letter-spacing: 0.04em;
    @media (min-width: 751px) {
      font-size: 14px;
      letter-spacing: 0.04em;
    }
  }
  &_LinkX {
    display: block;
    width: 52px;
    height: 52px;
    padding: 16px 17px;
    margin: 0 auto 35px;
    background-color: var(--c-black-2);
    border-radius: 50%;
    @media (min-width: 751px) {
      width: 52px;
      height: 52px;
      padding: 16px 17px;
      margin: auto 0 auto 45.3px;
    }
  }
  &_IconX {
    width: 24.56px;
    fill: var(--c-white-base);
  }
  &_MainList {
    @media (min-width: 751px) {
      display: none;
    }
  }
  &_CopyrightPc {
    display: none;
    @media (min-width: 751px) {
      display: block;
      margin: 0 52px 0 0;
      font-size: 12px;
      font-weight: var(--font-weight-medium);
      color: var(--c-gray-3);
      letter-spacing: 0.1em;
    }
  }
  &_CopyrightSp {
    padding: 0 0 11px 0;
    font-size: 8px;
    font-weight: var(--font-weight-medium);
    line-height: 1.5;
    color: var(--c-gray-3);
    letter-spacing: 0.1em;
    @media (min-width: 751px) {
      display: none;
    }
  }
}
</style>
