// import MeRepository from "~/repositories/MeRepository"
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MeRepository = RepositoryFactory.get('me')

// TODO:BOM用にstateを置換
export const state = () => ({
  data: null,
  isPreSaveComplete: false,
  isLoginAppleMusic: false,
  appleMusic: {
    albumId: null
  },
  spotify: {
    valid: false,
    accessToken: null,
    dataId: null
  },
  pageReleaseDate: '2021-05-15',
  applicationEndDate: '2021-08-02',
  albumName: 'PINK BLOOD',
  artistName: '宇多田ヒカル',
  availableCpDomains: null
})

export const mutations = {
  addFormLink(state, { type }) {
    if (type === 'privacy') {
      state.data.formPrivacyLinks.push({
        text: '',
        url: ''
      })
    } else if (type === 'contact') {
      state.data.formContactLinks.push({
        text: '',
        url: ''
      })
    }
  },
  addCampaignType(state, { type }) {
    state.data.campaignType = type
    if (type === 'lottery') {
      state.data.applicationSuccessTitle = 'ご応募ありがとうございました'
    }
  },
  setavailableCpDomains(state, { domains }) {
    state.availableCpDomains = [
      { urlDomain: process.env.CAMPAIGN_PAGE_DEFAULT_DOMAIN }
    ]
  },
  switchFormActive(state, { type }) {
    state.data.isForm = type
    if (type) {
      state.data.activeServices = [
        'Spotify',
        'Apple Music',
        'iTunes',
        'LINE MUSIC'
      ]
    } else {
      state.data.activeServices = ['Spotify', 'Apple Music']
    }
  },
  updateCampaignData(
    state,
    {
      activeServices,
      appleMusicCampaignText,
      appleMusicCampaignTitle,
      appleMusicDescription,
      appleMusicPreaddId,
      applicationNoticeText,
      applicationSuccessText,
      applicationSuccessTitle,
      applicationGuideText,
      copyrightText,
      campaignStartDate,
      campaignType,
      campaignEndDate,
      description,
      downloadImageUrlPc,
      downloadImageUrlSp,
      displayStatus,
      formActiveParts,
      formContactText,
      formContactLinks,
      formPrivacyText,
      formPrivacyLinks,
      imageUrl,
      isForm,
      itunesCampaignText,
      itunesCampaignTitle,
      itunesDescription,
      itunesPreorderUrl,
      presentImageUrl,
      presentTitle,
      privacyPolicyUrl,
      rewardLinkText,
      rewardLinkUrl,
      rewardType,
      spotifyCampaignText,
      spotifyCampaignTitle,
      spotifyDescription,
      spotifyPresaveUri,
      subtitle,
      lineMusicCampaignText,
      lineMusicCampaignTitle,
      lineMusicDescription,
      lineMusicUrl,
      themeType,
      twitterHashtags,
      title,
      urlDomain,
      urlPath,
      winningAnnounceText
    }
  ) {
    if (activeServices) {
      const index = state.data.activeServices.findIndex(
        (s) => s === activeServices
      )
      if (index !== -1) {
        // サービスの無効化
        if (state.data.activeServices.length === 1) {
          alert('1つ以上の応募ページを選択してください')
          return
        }
        state.data.activeServices.splice(index, 1)
        // iTunes及びLINE MUSICを無効にした場合はフォームのスクリーンショット画像を無効にする
        if (
          !state.data.activeServices.includes('LINE MUSIC') &&
          !state.data.activeServices.includes('iTunes')
        ) {
          const index = state.data.formActiveParts.findIndex(
            (s) => s === 'screen-shot'
          )
          if (index !== -1) state.data.formActiveParts.splice(index, 1)
        }
      } else {
        // サービスの有効化
        state.data.activeServices.push(activeServices)
        // iTunesまたはLINE MUSICを有効にした場合はフォームのスクリーンショット画像を有効にする
        if (activeServices === 'iTunes' || activeServices === 'LINE MUSIC') {
          if (!state.data.formActiveParts.includes('screen-shot'))
            state.data.formActiveParts.push('screen-shot')
        }
      }
    }

    if (appleMusicCampaignText || appleMusicCampaignText === '')
      state.data.appleMusicCampaignText = appleMusicCampaignText
    if (appleMusicCampaignTitle || appleMusicCampaignTitle === '')
      state.data.appleMusicCampaignTitle = appleMusicCampaignTitle
    if (appleMusicDescription || appleMusicDescription === '')
      state.data.appleMusicDescription = appleMusicDescription
    if (appleMusicPreaddId || appleMusicPreaddId === '')
      state.data.appleMusicPreaddId = appleMusicPreaddId
    if (applicationNoticeText || applicationNoticeText === '')
      state.data.applicationNoticeText = applicationNoticeText
    if (applicationSuccessText || applicationSuccessText === '')
      state.data.applicationSuccessText = applicationSuccessText
    if (applicationSuccessTitle || applicationSuccessTitle === '')
      state.data.applicationSuccessTitle = applicationSuccessTitle
    if (applicationGuideText || applicationGuideText === '')
      state.data.applicationGuideText = applicationGuideText
    if (campaignStartDate) state.data.campaignStartDate = campaignStartDate
    if (campaignType) state.data.campaignType = campaignType
    if (campaignEndDate) state.data.campaignEndDate = campaignEndDate
    if (copyrightText || copyrightText === '')
      state.data.copyrightText = copyrightText
    if (description || description === '') state.data.description = description
    if (downloadImageUrlPc) state.data.downloadImageUrlPc = downloadImageUrlPc
    if (downloadImageUrlSp) state.data.downloadImageUrlSp = downloadImageUrlSp
    if (displayStatus) state.data.displayStatus = displayStatus
    if (formActiveParts) {
      const index = state.data.formActiveParts.findIndex(
        (s) => s === formActiveParts
      )
      if (index !== -1) {
        state.data.formActiveParts.splice(index, 1)
      } else {
        state.data.formActiveParts.push(formActiveParts)
      }
    }
    if (formContactText || formContactText === '')
      state.data.formContactText = formContactText
    if (formContactLinks) {
      state.data.formContactLinks[formContactLinks.index].text =
        formContactLinks.text
      state.data.formContactLinks[formContactLinks.index].url =
        formContactLinks.url
    }
    if (formPrivacyText || formPrivacyText === '')
      state.data.formPrivacyText = formPrivacyText
    if (formPrivacyLinks) {
      state.data.formPrivacyLinks[formPrivacyLinks.index].text =
        formPrivacyLinks.text
      state.data.formPrivacyLinks[formPrivacyLinks.index].url =
        formPrivacyLinks.url
    }
    if (imageUrl) state.data.imageUrl = imageUrl
    if (isForm) state.data.isForm = isForm
    if (itunesCampaignText || itunesCampaignText === '')
      state.data.itunesCampaignText = itunesCampaignText
    if (itunesCampaignTitle || itunesCampaignTitle === '')
      state.data.itunesCampaignTitle = itunesCampaignTitle
    if (itunesDescription || itunesDescription === '')
      state.data.itunesDescription = itunesDescription
    if (itunesPreorderUrl || itunesPreorderUrl === '')
      state.data.itunesPreorderUrl = itunesPreorderUrl
    if (presentImageUrl) state.data.presentImageUrl = presentImageUrl
    if (presentTitle || presentTitle === '')
      state.data.presentTitle = presentTitle
    if (privacyPolicyUrl || privacyPolicyUrl === '')
      state.data.privacyPolicyUrl = privacyPolicyUrl
    if (rewardLinkText || rewardLinkText === '')
      state.data.rewardLinkText = rewardLinkText
    if (rewardLinkUrl) state.data.rewardLinkUrl = rewardLinkUrl
    if (rewardType) state.data.rewardType = rewardType
    if (spotifyCampaignText || spotifyCampaignText === '')
      state.data.spotifyCampaignText = spotifyCampaignText
    if (spotifyCampaignTitle || spotifyCampaignTitle === '')
      state.data.spotifyCampaignTitle = spotifyCampaignTitle
    if (spotifyDescription || spotifyDescription === '')
      state.data.spotifyDescription = spotifyDescription
    if (spotifyPresaveUri || spotifyPresaveUri === '')
      state.data.spotifyPresaveUri = spotifyPresaveUri
    if (lineMusicCampaignText || lineMusicCampaignText === '')
      state.data.lineMusicCampaignText = lineMusicCampaignText
    if (lineMusicCampaignTitle || lineMusicCampaignTitle === '')
      state.data.lineMusicCampaignTitle = lineMusicCampaignTitle
    if (lineMusicDescription || lineMusicDescription === '')
      state.data.lineMusicDescription = lineMusicDescription
    if (lineMusicUrl || lineMusicUrl === '')
      state.data.lineMusicUrl = lineMusicUrl
    if (subtitle || subtitle === '') state.data.subtitle = subtitle
    if (twitterHashtags || twitterHashtags === '')
      state.data.twitterHashtags = twitterHashtags
    if (themeType) state.data.themeType = themeType
    if (title || title === '') state.data.title = title
    if (urlDomain || urlDomain === '') state.data.urlDomain = urlDomain
    if (urlPath || urlPath === '') state.data.urlPath = urlPath
    if (winningAnnounceText || winningAnnounceText === '')
      state.data.winningAnnounceText = winningAnnounceText
  },
  setDefaultCampaignData(state) {
    state.data = {
      activeServices: ['Spotify', 'Apple Music', 'iTunes', 'LINE MUSIC'],
      appleMusicCampaignText:
        '当サイトにて「Apple Music Pre-Add」ボタンクリック後に表示される「キャンペーン応募フォーム」ボタンから、プレゼントにご応募できます。',
      appleMusicCampaignTitle:
        'Apple Musicをご利用の方が、ご参加いただけるキャンペーンです',
      appleMusicDescription:
        '「アーティスト名」の「アルバム名」をApple Musicでいち早く体験するためにPre-Addをしよう！',
      appleMusicPreaddId: '123456789',
      applicationGuideText:
        'キャンペーンご参加ありがとうございます。下記ボタンから応募フォームへ移動して入力を完了してください。',
      applicationSuccessText: '○月○日に自動的に曲が追加されます。お楽しみに！',
      applicationSuccessTitle:
        'ご応募ありがとうございました\nオリジナル壁紙プレゼント',
      applicationNoticeText:
        '・当選の権利は当選者ご本人様のみとなります。第三者への譲渡はできません。\n・応募や当選状況に関するお問い合わせには一切お答えできません。',
      campaignEndDate: this.$dateFns.format(
        new Date(new Date().setHours(0, 0, 0, 0)).setDate(
          new Date().getDate() + 14
        ),
        'YYYY-MM-DD HH:mm'
      ),
      campaignType: null,
      campaignStartDate: this.$dateFns.format(
        new Date().setHours(0, 0, 0, 0),
        'YYYY-MM-DD HH:mm'
      ),
      copyrightText: '©株式会社〜',
      description:
        '配信シングル「○○○」のリリースを記念して、○○プレゼントキャンペーンがスタート！',
      downloadImageUrlPc: `${process.env.BASE_URL}/img/cp-default.jpg`,
      downloadImageUrlSp: `${process.env.BASE_URL}/img/cp-default.jpg`,
      displayStatus: 'private',
      formActiveParts: [
        'name',
        'email',
        'prefectures',
        'address',
        'screen-shot'
      ],
      formContactText:
        '下記ページにアクセスし、問い合わせフォームへ必要事項（お名前・電話番号・メールアドレス）とお問い合わせ内容をご記入の上、送信下さい。',
      formContactLinks: [
        {
          text: 'お問合せ先',
          url: 'https://example.com/contact'
        }
      ],
      formPrivacyText:
        '【情報の利用目的】お客様に投稿いただきました情報は、当選された方にご連絡させていただく以外の目的仕様をする事は一切ございません。\n【情報の保管】当社の個人情報保護方針に即して適切に管理し、ご当選者の方への当選のご確認の為、1ヵ月間保管し、全て再生不可能な形状にした後、破棄致します。\n【情報の提供・開示】法令に基づいて司法、行政、又はこれに類する機関から情報開示の要請を受けた場合を除きお客様の同意無く個人情報を第三者に提供、又は開示することはございません。',
      formPrivacyLinks: [
        {
          text: '個人情報保護方針リンク',
          url: 'https://example.com/privacy-policy'
        }
      ],
      imageUrl: `${process.env.BASE_URL}/img/cp-default.jpg`,
      isForm: true,
      itunesCampaignText:
        '「キャンペーン応募フォーム」ボタンから、プレゼントにご応募できます',
      itunesCampaignTitle:
        'iTunesをご利用の方が、ご参加いただけるキャンペーンです',
      itunesDescription:
        '「アーティスト名」の「アルバム名」をiTunesでいち早く体験するためにiTunes Pre-Orderをしよう！',
      itunesPreorderUrl: 'https://music.apple.com/jp/album/~',
      presentImageUrl: `${process.env.BASE_URL}/img/cp-sample-prize.jpg`,
      presentTitle: 'キャンペーン参加者から抽選で○名様に○○プレゼント',
      privacyPolicyUrl: 'https://~',
      rewardLinkText: '限定公開URLへ',
      rewardLinkUrl: 'https://~',
      rewardType: 'image',
      spotifyCampaignText:
        '当サイトにて「Spotify Pre-Save」ボタンクリック後に表示される「キャンペーン応募フォーム」ボタンから、プレゼントにご応募できます。',
      spotifyCampaignTitle:
        'Spotifyをご利用の方が、ご参加いただけるキャンペーンです',
      spotifyDescription:
        '「アーティスト名」の「アルバム名」をSpotifyでいち早く体験するためにSpotify Pre-Saveをしよう！',
      spotifyPresaveUri: 'https://open.spotify.com/album/123456',
      subtitle: '配信シングル',
      lineMusicCampaignText:
        'LINE MUSICアプリ内の再生履歴のスクリーンショットを「キャンペーン応募フォーム」にてアップロードいただき応募となります。',
      lineMusicCampaignTitle:
        'LINE MUSICをご利用の方が、ご参加いただけるキャンペーンです',
      lineMusicDescription:
        '「アーティスト名」の「アルバム名」をLINE MUSICでたくさん聞いて応募しよう！',
      lineMusicUrl: 'https://music.line.me/webapp/today',
      themeType: 'dark',
      twitterHashtags: 'tag1,tag2',
      title: 'アルバムタイトル',
      urlDomain: process.env.CAMPAIGN_PAGE_DEFAULT_DOMAIN,
      urlPath: new Date().getTime().toString(16),
      winningAnnounceText: 'ご当選された方にのみご連絡をさせていただきます。'
    }
  },
  setEditCampaignData(state, { data }) {
    state.data = data
  },
  setPresaveSpotifyDataId(state, payload) {
    state.spotify.dataId = payload.id
  },
  isPreSaveComplete(state, payload) {
    state.isPreSaveComplete = true
    window.localStorage.setItem(
      payload.directory + '-presave',
      JSON.stringify(true)
    )
  },
  authorizeAppleMusic(state) {
    state.isLoginAppleMusic = true
  },
  submitForm(state, payload) {
    state.isLoginAppleMusic = false
    state.isLoginSpotify = false
    state.isPreSaveComplete = false
    window.localStorage.setItem(
      payload.service + '-' + payload.directory + '-submit',
      JSON.stringify(true)
    )
  }
}

export const actions = {
  async createCampaign({ state, dispatch, rootState }) {
    try {
      state.data.authorName = rootState.user.displayName
      const res = await MeRepository.postCampaigns(state.data)
      if (!res || res.status !== 200) {
        throw new Error('エラーが発生しました。')
      }
      dispatch(
        'showFlashMessage',
        {
          text: '作成されました',
          type: 'success'
        },
        { root: true }
      )
      return res.data.campaignId
    } catch (e) {
      dispatch(
        'showFlashMessage',
        {
          text: `${e}`,
          type: 'error'
        },
        { root: true }
      )
    }
  },
  async updateCampaign({ state, dispatch }, { campaignId }) {
    try {
      state.data.campaignId = campaignId
      const res = await MeRepository.postCampaigns(state.data)
      if (!res || res.status !== 200) {
        throw new Error('エラーが発生しました。')
      }
      return dispatch(
        'showFlashMessage',
        {
          text: '更新されました',
          type: 'success'
        },
        { root: true }
      )
    } catch (e) {
      dispatch(
        'showFlashMessage',
        {
          text: `${e}`,
          type: 'error'
        },
        { root: true }
      )
    }
  }
}
