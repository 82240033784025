import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/services'

export default {
  getServiceByUrl(url) {
    return repository.get(`${prefix}${resource}/url?url=${encodeURI(url)}`)
  }
}
