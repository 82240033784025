/**
パーミッション一覧
insightsDateAll: 計測期間設定7種（今日/昨日/今週//直近7日間/今月/直近30日間/カスタム期間）使用可能
insightsPageview：アナリティクスのページビューを閲覧できる
insightsUniqueUser：アナリティクスのUUを閲覧できる
insightsClick:アナリティクスのクリック数を閲覧できる
insightsCtr: アナリティクスのCTRを閲覧できる
insightsAccessRanking：アナリティクスのアクセスランキングを閲覧できる（上位5位まで）
insightsAccessRankingAll：アナリティクスのアクセスランキングをすべて閲覧できる
insightsStreamingClick:アナリティクスのストリーミングクリックを閲覧できる（上位5位まで）
insightsStreamingClickAll:アナリティクスのストリーミングクリックをすべて閲覧できる
insightsRegion: アナリティクスのアクセス地域を閲覧できる（上位5位まで）
insightsRegionAll: アナリティクスのアクセス地域をすべて閲覧できる
insightsReference：アナリティクスの参照元を閲覧できる（上位5位まで）
insightsReferenceAll：アナリティクスの参照元をすべて閲覧できる
insightsExport：アナリティクスの外部ファイルエクスポートができる
presave: プレリリースを行うことができる
userManagement：ダッシュボードのメンバー管理ができる
 */

export const state = () => ({
  acl: {
    free: {
      displayName: '個人プランFree',
      permissions: [
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'presave'
      ]
    },
    [process.env.PLAN_PERSONAL_BASIC]: {
      displayName: '個人プランBasic',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsReference',
        'insightsStreamingClick',
        'insightsRegion'
      ]
    },
    [process.env.PLAN_PERSONAL_BASIC_ANNUAL]: {
      displayName: '個人プランBasic(年間)',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsReference',
        'insightsStreamingClick',
        'insightsRegion'
      ]
    },
    [process.env.PLAN_PERSONAL_PREMIUM]: {
      displayName: '個人プランPremium',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport'
      ]
    },
    [process.env.PLAN_PERSONAL_PREMIUM_ANNUAL]: {
      displayName: '個人プランPremium（年間）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport'
      ]
    },
    [process.env.PLAN_BIZ_START]: {
      displayName: '法人プラン（Start for BIZ）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_START_ANNUAL]: {
      displayName: '法人プラン（Start for BIZ）（年間）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_BASIC]: {
      displayName: '法人プラン（Basic for BIZ）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_BASIC_ANNUAL]: {
      displayName: '法人プラン（Basic for BIZ）（年間）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_PREMIUM]: {
      displayName: '法人プラン（Premium for BIZ）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_PREMIUM_ANNUAL]: {
      displayName: '法人プラン（Premium for BIZ）（年間）',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_PERSONAL_202204]: {
      displayName: '個人プランV2 Personal',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport'
      ]
    },
    [process.env.PLAN_BIZ_SMALL]: {
      displayName: '法人プランV2 Small Team',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_ENTERPRISE_202204]: {
      displayName: '法人プランV2 Enterprise',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_PERSONAL_ANNUAL_202204]: {
      displayName: '個人プランV2 Personal(年間)',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport'
      ]
    },
    [process.env.PLAN_BIZ_SMALL_ANNUAL_202204]: {
      displayName: '法人プランV2 Small Team(年間)',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    },
    [process.env.PLAN_BIZ_ENTERPRISE_ANNUAL_202204]: {
      displayName: '法人プランV2 Enterprise(年間)',
      permissions: [
        'presave',
        'insightsDateAll',
        'insightsPageview',
        'insightsUniqueUser',
        'insightsClick',
        'insightsCtr',
        'insightsAccessRanking',
        'insightsAccessRankingAll',
        'insightsReference',
        'insightsReferenceAll',
        'insightsStreamingClick',
        'insightsStreamingClickAll',
        'insightsRegion',
        'insightsRegionAll',
        'insightsExport',
        'userManagement'
      ]
    }
  }
})

export const getters = {
  acl: (state) => {
    return state.acl
  }
}
