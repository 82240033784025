import { repository } from '../plugins/axios'
import MeCampaignsRepository from './MeCampaignsRepository'
import MeDashboardsRepository from './MeDashboardsRepository'
import MeDataCoordinationsRepository from './MeDataCoordinationsRepository'
import MeLinksInsightsRepository from './MeLinksInsightsRepository'
import MeLinksRepository from './MeLinksRepository'
import MeMembersRepository from './MeMembersRepository'
import MeSubscriptionRepository from './MeSubscriptionRepository'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getMe: () => {
    return repository.get(`${prefix}${resource}`)
  },
  putMe: (params) => {
    return repository.put(`${prefix}${resource}`, params)
  },
  deleteMe: () => {
    return repository.delete(`${prefix}${resource}`)
  },
  putPassword: (params) => {
    return repository.put(`${prefix}${resource}/password`, params)
  },
  getMemberDashBoards: () => {
    return repository.get(`${prefix}${resource}/member-dashboards`)
  },
  getNotifications: (params) => {
    return repository.get(`${prefix}${resource}/notifications`, {
      params
    })
  },
  getIsLogin: () => {
    return repository.get(`${prefix}${resource}/is_login`)
  },
  ...MeCampaignsRepository,
  ...MeDashboardsRepository,
  ...MeDataCoordinationsRepository,
  ...MeLinksInsightsRepository,
  ...MeLinksRepository,
  ...MeMembersRepository,
  ...MeSubscriptionRepository
}
