import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

// eslint-disable-next-line import/no-mutable-exports
export let repository

export default ({ $axios, $sentry, isDesktop, redirect }) => {
  $axios.onRequest((config) => {
    if (config.params) {
      config.params = snakecaseKeys(config.params, { deep: true })
    }
    if (!config.data || typeof config.data !== 'object') {
      return config
    }
    config.data = snakecaseKeys(config.data, { deep: true })
    return config
  })
  $axios.onResponse((response) => {
    if (!response.data || typeof response.data !== 'object') {
      return response
    }
    response.data = camelcaseKeys(response.data, { deep: true })
    return response
  })
  // $axios.onResponseError((error) => {
  //   if (error.response.status === 401) {
  //     if (isDesktop) {
  //       return redirect(302, '/login', { error: 'auth' })
  //     }
  //     return redirect(302, '/sp/login', { error: 'auth' })
  //   }
  // })
  $axios.interceptors.response.use(
    (res) => {
      if (reLoginResolves.length > 0) {
        reLoginResolves.forEach((resolve, index) => {
          resolve()
          reLoginResolves.splice(index, 1)
        })
      }
      return res
    },
    async (err) => {
      if (err.response.status === 401 && process.browser) {
        window.$nuxt.$store.commit('updateLoginInfo', { logged: false })
        const { config } = err

        config.headers.Authorization = localStorage.getItem('auth._token.user')
        await tryReLogin()
        return $axios.request(config)
      }
      $sentry.captureException(err)
      return Promise.reject(err.response)
    }
  )
  // $axios.onError((error) => {
  //   $sentry.captureException(error)
  //   return Promise.reject(error.response)
  // })
  repository = $axios
}

const reLoginResolves = []

function tryReLogin() {
  return new Promise((resolve) => {
    if (!reLoginResolves.length) {
      window.addEventListener(
        'message',
        function handleMessage(event) {
          if (!event.origin.includes(process.env.BASE_URL)) return
          reLoginResolves.forEach((resolve) => resolve())
          window.removeEventListener('message', handleMessage)
          // reLoginResolves = []
        },
        false
      )
    }
    reLoginResolves.push(resolve)
  })
}
