<template>
  <div class="TeaserSns_Wrapper">
    <span class="TeaserSns_Text">SNS SHARE</span>
    <a
      href="https://x.com/BOM_MusicTool"
      target="_blank"
      class="TeaserSns_LinkX"
    >
      <IconX class="TeaserSns_IconX" />
    </a>
    <a
      href="https://x.com/BOM_MusicTool"
      target="_blank"
      class="TeaserSns_LinkX"
    >
      <IconFacebook class="TeaserSns_IconX" />
    </a>
  </div>
</template>
<script>
import IconX from '@/static/img/icon-x.svg'
import IconFacebook from '@/static/img/icon-facebook.svg'
export default {
  components: {
    IconX,
    IconFacebook
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TeaserSns {
  &_Wrapper {
    width: 100%;
    padding: 4px 0;
    text-align: center;
    background-color: var(--c-black-2);
  }
  &_Text {
    margin-right: 10px;
    font-size: 18px;
    vertical-align: middle;
  }
  &_LinkX {
    display: inline-block;
    width: 48px;
    height: 52px;
    padding: 16px 0 0 13px;
    vertical-align: middle;
  }
  &_IconX {
    width: 24.56px;
    height: 24.56px;
    fill: var(--c-gray-3);
  }
}
@media (min-width: 751px) {
  .TeaserSns {
    &_Wrapper {
      width: 100%;
      padding: 4px 0;
      text-align: center;
      background-color: var(--c-black-2);
    }
    &_Text {
      margin-right: 10px;
      font-size: 18px;
      vertical-align: middle;
    }
    &_LinkX {
      display: inline-block;
      width: 48px;
      height: 52px;
      padding: 16px 0 0 13px;
      vertical-align: middle;
    }
    &_IconX {
      width: 24.56px;
      height: 24.56px;
      fill: var(--c-gray-3);
    }
  }
}
</style>
