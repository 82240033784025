<template>
  <div class="layout bg-dark">
    <TheFlashMessage v-show="$store.state.flashMessages.length > 0" />
    <template v-if="isLoginAdmin">
      <header class="header">
        <TheGlobalHeaderAdmin />
      </header>
    </template>
    <main class="main bg-secondary">
      <nuxt />
    </main>
  </div>
</template>

<script>
import TheGlobalHeaderAdmin from '@/components/TheGlobalHeaderAdmin.vue'
import TheFlashMessage from '@/components/TheFlashMessage'
import { CheckIsLoginAdmin } from '@/middleware/auth'
export default {
  name: 'Admin',
  head() {
    return {
      title: 'B.O.M Admin'
    }
  },
  middleware: CheckIsLoginAdmin,
  components: {
    TheGlobalHeaderAdmin,
    TheFlashMessage
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isLoginAdmin() {
      return (
        this.$route.path.match(/admin/) !== null &&
        this.$route.path.match(/admin\/login/) === null
      )
    }
  }
}
</script>
