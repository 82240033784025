import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getCampaigns: (params) => {
    return repository.get(`${prefix}${resource}/campaigns`, {
      params
    })
  },
  postCampaigns: (params) => {
    return repository.post(`${prefix}${resource}/campaigns`, params)
  },
  getCampaignById: (campaignId) => {
    return repository.get(`${prefix}${resource}/campaigns/detail/${campaignId}`)
  },
  deleteCampaign: (campaignId) => {
    return repository.delete(
      `${prefix}${resource}/campaigns/detail/${campaignId}`
    )
  },
  getCampaignFile: (campaignId, params) => {
    return repository.get(
      `${prefix}${resource}/campaigns/detail/${campaignId}/file`,
      {
        params
      }
    )
  },
  postCampaignImage: (params, options = {}) => {
    return repository.post(
      `${prefix}${resource}/campaigns/image`,
      params,
      options
    )
  },
  getCampaignPoints: () => {
    return repository.get(`${prefix}${resource}/checkout-campaign/points`)
  },
  getPurchaseCampaignPointsLink: () => {
    return repository.get(`${prefix}${resource}/checkout-campaign`)
  },
  postAddCampaignPointWithCoupon: (params) => {
    return repository.post(
      `${prefix}${resource}/checkout-campaign/coupon`,
      params
    )
  },
  postConsumeCampaignPoint: (campaignId) => {
    return repository.post(
      `${prefix}${resource}/checkout-campaign/points`,
      campaignId
    )
  }
}
