import RootRepository from './RootRepository'
import MeRepository from './MeRepository'
import LinksRepository from './LinksRepository'
import ServicesRepository from './ServicesRepository'
import ServiceApiRepository from './ServiceApiRepository'
import LambdaBomRepository from './LambdaBomRepository'
import AppleApiRepository from './AppleApiRepository'
import SpotifyApiRepository from './SpotifyApiRepository'
import PaymentRepository from './PaymentRepository'
import EmptyRepository from './EmptyRepository'
import CampaignsRepository from './CampaignsRepository'
import AdminRepository from './AdminRepository'
import NonRegisteredRepository from './NonRegisteredRepository'
import NoteRepository from './NoteRepository'

const repositories = {
  root: RootRepository,
  me: MeRepository,
  links: LinksRepository,
  services: ServicesRepository,
  serviceApi: ServiceApiRepository,
  lambda: LambdaBomRepository,
  apple: AppleApiRepository,
  spotify: SpotifyApiRepository,
  payment: PaymentRepository,
  empty: EmptyRepository,
  campaigns: CampaignsRepository,
  admin: AdminRepository,
  nonRegistered: NonRegisteredRepository,
  note: NoteRepository
}

export const RepositoryFactory = {
  get: (name) => repositories[name]
}
