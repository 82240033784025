<template>
  <div :class="{ ButtonPrime: prime }" class="ButtonComponent">
    <slot name="content" />
  </div>
</template>
<script>
export default {
  props: {
    prime: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="postcss" scoped>
.ButtonComponent {
  margin-left: 0.5vw;
  @media screen and (max-width: 750px) {
    margin-left: 0;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: auto;
    font-size: 12px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 5px;
    @media screen and (min-width: 751px) {
      height: 30px;
      padding: 7px 20px 8px;
      font-size: 12px;
      border-radius: 10px;
    }
  }
  &.ButtonPrime {
    a {
      color: #ffc107;
      border: 1px solid #ffc107;
      @media screen and (min-width: 751px) {
        width: 660px;
        border-radius: 5px;
      }
    }
  }
}
</style>
