import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend
} from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json' // エラーメッセージを日本語化します
import {
  required,
  numeric,
  ext,
  email,
  image,
  dimensions,
  size,
  confirmed,
  // eslint-disable-next-line camelcase
  alpha_dash
} from 'vee-validate/dist/rules' // 使用するバリデーションルールを指定します。
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const LinksRepository = RepositoryFactory.get('links')
const LambdaBomRepository = RepositoryFactory.get('lambda')
const AppleApiRepository = RepositoryFactory.get('apple')

// VeeValidateが用意している各ルールを使用するよう指定
extend('alpha_dash', alpha_dash)
extend('required', required)
extend('numeric', numeric)
extend('email', email)
extend('ext', ext)
extend('image', image)
extend('dimensions', dimensions)
extend('size', size)
extend('confirmed', confirmed)
extend('url-available', {
  params: ['currentUrlDomain', 'currentUrlPath'],
  message: (field) => `このURLは使用できません`,
  async validate(value, { currentUrlDomain, currentUrlPath }) {
    try {
      if (currentUrlPath === value) {
        return true
      }
      const domain = currentUrlDomain || process.env.LINK_PAGE_DEFAULT_DOMAIN
      const res = await LinksRepository.getUrlObrain({
        domain,
        path: value
      })
      return res.data.result === 'true'
    } catch (e) {
      console.log(e)
      return false
    }
  }
})

extend('uri-spotify', {
  validate(value) {
    if (value.match(/open.spotify.com\/(?:intl-[a-z]{2}\/)?album/)) {
      return true
    }
    return '無効なURLです'
  }
})

// linkfireのルートドメインURL、サブドメインURL用
extend('linkfire-url', {
  validate(value) {
    if (
      value.match(/^https:\/\/lnk.to/) ||
      value.match(/^https:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+\.lnk.to/) ||
      value.match(/^https:\/\/lnkfi.re/)
    ) {
      return true
    }
    return '無効なURLです'
  }
})

// linkCoreのルートドメインURL、サブドメインURL用
extend('linkcore-url', {
  validate(value) {
    if (
      value.match(/^https:\/\/linkco.re/) ||
      value.match(/^https:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+\.linkco.re/)
    ) {
      return true
    }
    return '無効なURLです'
  }
})

extend('spotify-artist-url', {
  validate(value) {
    if (
      value.match(
        /https:\/\/open\.spotify\.com\/(?:intl-[a-z]{2}\/)?artist\/[a-zA-Z0-9]+/g
      )
    ) {
      return true
    }
    return '無効なURLです'
  }
})

// Spotify for PodcastersのルートドメインURL、サブドメインURL用
extend('sfp-url', {
  validate(value) {
    if (value.match(/^https:\/\/podcasters.spotify.com/)) {
      return true
    }
    return '無効なURLです'
  }
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'パスワードが一致しません'
})

extend('max', {
  validate(value, args) {
    return value.length <= args.length
  },
  params: ['length']
})

extend('max-length', {
  validate(value, args) {
    if (value.length <= args.length) {
      return true
    }
    return '※文字数が多すぎます。'
  },
  params: ['length']
})

extend('match-value', {
  params: ['target'],
  message: (field) => {
    if (field === '現在のアドレス') return `入力値が${field}と一致しません`
    if (field === '確認のアドレス')
      return `入力値が新規登録のアドレスと一致しません`
    return `${field}が正しくありません`
  },
  validate(value, { target }) {
    return value === target
  }
})

extend('strong-password', {
  message: (field) =>
    `英数大文字、数字をそれぞれ最低1つ含め、かつ8文字以上である必要があります。`,
  validate: (value) => {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
    )
    return strongRegex.test(value)
  }
})

extend('required-checkbox', {
  message: (field) => 'この項目は必須です',
  validate: (value) => {
    return value === true
  }
})

export default ({ store }) => {
  extend('url-service', {
    validate(value) {
      for (let i = 0; i < store.state.supportedServices.length; i++) {
        if (value.match(store.state.supportedServices[i].pattern)) {
          return true
        }
      }
      return '無効なURLです'
    }
  })
  extend('uri-apple-music-id', {
    async validate(value) {
      if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
        return '無効なURIです'
      }
      const tokenRes = await LambdaBomRepository.getAccessToken('apple-music')
      const res = await AppleApiRepository.getCatalogInfo(
        'jp',
        value,
        'albums',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + tokenRes.data
        }
      ).catch((e) => {
        return e
      })
      if (res.status === 404) {
        return '無効なURIです'
      }
      return true
    }
  })
}

extend('url-service-scan', {
  validate(value) {
    if (
      value.match(
        'spotify.com/(?:intl-[a-z]{2}/)?(album|track|.*playlist)|(spoti.fi/)|spotify:(album|track)|(link.tospotify.com/)'
      ) ||
      value.match(
        'music.apple.com/((?<country>[a-z]{2}/)?(album|track|artist|book|movie|playlist|show|music-video|music-movie|apple-music|podcast|curator|music)|station|artist|subscribe|search|deeplink)|itun.es/[a-zA-Z0-9]+|apple.co/[a-zA-Z0-9]+|itunes.apple.com/'
      )
    ) {
      return true
    }

    return '無効なURLです'
  }
})

extend('element-size', {
  params: ['maxHeight', 'maxWidth', 'element'],
  validate(value, { maxHeight, maxWidth, element }) {
    if (!element) return true
    if (maxHeight >= element.clientHeight && maxWidth >= element.clientWidth) {
      return true
    } else {
      return false
    }
  },
  message: '文字数が多すぎます'
})

extend('prefecture', {
  validate(value) {
    if (value === '選択してください') {
      return '都道府県を選択してください'
    }
    return true
  }
})

// 下記は固定で書く
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
localize('ja', ja)
