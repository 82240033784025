import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getSubscriptionByDashboardId: (dashboardId) => {
    return repository.get(
      `${prefix}${resource}/subscription?dashboardId=${dashboardId}`
    )
  },
  postSubscription: (params) => {
    return repository.post(`${prefix}${resource}/subscription`, params)
  },
  deleteSubscriptionByDashboardId: (cancelAtPeriodEnd) => {
    return repository.delete(
      `${prefix}${resource}/subscription?cancelAtPeriodEnd=${cancelAtPeriodEnd}`
    )
  },
  putSubscription: (subscriptionId, params) => {
    return repository.put(
      `${prefix}${resource}/subscription/${subscriptionId}`,
      params
    )
  },
  getSubscriptionCustomer: () => {
    return repository.get(`${prefix}${resource}/subscription/customer`)
  },
  putSubscriptionCustomer: (params) => {
    return repository.put(`${prefix}${resource}/subscription/customer`, params)
  },
  getSubscriptionInvoices: (year) => {
    return repository.get(
      `${prefix}${resource}/subscription/invoices?year=${year}`
    )
  },
  getSubscriptionPaymentMethods: () => {
    return repository.get(`${prefix}${resource}/subscription/payment-methods`)
  },
  postSubscriptionPaymentMethods: (params) => {
    return repository.post(
      `${prefix}${resource}/subscription/payment-methods`,
      params
    )
  },
  deleteSubscriptionPaymentMethod: (paymentMethodId) => {
    return repository.delete(
      `${prefix}${resource}/subscription/payment-methods?paymentMethodId=${paymentMethodId}`
    )
  }
}
