import { repository } from '../plugins/axios'
const endpoint = process.env.LAMBDA_BOM_ENDPOINT

export default {
  getAccessToken: (name) => {
    return repository.get(`${endpoint}/services/token?name=${name}`)
  },
  getServicesBatchByUrl: (url) => {
    return repository.get(`${endpoint}/services/batch?url=${url}`)
  },
  getSuggestsArtist: (name) => {
    return repository.get(`${endpoint}/search/artist?q=${name}`)
  },
  postPresaves: (params) => {
    return repository.post(`${endpoint}/presaves`, params)
  }
}
