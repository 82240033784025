import { repository } from '../plugins/axios'
const prefix = '/api'
const resource = '/links'

export default {
  getLinks: (params) => {
    return repository.get(`${prefix}${resource}`, { params })
  },
  putLinks: (id) => {
    return repository.put(`${prefix}${resource}/${id}`)
  },
  getUrlObrain: (params) => {
    return repository.get(`${prefix}${resource}/url/obtain`, { params })
  },
  getLinkFireLinks: (url) => {
    return repository.post(`${prefix}${resource}/linkfire`, { url })
  },
  getLinkCoreLinks: (url) => {
    return repository.post(`${prefix}${resource}/linkcore`, { url })
  },
  getSfpLinks: (url) => {
    return repository.post(`${prefix}${resource}/spotify-for-podcasters`, {
      url
    })
  }
}
