<template>
  <div
    :class="[isSpAnalytics ? 'TheGlobalHeaderSpAnalitycs' : 'TheGlobalHeader']"
  >
    <button class="TheGlobalHeader_HumbergerButton" @click="toggleShowMenu">
      <span class="TheGlobalHeader_HumbergerButtonImage" />
    </button>
    <!-- SPメニュー -->
    <transition name="TheGlobalHeader_Left">
      <nav v-show="showMenu" class="TheGlobalHeader_Menu">
        <div class="TheGlobalHeader_MenuBoard">
          <div class="TheGlobalHeader_MenuHeader">
            <button
              class="TheGlobalHeader_MenuCloseButton"
              @click="toggleShowMenu"
            >
              閉じる
            </button>
            <img
              class="TheGlobalHeader_MenuLogo"
              src="/img/logo.png"
              alt="Logo"
            />
          </div>
          <div class="TheGlobalHeader_MenuBody">
            <ul class="TheGlobalHeader_MenuList">
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="
                    routing({
                      path: `/user/${$route.params.userId}/analytics`
                    })
                  "
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuLineChart
                      class="TheGlobalHeader_IconMenuLineChart"
                    />
                  </div>
                  <span class="TheGlobalHeader_MenuText">アナリティクス</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="routing({ path: `/user/${$route.params.userId}/` })"
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuLinkList
                      class="TheGlobalHeader_IconMenuLinkList"
                    />
                  </div>
                  <span class="TheGlobalHeader_MenuText">リンク一覧</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="
                    routing({
                      path: `/user/${$route.params.userId}/notification`
                    })
                  "
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuShout class="TheGlobalHeader_IconMenuShout" />
                  </div>
                  <span class="TheGlobalHeader_MenuText">お知らせ</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="
                    routing({
                      path: `/user/${$route.params.userId}/account/preferences`
                    })
                  "
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuSetting class="TheGlobalHeader_IconMenuSetting" />
                  </div>
                  <span class="TheGlobalHeader_MenuText">アカウント設定</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="
                    routing({
                      path: `/user/${$route.params.userId}/account/profile`
                    })
                  "
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuSetting class="TheGlobalHeader_IconMenuSetting" />
                  </div>
                  <span class="TheGlobalHeader_MenuText">ユーザー設定</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="
                    routing({
                      path: `/user/${$route.params.userId}/member`
                    })
                  "
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuSetting class="TheGlobalHeader_IconMenuSetting" />
                  </div>
                  <span class="TheGlobalHeader_MenuText">メンバー管理</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button
                  class="TheGlobalHeader_MenuLink"
                  @click="
                    routing({
                      path: `/user/${$route.params.userId}/connect`
                    })
                  "
                >
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuSetting class="TheGlobalHeader_IconMenuSetting" />
                  </div>
                  <span class="TheGlobalHeader_MenuText">データ連携管理</span>
                </button>
              </li>
              <li class="TheGlobalHeader_MenuItem">
                <button class="TheGlobalHeader_MenuLink" @click="logout">
                  <div class="TheGlobalHeader_MenuIconWrapper">
                    <IconMenuLogout class="TheGlobalHeader_IconMenuLogout" />
                  </div>
                  <span class="TheGlobalHeader_MenuText">ログアウト</span>
                </button>
              </li>
            </ul>
            <div class="TheGlobalHeader_MenuItemDiv">
              <a
                class="TheGlobalHeader_MenuLink"
                href="https://form.run/@bom--1592908099"
                target="_blank"
              >
                <div class="TheGlobalHeader_MenuIconWrapper">
                  <IconMenuMail class="TheGlobalHeader_IconMenuMail" />
                </div>
                <span class="TheGlobalHeader_MenuText">お問い合わせ</span></a
              >
            </div>
          </div>
        </div>
        <div class="TheGlobalHeader_MenuBackground" @click="toggleShowMenu" />
      </nav>
    </transition>
    <template v-if="isSpAnalytics">
      <div class="TheGlobalHeader_AnalyticsSelect">
        <button
          class="TheGlobalHeader_AnalyticsSelectButton"
          @click="toggleShowAnalyticsLinkList"
        >
          <img
            :src="analyticsLinkSelectedImage"
            class="TheGlobalHeader_AnalyticsSelectUserImage"
            alt="ダッシュボード画像"
          />
          <span class="TheGlobalHeader_AnalyticsSelectUserText">
            {{ analyticsLinkSelectedText }}
          </span>
          <i class="TheGlobalHeader_AnalyticsSelectUserIcon" />
        </button>
        <nav
          v-show="showAnalyticsLinkList"
          class="TheGlobalHeader_AnalyticsModal"
        >
          <!-- データ連携がある場合 -->
          <template
            v-if="
              childDataDashboards.length > 0 ||
                childDataDashboardsDetached.length > 0
            "
          >
            <ul class="TheGlobalHeader_AnalyticsSelectListWrapper">
              <li class="TheGlobalHeader_AnalyticsDataGroup">
                <ul class="TheGlobalHeader_AnalyticsDataGroupList">
                  <li
                    class="TheGlobalHeader_AnalyticsDataGroupSelectList"
                    :class="{
                      _isActive:
                        currentDataGroup ===
                        $store.getters.currentDashboard.dashboardId
                    }"
                    @click="
                      currentDataGroup =
                        $store.getters.currentDashboard.dashboardId
                    "
                  >
                    <img
                      class="TheGlobalHeader_AnalyticsDataGroupListImage"
                      :src="
                        $store.getters.currentDashboardDefaultProfile.imageUrl
                      "
                      alt="アカウント画像"
                    />
                    <p class="TheGlobalHeader_AnalyticsDataGroupListText">
                      {{
                        $store.getters.currentDashboardDefaultProfile
                          .displayName
                      }}
                    </p>
                  </li>
                  <li
                    v-for="dashboard in childDataDashboards"
                    :key="dashboard.dashboardId"
                    class="TheGlobalHeader_AnalyticsDataGroupSelectList"
                    :class="{
                      _isActive: currentDataGroup === dashboard.dashboardId
                    }"
                    @click="currentDataGroup = dashboard.dashboardId"
                  >
                    <img
                      class="TheGlobalHeader_AnalyticsDataGroupListImage"
                      :src="dashboard.imageUrl || '/img/no-image.png'"
                      alt="アカウント画像"
                    />
                    <p class="TheGlobalHeader_AnalyticsDataGroupListText">
                      {{ dashboard.displayName }}
                    </p>
                  </li>
                  <li
                    class="TheGlobalHeader_AnalyticsDataGroupSelectList"
                    :class="{ _isActive: currentDataGroup === 'deleted' }"
                    @click="currentDataGroup = 'deleted'"
                  >
                    <IconRoundDelete
                      class="TheGlobalHeader_AnalyticsDataGroupListImage"
                    />
                    <p class="TheGlobalHeader_AnalyticsDataGroupListText">
                      削除済み
                    </p>
                  </li>
                  <li
                    class="TheGlobalHeader_AnalyticsDataGroupSelectList"
                    :class="{ _isActive: currentDataGroup === 'detached' }"
                    @click="currentDataGroup = 'detached'"
                  >
                    <IconRoundLink
                      class="TheGlobalHeader_AnalyticsDataGroupListImage"
                    />
                    <p class="TheGlobalHeader_AnalyticsDataGroupListText">
                      連携解消
                    </p>
                  </li>
                </ul>
              </li>
              <li class="TheGlobalHeader_AnalyticsLinkGroup">
                <!-- 現在のダッシュボード -->
                <template
                  v-if="
                    !currentDataGroup ||
                      currentDataGroup ===
                        $store.getters.currentDashboard.dashboardId
                  "
                >
                  <!-- アコーディオンなし -->
                  <ul class="TheGlobalHeader_AnalyticsLinkGroupList">
                    <li
                      class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                      @click="emitClickAnalyticsList($route.query.type, null)"
                    >
                      合計データ
                    </li>
                    <template v-for="(link, index) in analyticsLinks">
                      <li
                        :key="index"
                        class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                        :class="{
                          _trackAlbum:
                            link.linkType.includes('track') ||
                            link.linkType.includes('album'),
                          _playlist: link.linkType.includes('playlist'),
                          _presave: link.linkType.includes('presave')
                        }"
                        @click="
                          emitClickAnalyticsList($route.query.type, link.linkId)
                        "
                      >
                        <p
                          class="TheGlobalHeader_AnalyticsLinkGroupSelectListText"
                        >
                          {{ link.title }}
                        </p>
                      </li>
                    </template>
                  </ul>
                </template>
                <!-- 削除済み -->
                <template v-else-if="currentDataGroup === 'deleted'">
                  <div>
                    <div
                      class="TheGlobalHeader_AnalyticsGroupTitle"
                      :class="{
                        _isActive:
                          currentLinkGroupDashboardId ===
                          `deleted-${$store.getters.currentDashboard.dashboardId}`
                      }"
                      @click="
                        setCurrentLinkGroupDashboardId(
                          `deleted-${$store.getters.currentDashboard.dashboardId}`
                        )
                      "
                    >
                      {{
                        $store.getters.currentDashboardDefaultProfile
                          .displayName
                      }}
                    </div>
                    <ul
                      v-if="
                        currentLinkGroupDashboardId ===
                          `deleted-${$store.getters.currentDashboard.dashboardId}`
                      "
                      class="TheGlobalHeader_AnalyticsLinkGroupList"
                    >
                      <template v-for="(link, index) in analyticsLinksDeleted">
                        <li
                          :key="index"
                          class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                          :class="{
                            _trackAlbum:
                              link.linkType.includes('track') ||
                              link.linkType.includes('album'),
                            _playlist: link.linkType.includes('playlist'),
                            _presave: link.linkType.includes('presave')
                          }"
                          @click="
                            emitClickAnalyticsList(
                              $route.query.type,
                              link.linkId
                            )
                          "
                        >
                          <p
                            class="TheGlobalHeader_AnalyticsLinkGroupSelectListText"
                          >
                            {{ link.title }}
                          </p>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div
                    v-for="dashboard in childDataDashboards"
                    :key="dashboard.dashboardId"
                  >
                    <!-- アコーディオンあり -->
                    <div
                      class="TheGlobalHeader_AnalyticsGroupTitle"
                      :class="{
                        _isActive:
                          currentLinkGroupDashboardId ===
                          `deleted-${dashboard.dashboardId}`
                      }"
                      @click="
                        setCurrentLinkGroupDashboardId(
                          `deleted-${dashboard.dashboardId}`
                        )
                      "
                    >
                      {{ dashboard.displayName }}
                    </div>
                    <ul
                      v-if="
                        currentLinkGroupDashboardId ===
                          `deleted-${dashboard.dashboardId}`
                      "
                      class="TheGlobalHeader_AnalyticsLinkGroupList"
                    >
                      <template
                        v-for="(link,
                        index) in dashboard.dataDashboardLinksDeleted"
                      >
                        <li
                          :key="index"
                          class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                          :class="{
                            _trackAlbum:
                              link.linkType.includes('track') ||
                              link.linkType.includes('album'),
                            _playlist: link.linkType.includes('playlist'),
                            _presave: link.linkType.includes('presave')
                          }"
                          @click="
                            emitClickAnalyticsList(
                              $route.query.type,
                              link.linkId,
                              dashboard.daashboardId
                            )
                          "
                        >
                          <p
                            class="TheGlobalHeader_AnalyticsLinkGroupSelectListText"
                          >
                            {{ link.title }}
                          </p>
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
                <!-- 連携解消 -->
                <template v-else-if="currentDataGroup === 'detached'">
                  <!-- アコーディオンあり -->
                  <div
                    v-for="dashboard in childDataDashboardsDetached"
                    :key="dashboard.dashboardId"
                  >
                    <div
                      class="TheGlobalHeader_AnalyticsGroupTitle"
                      :class="{
                        _isActive:
                          currentLinkGroupDashboardId ===
                          `detached-${dashboard.dashboardId}`
                      }"
                      @click="
                        setCurrentLinkGroupDashboardId(
                          `detached-${dashboard.dashboardId}`
                        )
                      "
                    >
                      {{ dashboard.displayName }}
                    </div>
                    <ul
                      v-if="
                        currentLinkGroupDashboardId ===
                          `detached-${dashboard.dashboardId}`
                      "
                      class="TheGlobalHeader_AnalyticsLinkGroupList"
                    >
                      <li
                        class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                        @click="
                          emitClickAnalyticsList(
                            $route.query.type,
                            null,
                            dashboard.dashboardId
                          )
                        "
                      >
                        {{ dashboard.displayName }}の合計データ
                      </li>
                      <li
                        v-for="(link, index) in dashboard.dataDashboardLinks"
                        :key="index"
                        class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                        :class="{
                          _trackAlbum:
                            link.linkType.includes('track') ||
                            link.linkType.includes('album'),
                          _playlist: link.linkType.includes('playlist'),
                          _presave: link.linkType.includes('presave')
                        }"
                        @click="
                          emitClickAnalyticsList(
                            $route.query.type,
                            link.linkId,
                            dashboard.dashboardId
                          )
                        "
                      >
                        <p
                          class="TheGlobalHeader_AnalyticsLinkGroupSelectListText"
                        >
                          {{ link.title }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- TODO:連携解消済み削除済みデータ -->
                  <div
                    class="TheGlobalHeader_AnalyticsGroupTitle"
                    :class="{
                      _isActive: currentLinkGroupDashboardId.includes(
                        `detached-deleted`
                      )
                    }"
                    @click="setCurrentLinkGroupDashboardId(`detached-deleted`)"
                  >
                    削除済みデータ
                  </div>
                  <ul
                    v-if="
                      currentLinkGroupDashboardId.includes(`detached-deleted`)
                    "
                    class="TheGlobalHeader_AnalyticsLinkGroupList"
                  >
                    <template v-for="dashboard in childDataDashboardsDetached">
                      <li :key="dashboard.dashboardId">
                        <div
                          class="TheGlobalHeader_AnalyticsGroupTitle"
                          :class="{
                            _isActive:
                              currentLinkGroupDashboardId ===
                              `detached-deleted-${dashboard.dashboardId}`
                          }"
                          @click="
                            setCurrentLinkGroupDashboardId(
                              `detached-deleted-${dashboard.dashboardId}`
                            )
                          "
                        >
                          {{ dashboard.displayName }}
                        </div>
                        <ul
                          v-if="
                            currentLinkGroupDashboardId ===
                              `detached-deleted-${dashboard.dashboardId}`
                          "
                          class="TheGlobalHeader_AnalyticsLinkGroupList"
                        >
                          <li
                            v-for="(link,
                            index) in dashboard.dataDashboardLinksDeleted"
                            :key="index"
                            class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                            :class="{
                              _trackAlbum:
                                link.linkType.includes('track') ||
                                link.linkType.includes('album'),
                              _playlist: link.linkType.includes('playlist'),
                              _presave: link.linkType.includes('presave')
                            }"
                            @click="
                              emitClickAnalyticsList(
                                $route.query.type,
                                link.linkId,
                                dashboard.dashboardId
                              )
                            "
                          >
                            <p
                              class="TheGlobalHeader_AnalyticsLinkGroupSelectListText"
                            >
                              {{ link.title }}
                            </p>
                          </li>
                        </ul>
                      </li>
                    </template>
                  </ul>
                </template>
                <!-- データ連携中アカウント -->
                <template v-else>
                  <div
                    v-for="dashboard in childDataDashboards"
                    :key="dashboard.dashboardId"
                  >
                    <template v-if="currentDataGroup === dashboard.dashboardId">
                      <!-- アコーディオンなし -->
                      <ul class="TheGlobalHeader_AnalyticsLinkGroupList">
                        <li
                          class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                          @click="
                            emitClickAnalyticsList(
                              $route.query.type,
                              null,
                              dashboard.dashboardId
                            )
                          "
                        >
                          合計データ
                        </li>
                        <template
                          v-for="(link, index) in dashboard.dataDashboardLinks"
                        >
                          <li
                            :key="index"
                            class="TheGlobalHeader_AnalyticsLinkGroupSelectList"
                            :class="{
                              _trackAlbum:
                                link.linkType.includes('track') ||
                                link.linkType.includes('album'),
                              _playlist: link.linkType.includes('playlist'),
                              _presave: link.linkType.includes('presave')
                            }"
                            @click="
                              emitClickAnalyticsList(
                                $route.query.type,
                                link.linkId,
                                dashboard.dashboardId
                              )
                            "
                          >
                            <p
                              class="TheGlobalHeader_AnalyticsLinkGroupSelectListText"
                            >
                              {{ link.title }}
                            </p>
                          </li>
                        </template>
                      </ul>
                    </template>
                  </div>
                </template>
              </li>
            </ul>
          </template>
          <!-- データ連携がない場合 -->
          <template v-else>
            <ul class="TheGlobalHeader_AnalyticsSelectSingleListWrapper">
              <li
                class="TheGlobalHeader_AnalyticsSelectListActive"
                @click="emitClickAnalyticsList($route.query.type, null)"
              >
                {{ this.$store.state.user.userOrganizationName }}の合計データ
              </li>
              <template v-for="(link, index) in analyticsLinks">
                <li
                  :key="index"
                  class="TheGlobalHeader_AnalyticsSelectList"
                  @click="
                    emitClickAnalyticsList($route.query.type, link.linkId)
                  "
                >
                  <img
                    :src="link.imageUrl"
                    class="TheGlobalHeader_AnalyticsSelectListImage"
                  />
                  <span class="TheGlobalHeader_AnalyticsSelectListText">
                    {{ link.title }}
                  </span>
                </li>
              </template>
            </ul>
          </template>
          <div
            class="TheGlobalHeader_AnalyticsModalBackground"
            @click="toggleShowAnalyticsLinkList"
          />
        </nav>
      </div>
    </template>
    <template v-else>
      <button
        class="TheGlobalHeader_Logo"
        @click="routing({ path: `/sp/user/${$route.params.userId}` })"
      >
        <img class="TheGlobalHeader_LogoImage" src="/img/logo.png" alt="Logo" />
      </button>
    </template>
    <button class="TheGlobalHeader_SelectButton" @click="toggleSelectUser">
      <img
        :src="userImage"
        class="TheGlobalHeader_SelectUserImage"
        alt="ユーザー画像"
      />
    </button>
    <!-- SPユーザメニュー -->
    <transition name="TheGlobalHeader_Right">
      <nav v-show="selectUser" class="TheGlobalHeader_Menu">
        <div class="TheGlobalHeader_MenuBackground" @click="toggleSelectUser" />
        <div class="TheGlobalHeader_MenuBoard">
          <div class="TheGlobalHeader_MenuHeader">
            <button
              class="TheGlobalHeader_MenuCloseButton"
              @click="toggleSelectUser"
            >
              閉じる
            </button>
            <img
              class="TheGlobalHeader_MenuLogo"
              src="/img/logo.png"
              alt="Logo"
            />
            <img
              :src="userImage"
              class="TheGlobalHeader_MenuUserImage"
              alt="ユーザー画像"
            />
          </div>
          <ul class="TheGlobalHeader_MenuListUser">
            <li
              v-for="dashboard in $store.state.dashboards"
              :key="dashboard.daashboardId"
              class="TheGlobalHeader_MenuItem"
              @click="changeCurrentDashboard(dashboard.dashboardId)"
            >
              <div class="TheGlobalHeader_MenuLink">
                <img
                  :src="dashboard.imageUrl"
                  :alt="dashboard.displayName"
                  class="TheGlobalHeader_MenuIconAccount"
                />
                <span
                  :class="{
                    _isActive:
                      $store.state.user.currentDashboardId ===
                      dashboard.dashboardId
                  }"
                  class="TheGlobalHeader_MenuText"
                  >{{ dashboard.displayName }}</span
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </transition>
  </div>
</template>
<script>
import {
  ScrollLockWithTheEx,
  ScrollPermitWithTheEx
} from '~/modules/scroll-control'
import { RepositoryFactory } from '~/repositories/RepositoryFactory'
import IconMenuLineChart from '@/static/img/icon-menu-line-chart.svg'
import IconMenuLinkList from '@/static/img/icon-menu-line-list.svg'
import IconMenuShout from '@/static/img/icon-menu-shout.svg'
import IconMenuSetting from '@/static/img/icon-menu-setting.svg'
import IconMenuLogout from '@/static/img/icon-menu-logout.svg'
import IconMenuMail from '@/static/img/icon-menu-mail.svg'
import IconRoundDelete from '@/static/img/icon-round-delete.svg'
import IconRoundLink from '@/static/img/icon-round-link.svg'

const MeRepository = RepositoryFactory.get('me')
export default {
  components: {
    IconMenuLineChart,
    IconMenuLinkList,
    IconMenuShout,
    IconMenuSetting,
    IconMenuLogout,
    IconMenuMail,
    IconRoundDelete,
    IconRoundLink
  },
  props: {
    analyticsLinks: {
      type: Array,
      default: () => [],
      required: false
    },
    analyticsLinksDeleted: {
      type: Array,
      default: () => [],
      required: false
    },
    analyticsLinkSelectedText: {
      type: String,
      default: null,
      required: false
    },
    analyticsLinkSelectedImage: {
      type: String,
      default: null,
      required: false
    },
    childDataDashboards: {
      type: Array,
      default: () => [],
      required: false
    },
    childDataDashboardsDetached: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      showMenu: false,
      selectUser: false,
      selectPreferences: false,
      showAnalyticsLinkList: false,
      currentDataGroup: null,
      currentLinkGroupDashboardId: this.$store.getters.currentDashboard
        .dashboardId
    }
  },
  computed: {
    userImage() {
      return (
        this.$store.getters.currentDashboardDefaultProfile.imageUrl ||
        this.$store.state.user.imageUrl
      )
    },
    isSpAnalytics() {
      return this.$route.path.match(/sp\/.*\/analytics/) !== null
    }
  },
  beforeDestroy() {
    if (this.showMenu) {
      this.toggleShowMenu()
    }
    if (this.selectUser) {
      this.toggleSelectUser()
    }
  },
  methods: {
    async changeCurrentDashboard(dashboardId) {
      if (this.$store.state.user.currentDashboardId === dashboardId) {
        return
      }
      try {
        await MeRepository.putDashboardsCurrent(dashboardId)
        location.reload()
      } catch (e) {
        console.error(e)
      }
    },
    async logout() {
      try {
        await this.$auth.logout('user')
        this.$router.push({ path: '/sp/login' })
      } catch (e) {
        window.alert(`ログアウトに失敗しました(${e})`)
      }
    },
    async toggleShowMenu() {
      // menu表示=>非表示
      if (this.showMenu) {
        const exEl = await document.querySelector('.TheGlobalHeader_MenuBody')
        ScrollPermitWithTheEx(exEl)
        return (this.showMenu = false)
      }
      // menu非表示=>表示
      this.showMenu = true
      const exEl = await document.querySelector('.TheGlobalHeader_MenuBody')
      return ScrollLockWithTheEx(exEl)
    },
    async toggleSelectUser() {
      // user menu表示=>非表示
      if (this.selectUser) {
        const exEl = await document.querySelector('.TheGlobalHeader_MenuList')
        ScrollPermitWithTheEx(exEl)
        return (this.selectUser = false)
      }
      // user menu非表示=>表示
      this.selectUser = true
      const exEl = await document.querySelector('.TheGlobalHeader_MenuList')
      return ScrollLockWithTheEx(exEl)
    },
    onClickPreferences() {
      this.selectPreferences = false
    },
    preview() {
      this.$router.push({ query: { isPreviewLink: true } })
    },
    async update() {
      await MeRepository.putLinksById(this.$route.query.id)
      this.$router.push({
        path: `sp/user/${this.$route.params.userId}/`,
        query: { update: true }
      })
    },
    toggleShowAnalyticsLinkList() {
      const analyticsListWrapperEl = document.querySelectorAll(
        '.TheGlobalHeader_AnalyticsSelectSingleListWrapper, .TheGlobalHeader_AnalyticsSelectListWrapper'
      )
      if (!analyticsListWrapperEl[0]) return
      if (!this.showAnalyticsLinkList) {
        ScrollLockWithTheEx(analyticsListWrapperEl[0])
        return (this.showAnalyticsLinkList = true)
      }
      ScrollPermitWithTheEx(analyticsListWrapperEl[0])
      return (this.showAnalyticsLinkList = false)
    },
    emitClickAnalyticsList(type, linkId, dashboardId) {
      this.$emit('clicked-list', { type, linkId, dashboardId })
      this.currentLinkGroupDashboardId = this.$store.getters.currentDashboard.dashboardId
      return this.toggleShowAnalyticsLinkList()
    },
    routing(path) {
      if (this.showMenu) {
        this.toggleShowMenu()
      }
      if (this.selectUser) {
        this.toggleSelectUser()
      }
      this.$router.push(path)
    },
    setCurrentLinkGroupDashboardId(dashboardId) {
      if (
        this.currentLinkGroupDashboardId.includes('detached-deleted-') &&
        this.currentLinkGroupDashboardId === dashboardId
      ) {
        this.currentLinkGroupDashboardId = 'detached-deleted'
        return
      }
      this.currentLinkGroupDashboardId =
        !this.currentLinkGroupDashboardId ||
        this.currentLinkGroupDashboardId !== dashboardId
          ? dashboardId
          : this.$store.getters.currentDashboard.dashboardId
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TheGlobalHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 52.5px;
  margin: auto;
  background-color: var(--c-black-base);
  border-bottom: 1px solid var(--c-gray-5);
  &_HumbergerButton {
    width: 48px;
    height: 100%;
    padding: 10px 0 10px 15px;
  }
  &_HumbergerButtonImage {
    position: relative;
    display: block;
    width: 18px;
    height: 15px;
    margin: auto 0;
    border-top: 1px solid var(--c-white-base);
    border-bottom: 1px solid var(--c-white-base);
    &:before {
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 100%;
      content: '';
      border-top: 1px solid var(--c-white-base);
    }
  }
  &_Menu {
    position: absolute;
    z-index: var(--zi-global-menu);
    display: flex;
    width: 100vw;
    height: 100vmax;
    overflow: hidden;
    transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &_MenuBackground {
    flex: 1;
    height: 100%;
    background-color: var(--c-black-base);
    opacity: 0.8;
  }
  &_MenuBoard {
    width: 319px;
    height: 100%;
    background-color: var(--c-black-base);
  }
  &_MenuHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 53px;
    background-color: var(--c-black-base);
    &:first-of-type {
      border-bottom: 1px solid var(--c-gray-5);
    }
  }
  &_MenuBody {
    height: 100%;
    overflow: scroll;
  }
  &_MenuCloseButton {
    width: 82px;
    height: 100%;
    padding: 15px;
    margin-right: 55px;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 17px;
    color: var(--c-red-base);
  }
  &_MenuLogo {
    width: 45.68px;
    height: 29.46px;
  }
  &_MenuUserImage {
    width: 32px;
    height: 32px;
    margin-left: 89.3px;
    border-radius: 50px;
  }
  &_MenuName {
    display: block;
    margin: 30px 0 10px 15px;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 17px;
    letter-spacing: 0.03em;
    &:first-of-type {
      margin-top: 22px;
    }
  }
  &_MenuList {
    width: 100%;
  }
  &_MenuListUser {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  &_MenuItem {
    height: 60px;
    margin-bottom: 1px;
    background-color: var(--c-black-2);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_MenuItemDiv {
    height: 60px;
    margin-top: 30px;
    margin-bottom: 150px;
    background-color: var(--c-black-2);
  }
  &_MenuLink {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &_MenuIconWrapper {
    position: absolute;
    top: 50%;
    left: 34px;
    width: 30.64px;
    height: 20px;
    transform: translate(-50%, -50%);
  }
  &_IconMenuLineChart {
    width: 30.64px;
  }
  &_IconMenuLinkList {
    width: 20px;
  }
  &_IconMenuShout {
    width: 21.16px;
  }
  &_IconMenuSetting {
    width: 20px;
  }
  &_IconMenuLogout {
    width: 17.52px;
  }
  &_IconMenuMail {
    width: 17.76px;
  }
  &_MenuIconAccount {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    width: 32px;
    height: 32px;
    margin: auto;
    border-radius: 50px;
  }
  &_MenuText {
    margin: auto 0 auto 64px;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 17px;
    letter-spacing: 0.03em;
    &._isActive {
      color: var(--c-orange-base);
    }
  }
  &_Logo {
    display: inline-block;
    align-self: center;
    width: 45.68px;
    height: 29.46px;
  }
  &_LogoImage {
    width: 100%;
    height: 100%;
  }
  &_SelectButton {
    position: relative;
    width: 48px;
    height: 100%;
    padding: 10px 15px 10px 0;
  }
  &_SelectUserImage {
    width: 32px;
    height: 32px;
    border-radius: 50px;
  }
  &_AnalyticsSelect {
    position: relative;
    width: 265px;
    height: 32px;
    padding: 4px 5px;
    margin: auto 0;
    background-color: var(--c-black-2);
    border-radius: 5px;
  }
  &_AnalyticsSelectButton {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &_AnalyticsSelectUserImage {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    border-radius: 50px;
  }
  &_AnalyticsSelectUserText {
    display: block;
    max-width: 200px;
    margin: 4px 0 5px;
    overflow-x: hidden;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_AnalyticsSelectUserIcon {
    position: relative;
    display: block;
    width: 7.85px;
    height: 4.77px;
    margin: auto 11.3px auto auto;
    background-image: url('/img/icon-arrow-down-red.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  &_AnalyticsModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--zi-global-menu);
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  &_AnalyticsModalBackground {
    width: 100%;
    height: 100%;
    background-color: var(--c-black-base);
    opacity: 0.8;
  }
  &_AnalyticsSelectListWrapper {
    position: absolute;
    top: 52px;
    left: 15px;
    z-index: var(--zi-sort-list-wrapper);
    display: flex;
    width: 344px;
    height: 700px;
    /* overflow: scroll; */
    list-style: none;
    background-color: var(--c-black-base);
    border: 1px solid var(--c-gray-1);
    border-radius: 5px;
    box-shadow: 0 0 5px 0 var(--c-black-4);
  }
  &_AnalyticsSelectSingleListWrapper {
    position: absolute;
    top: 52px;
    z-index: var(--zi-sort-list-wrapper);
    width: 276px;
    height: 303px;
    padding: 16px 12px 7px;
    margin: 10px 50px auto;
    overflow: scroll;
    overflow-y: scroll;
    list-style: none;
    background-color: var(--c-black-base);
    border: 1px solid var(--c-gray-1);
    border-radius: 5px;
    box-shadow: 0 0 5px 0 var(--c-black-4);
  }
  &_AnalyticsSelectListActive {
    height: 38.5px;
    height: 38.5px;
    font-size: 13px;
    color: var(--c-orange-base);
    word-break: break-word;
    cursor: pointer;
    border-bottom: 1px solid var(--c-black-2);
  }
  &_AnalyticsSelectList {
    display: flex;
    align-items: center;
    height: 25px;
    margin-bottom: 20px;
    color: var(--c-white-base);
    cursor: pointer;
    &:nth-child(2) {
      margin-top: 16.5px;
    }
  }
  &_AnalyticsSelectListImage {
    width: 25px;
    height: 100%;
    margin-right: 10px;
    border-radius: 50px;
  }
  &_AnalyticsSelectListText {
    display: block;
    width: 216px;
    overflow: hidden;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_AnalyticsDataGroup {
    width: 60px;
    height: 700px;
    padding: 24px 0 0;
    overflow: scroll;
    border-right: 1px solid var(--c-gray-1);
  }
  &_AnalyticsDataGroupSelectList {
    padding: 11px 0 10px;
    &._isActive {
      background: var(--c-black-2);
    }
  }
  &_AnalyticsDataGroupListImage {
    width: 32px;
    height: 32px;
    margin: auto;
    border-radius: 50px;
    object-fit: cover;
  }
  &_AnalyticsDataGroupListText {
    width: 100%;
    padding: 0 4px;
    overflow: hidden;
    font-size: 10px;
    line-height: 1.6;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_AnalyticsLinkGroup {
    width: 100%;
    height: 550px;
    padding: 13px 0 0;
    overflow-y: scroll;
  }
  &_AnalyticsLinkGroupList {
    /* height: 500px;
    overflow: scroll; */
  }
  &_AnalyticsLinkGroupSelectList {
    padding: 11px 16px 12px 16px;
    font-size: 13px;
    &._isActive {
      color: var(--c-orange-base);
      background-color: var(--c-black-2);
    }
    &._trackAlbum {
      position: relative;
      padding: 11px 16px 12px 26px;
      &:after {
        position: absolute;
        top: 13px;
        left: 15px;
        width: 4px;
        height: 20px;
        content: '';
        background-color: var(--c-orange-base);
        border-radius: 12px;
      }
    }
    &._playlist {
      position: relative;
      padding: 11px 16px 12px 26px;
      &:after {
        position: absolute;
        top: 13px;
        left: 15px;
        width: 4px;
        height: 20px;
        content: '';
        background-color: var(--c-blue-base);
        border-radius: 12px;
      }
    }
    &._presave {
      position: relative;
      padding: 11px 16px 12px 26px;
      &:after {
        position: absolute;
        top: 13px;
        left: 15px;
        width: 4px;
        height: 20px;
        content: '';
        background-color: var(--c-tarquoise-base);
        border-radius: 12px;
      }
    }
  }
  &_AnalyticsLinkGroupSelectListText {
    width: 250px;
    margin: 0;
    overflow: hidden;
    line-height: 1.5;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_AnalyticsGroupTitle {
    position: relative;
    padding: 13px 0 14px 16px;
    overflow: hidden;
    font-size: 13px;

    &:after {
      position: absolute;
      top: 0;
      right: 12px;
      bottom: 0;
      display: block;
      width: 4.47px;
      height: 7.85px;
      margin: auto;
      content: '';
      background-image: url('/img/icon-arrow-white.svg');
      background-repeat: no-repeat;
      transform: rotate(90deg);
    }
    &._isActive {
      color: var(--c-orange-base);
      background-color: var(--c-black-2);
      &:after {
        background-image: url('/img/icon-arrow-orange.svg');
        transform: rotate(-90deg);
      }
    }
  }
  /* menu animation */
  &_Left-enter,
  &_Left-leave-to {
    transform: translateX(-100%) translateX(0%);
  }
  &_Left-enter-active,
  &_Left-leave-active {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &_Right-enter,
  &_Right-leave-to {
    transform: translateX(100%) translateX(0%);
  }
  &_Right-enter-active,
  &_Right-leave-active {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.TheGlobalHeaderSpAnalitycs {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 52.5px;
  margin: auto;
  background-color: var(--c-black-base);
}
</style>
