// import { weekdaysMin } from 'moment'
import moment from 'moment'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MeRepository = RepositoryFactory.get('me')
const NonRegistered = RepositoryFactory.get('nonRegistered')

export const state = () => ({
  flashMessages: [],
  linkData: null,
  linkDataFull: null,
  user: null,
  isLogIn: false,
  dashboards: null,
  deprecatedServices: [
    {
      name: 'hikari-tv-music',
      pattern: 'music.hikaritv.net',
      title: 'ひかりTVミュージック',
      type: 'audio',
      defaultDestinationText: '購入'
    }
  ],
  supportedServices: [
    {
      name: 'amazon-music',
      pattern: '^(?!.*podcasts).*amazon.co.jp',
      title: 'Amazon Music',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'amazon-music-podcasts',
      pattern: `amazon.co.jp/.*podcasts.*`,
      title: 'Amazon Music(Podcast)',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'apple-music',
      pattern: `music.apple.com/((?<country>[a-z]{2}/)?(album|track|artist|book|movie|playlist|show|music-video|music-movie|apple-music|podcast|curator|music)|station|artist|subscribe|search|deeplink)`,
      title: 'Apple Music',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'awa',
      pattern: `awa.fm`,
      title: 'AWA',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'deezer',
      pattern: 'deezer.com/([a-zA-Z]{2}/|)(track|artist|album|playlist)',
      title: 'Deezer',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'd-hits',
      pattern: 'selection.music.dmkt-sp.jp',
      title: 'dヒッツ',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'd-music',
      pattern: '^(?!.*selection).*music.dmkt-sp.jp',
      title: 'dミュージック',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'hikari-tv-music',
      pattern: 'music.hikaritv.net',
      title: 'ひかりTVミュージック',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'itunes-store',
      pattern: `music.apple.com/.*app=itunes|itun.es/[a-zA-Z0-9]+|apple.co/[a-zA-Z0-9]+|itunes.apple.com/`,
      title: 'iTunes Store',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'kkbox',
      pattern: 'kkbox.com',
      title: 'kkbox',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'line-music',
      pattern: `line.me|lin.ee`,
      title: 'LINE MUSIC',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'mora',
      pattern: 'mora.jp',
      title: 'mora',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'rakuten-music',
      pattern: 'music.rakuten.co.jp',
      title: 'Rakuten music',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'rec-music',
      pattern: 'recmusic.jp',
      title: 'RecMusic',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'recochoku',
      pattern: 'recochoku.jp',
      title: 'レコチョク',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'spotify',
      pattern: `spotify.com/(?:intl-[a-z]{2}/)?(album|artist|track|.*playlist)|(spoti.fi/)|spotify:(album|track|artist)|(link.tospotify.com/)`,
      title: 'Spotify',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'spotify-podcasts',
      pattern: `spotify.com/(?:intl-[a-z]{2}/)?(episode|show)|(spoti.fi/)|spotify:(episode|show)|(link.tospotify.com/)`,
      title: 'Spotify(Podcast)',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'soundcloud',
      pattern: 'soundcloud.com',
      title: 'SoundCloud',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'youtube',
      pattern: `^(?!.*music).*(youtube.com|youtu.be)`,
      title: 'YouTube',
      type: 'video',
      defaultDestinationText: '再生'
    },
    {
      name: 'youtube-music',
      pattern: `music.youtube.com`,
      title: 'YouTube Music',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'youtube-link',
      pattern: `^(?!.*music).*(youtube.com|youtu.be)`,
      title: 'YouTube Link',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'ototoy',
      pattern: `ototoy.jp`,
      title: 'OTOTOY',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'oricon-music-store',
      pattern: `music.oricon.co.jp`,
      title: 'オリコンミュージックストア',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'bandcamp',
      pattern: `bandcamp.com`,
      title: 'bandcamp',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'netflix',
      pattern: `netflix.com`,
      title: 'Netflix',
      type: 'video',
      defaultDestinationText: '視聴'
    },
    {
      name: 'hulu',
      pattern: `hulu.jp`,
      title: 'hulu',
      type: 'video',
      defaultDestinationText: '視聴'
    },
    {
      name: 'amazon-prime-video',
      pattern: `amazon.co.jp/.*video.*`,
      title: 'Amazon Prime Video',
      type: 'video',
      defaultDestinationText: '視聴'
    },
    {
      name: 'apple-podcasts',
      pattern: `podcasts.apple.com`,
      title: 'Apple Podcasts',
      type: 'audio',
      defaultDestinationText: '再生'
    },
    {
      name: 'x',
      pattern: `(twitter|//x).com`,
      title: 'X',
      type: 'audio',
      defaultDestinationText: 'フォロー'
    },
    {
      name: 'instagram',
      pattern: `instagram.com`,
      title: 'Instagram',
      type: 'audio',
      defaultDestinationText: 'フォロー'
    },
    {
      name: 'music-jp',
      pattern: `music-book.jp`,
      title: 'music.jp',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'u-next',
      pattern: `video.unext.jp`,
      title: 'U-NEXT',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'amazon',
      pattern: '^(?!.*podcasts).*amazon.co.jp',
      title: 'Amazon',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'the-nft-records',
      pattern: 'jp.thenftrecords.com',
      title: 'The NFT Records',
      type: 'audio',
      defaultDestinationText: '購入'
    },
    {
      name: 'google-podcasts',
      pattern: 'podcasts.google.com',
      title: 'Google Podcasts',
      type: 'audio',
      defaultDestinationText: '再生'
    }
  ]
})

export const getters = {
  currentDashboard: (state) => {
    if (!state.dashboards) {
      return null
    }
    const index = state.dashboards.findIndex(
      (dashboard) => dashboard.dashboardId === state.user.currentDashboardId
    )
    return state.dashboards[index]
  },
  currentDashboardDefaultProfile: (state) => {
    if (!state.dashboards) {
      return null
    }
    const index = state.dashboards.findIndex(
      (dashboard) => dashboard.dashboardId === state.user.currentDashboardId
    )
    return state.dashboards[index].profiles.find(
      (profile) => profile.isDefaultProfile
    )
  },
  currentDashboardRole: (state) => {
    if (!state.user) {
      return null
    }
    const index = state.user.userRoles.findIndex(
      (userRole) => userRole.dashboardHashId === state.user.currentDashboardId
    )
    return state.user.userRoles[index].roleType
  },
  isUserInitialDashboard: (state) => {
    if (!state.user) {
      return null
    }
    return state.user.currentDashboardId === state.user.userRegistDashboardId
  }
}

export const mutations = {
  addLinkDataServiceAudio(state, { service }) {
    console.log(service)
    state.linkData.services.push(service)
  },
  deleteFlashMessage(state, { index }) {
    state.flashMessages = state.flashMessages.filter((v, i) => i !== index)
  },
  setLinkData(state, { linkData, linkDataFull }) {
    state.linkData = linkData
    state.linkDataFull = linkDataFull
  },
  changeEditFormat(state, { formatId }) {
    const index = state.linkDataFull.formats.findIndex(
      (format) => format.formatId === formatId
    )
    console.log(index)
    if (index === -1) {
      return
    }
    state.linkData = state.linkDataFull.formats[index]
  },
  setEditLinkData(state, { linkData, linkDataFull }) {
    for (let iFormats = 0; iFormats < linkDataFull.formats.length; iFormats++) {
      const format = linkDataFull.formats[iFormats]

      const isVideoServices =
        format.services.findIndex((service) => service.type === 'video') !== -1
      if (!isVideoServices) {
        format.services.push({
          type: 'video',
          name: 'youtube',
          title: 'YouTube',
          url: ''
        })
      }
      for (let i = 0; i < format.services.length; i++) {
        if (!format.services[i].destinationText) {
          const indexService = state.supportedServices.findIndex(
            (service) => service.name === format.services[i].name
          )
          // NOTE: リンクページの繊維先テキスト（再生、購入など）を設定する
          // リリース日を超えている場合、誘導テキストをデフォルト(Pre-Add, Pre-Save)から再生に変更する
          if (indexService !== -1) {
            const service = state.supportedServices[indexService]
            const isPresave = linkDataFull.linkType.includes('presave')
            const isAppleMusic = service.name === 'apple-music'
            const isSpotify = service.name === 'spotify'
            const isYoutubeMusic = service.name === 'youtube-music'
            const isAmazonMusic = service.name === 'amazon-music'
            const isBeforeRelease = !moment().isSameOrAfter(
              linkDataFull.releaseDate
            )

            if (isPresave && isAppleMusic && isBeforeRelease) {
              format.services[i].destinationText = 'Pre-Add'
            } else if (isPresave && isSpotify && isBeforeRelease) {
              format.services[i].destinationText = 'Pre-Save'
            } else if (isPresave && isYoutubeMusic && isBeforeRelease) {
              format.services[i].destinationText = 'Pre-Save'
            } else if (isPresave && isAmazonMusic && isBeforeRelease) {
              format.services[i].destinationText = 'Pre-Save'
            } else {
              format.services[i].destinationText =
                service.defaultDestinationText
            }
          }
        }
      }
    }
    state.linkDataFull = linkDataFull
    state.linkData = linkData
  },
  setEditLinkDataServiceAudio(state, { linkDataService }) {
    state.linkData.services.audios = linkDataService
  },
  setLinkFormats(state, { linkFormats }) {
    state.linkFormats = linkFormats
  },
  setLinkCheckAudio(state, { index, deleteState }) {
    state.linkData.services.audios[index].delete = deleteState
  },
  setLinkImage(state, { imageUrl }) {
    state.linkData.imageUrl = imageUrl
  },
  setUser(state, { user, displayName }) {
    if (user) {
      state.user = user
    }
    if (displayName) {
      state.user.displayName = displayName
    }
  },
  setDashboards(state, { dashboards }) {
    state.dashboards = dashboards
  },
  setMessage: (state, { text, type }) => {
    state.flashMessages.push({
      text,
      type
    })
  },
  updateLinkData(
    state,
    {
      title,
      urlDomain,
      urlPath,
      linkServices,
      videos,
      description,
      releaseDate,
      themeType,
      tags,
      artists,
      services,
      isDashboardProfile,
      credit,
      initialServicesView,
      audioPreviewValid,
      audioPreviewUrl,
      displayProfileId
    }
  ) {
    if (title || title === '') {
      state.linkData.title = title
    }
    if (urlDomain || urlDomain === '') {
      state.linkDataFull.urlDomain = urlDomain
    }
    if (urlPath || urlPath === '') {
      state.linkDataFull.urlPath = urlPath
    }
    if (description || description === '') {
      state.linkData.description = description
    }
    if (credit || credit === '') {
      state.linkData.credit = credit
    }

    state.linkData.releaseDate = releaseDate || state.linkData.releaseDate
    state.linkData.themeType = themeType || state.linkData.themeType
    state.linkData.tags = tags || state.linkData.tags
    state.linkData.artists = artists || state.linkData.artists
    state.linkData.services = services || state.linkData.services
    state.linkData.initialServicesView =
      initialServicesView || state.linkData.initialServicesView
    state.linkData.displayProfileId =
      displayProfileId || state.linkData.displayProfileId
    if (isDashboardProfile !== undefined) {
      state.linkData.isDashboardProfile = isDashboardProfile !== false
    }
    state.linkData.audioPreviewUrl =
      audioPreviewUrl || state.linkData.audioPreviewUrl
    if (linkServices) {
      const videos = state.linkData.services.filter(
        (service) => service.type === 'video'
      )
      state.linkData.services = linkServices.concat(videos)
    }
    if (videos) {
      const linkServices = state.linkData.services.filter(
        (service) => service.type !== 'video'
      )
      state.linkData.services = videos.concat(linkServices)
    }
    state.linkData.audioPreviewValid =
      audioPreviewValid === true || audioPreviewValid === false
        ? audioPreviewValid
        : state.linkData.audioPreviewValid
  },
  updateDestinationText(state, { destinationText, name, title }) {
    state.linkData.services.filter((service) => {
      if (service.name === name && service.title === title) {
        service.destinationText = destinationText
      }
    })
  },
  updateServiceText(state, { name, title, url }) {
    state.linkData.services.filter((service) => {
      if (service.name === name && service.url === url) {
        service.title = title
      }
    })
  },
  updateServiceUrl(state, { urlNew, urlOld, name, urlStatus }) {
    state.linkData.services.filter((service) => {
      if (service.url === urlOld && service.name === name) {
        service.url = urlNew
        service.urlStatus = urlStatus
      }
    })
  },
  updateLoginInfo(state, { logged }) {
    state.isLogIn = logged
  }
}

export const actions = {
  /**
   * フラッシュメッセージを表示する
   * @param {String} text メッセージ内容
   * @param {String} type メッセージの種類。成功：success,エラー：error,一定時間経過でログアウト：logout
   * @param {String|Object} description メッセージの詳細説明
   */
  showFlashMessage({ commit, state }, { text, type, description }) {
    if (description) {
      const descriptionText = description.toString()
      text = `${text}(${descriptionText})`
      commit('setMessage', { text, type })
    } else {
      commit('setMessage', { text, type })
    }
    setTimeout(() => {
      commit('deleteFlashMessage', { index: state.flashMessages.length - 1 })
    }, 3000)
  },
  async updateLink({ state, dispatch, commit }, { linkId }) {
    try {
      const services = state.linkData.services.map(
        ({ urlStatus, ...keepAttrs }) => keepAttrs
      )
      commit('updateLinkData', { services })
      const res = await MeRepository.putLinksByIdWithParams(
        linkId,
        state.linkDataFull
      )

      if (res.data.code === '400') {
        throw new Error('エラーが発生しました。')
      }
      if (!res || res.status !== 200) {
        throw new Error('エラーが発生しました。')
      }
      if (res.data.message === 'Invalid URL') {
        throw new Error('このURLは使用できません')
      }
      return dispatch('showFlashMessage', {
        text: '更新されました',
        type: 'success'
      })
    } catch (e) {
      dispatch('showFlashMessage', {
        text: `${e}`,
        type: 'error'
      })
    }
  },
  async updateLinkNonRegistered({ state, dispatch, commit }, { linkId }) {
    try {
      const services = state.linkData.services.map(
        ({ urlStatus, ...keepAttrs }) => keepAttrs
      )
      commit('updateLinkData', { services })
      console.log(services)
      const res = await NonRegistered.putLinkById(linkId, state.linkDataFull)

      if (!res || res.status !== 200) {
        throw new Error('エラーが発生しました。')
      }
      if (res.data.code === '400') {
        throw new Error('エラーが発生しました。')
      }
      if (res.data.message === 'Invalid URL') {
        throw new Error('このURLは使用できません')
      }
      return dispatch('showFlashMessage', {
        text: '更新されました',
        type: 'success'
      })
    } catch (e) {
      dispatch('showFlashMessage', {
        text: `${e}`,
        type: 'error'
      })
    }
  },
  async updateUser({ commit }) {
    const resUser = await MeRepository.getMe()
    const user = resUser.data
    commit('setUser', { user })
  },
  async nuxtServerInit(
    { commit, dispatch },
    { app, route, redirect, $config }
  ) {
    try {
      if (!route.path.match(/admin/) && route.path.match(/user/)) {
        const resUser = await MeRepository.getMe()
        const resDashboards = await MeRepository.getDashBoards()
        const user = resUser.data
        const dashboards = resDashboards.data
        const indexDashboard = dashboards.findIndex(
          (dashboard) => dashboard.dashboardId === user.currentDashboardId
        )
        if (indexDashboard === -1) {
          if (app.isDesktop) {
            return redirect(302, '/login', { error: 'auth' })
          }
          return redirect(302, '/sp/login', { error: 'auth' })
        }
        if (!route.params.userId || route.params.userId === 'undefined') {
          if (app.isDesktop) {
            return redirect(`/user/${user.userId}/`)
          }
          return redirect(`/sp/user/${user.userId}/`)
        }
        commit('setUser', { user })
        commit('setDashboards', { dashboards })
      }
    } catch (e) {
      if (app.isDesktop) {
        return redirect(302, '/login', { error: 'auth' })
      }
      return redirect(302, '/sp/login', { error: 'auth' })
    }
  }
}
