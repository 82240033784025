<template>
  <div class="Error">
    <div class="Error_Wrapper">
      <h1 v-if="error.statusCode" class="Error_StatusCode">
        {{ error.statusCode }}
      </h1>
      <p class="Error_Message">
        {{ handleMessage(error) }}
      </p>
      <n-link v-if="error.statusCode === 401" class="Error_Login" to="/login">
        ログイン画面へ
      </n-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleMessage(error) {
      if (!error || !error.data) return 'エラーが発生しました'
      if (error.data.message === 'Invalid Subscription') {
        return `サブスクリプションの有効期限が切れました。
        アカウント設定画面より更新をしてください。`
      }
      if (error.statusCode === 404) {
        return 'ページが見つかりません'
      }
      if (error.statusCode === 401) {
        return 'ログイン期限が切れました'
      }
      return 'エラーが発生しました'
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '@/assets/css/variables.css';
.Error {
  position: relative;
  height: 100%;
  /* SPのmainと同じ大きさ */
  min-height: calc(100vh - 52.5px - 25px);
  margin: auto;
  @media (min-width: 751px) {
    width: var(--width-pc-container);
    height: 100vh;
  }
  &_Wrapper {
    @util center;
    text-align: center;
  }
  &_StatusCode {
    font-weight: 600;
    letter-spacing: 0.1em;
    @media (min-width: 751px) {
      margin: 0 0 10px;
      font-size: 40px;
    }
  }
  &_Message {
    font-weight: 600;
    @media (min-width: 751px) {
      font-size: 20px;
    }
  }
  &_Login {
    display: block;
    width: 180px;
    height: 45px;
    padding: 12px;
    margin: 40px auto 0;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    border: 2px solid var(--c-gray-3);
    border-radius: 50px;
    @media (min-width: 751px) {
      width: 180px;
      height: 45px;
      padding: 12px;
      margin: 40px auto 0;
      font-size: 14px;
      border: 2px solid var(--c-gray-3);
      border-radius: 50px;
    }
  }
}
</style>
<style lang="postcss">
/* footerのpadding-bottomを0に(spのリンクページ対応) */
.Default {
  .Default_Footer {
    padding-bottom: 0;
  }
}
</style>
