export default function({ route, redirect, isDesktop }) {
  try {
    const currentPath = route.fullPath
    const isTeaserPath =
      currentPath === '/' ||
      currentPath === '/about' ||
      currentPath === '/pricing' ||
      currentPath === '/privacy-policy' ||
      currentPath === '/specified-commercial-transaction' ||
      currentPath === '/form' ||
      currentPath === '/company' ||
      currentPath.match(/blog/) ||
      currentPath.match(/inDevelop=true/) ||
      currentPath.match(`/?path=`)

    const isMobilePath = currentPath.match(/\/sp\//)
    // ティザーサイトではUA判定を用いたリダイレクトはしない
    if (isTeaserPath) {
      return true
    }
    // PCでSP版のパスに飛んだ場合、PC版にリダイレクト
    if (isMobilePath && isDesktop && !currentPath.includes('/#page')) {
      redirect(currentPath.replace('/sp/', '/'))
    }
    // SPでPC版のパスに飛んだ場合、SP版にリダイレクト
    if (
      !isMobilePath &&
      !isDesktop &&
      !currentPath.includes('/#page') &&
      !currentPath.includes('/statement')
    ) {
      redirect(`/sp${currentPath}`)
    }
  } catch (e) {
    console.log(e)
  }
}
