<template>
  <nav
    v-scroll="handleScroll"
    :class="{ _isFixed: scrollY > navHeight }"
    class="TeaserNavigationBar"
  >
    <n-link to="/" class="TeaserNavigationBar_Logo">
      <Logo class="TeaserNavigationBar_LogoImage" />
    </n-link>
    <p class="TeaserNavigationBar_Message">BOOST YOUR<br />CREATIVITY</p>
    <n-link class="TeaserNavigationBar_About" to="/about">STATEMENT</n-link>
    <n-link class="TeaserNavigationBar_Pricing" to="/pricing"
      >PRICING/FUNCTION</n-link
    >
    <n-link class="TeaserNavigationBar_Blog" to="/blog/posts">NOTE</n-link>
    <a
      class="TeaserNavigationBar_Help"
      target="_blank"
      href="https://tayori.com/faq/35b154be3f7a947d36c4ad53953bf4f96d3cbc1c"
      >HELP</a
    >
    <a
      class="TeaserNavigationBar_Help"
      target="_blank"
      href="https://tayori.com/faq/35b154be3f7a947d36c4ad53953bf4f96d3cbc1c"
      >HELP</a
    >
    <a
      class="TeaserNavigationBar_Help"
      target="_blank"
      href="https://x.com/BOM_MusicTool"
    >
      <XLogo />
    </a>
    <div class="TeaserNavigationBar_Buttons">
      <n-link class="TeaserNavigationBar_Login" to="/login"
        >ログイン
        <IconUser
          class="TeaserNavigationBar_IconUser"
          :class="{ _isFixed: scrollY > navHeight }"
        />
      </n-link>
      <n-link
        class="TeaserNavigationBar_Register"
        :class="{ _isFixed: scrollY > navHeight }"
        to="/register"
        >無料登録</n-link
      >
    </div>
    <button class="TeaserNavigationBar_HumbergerButton" @click="returnShowMenu">
      <img
        class="TeaserNavigationBar_HumbergerButtonImage"
        src="../static/img/icon-humberger.png"
        alt="menu"
      />
    </button>
    <!-- SPメニュー -->
    <transition name="TeaserNavigationBar">
      <TeaserMenu v-show="showMenu" @click-close="returnShowMenu" />
    </transition>
  </nav>
</template>
<script>
import TeaserMenu from '@/components/TeaserMenu'
import IconUser from '@/static/img/icon-user.svg'
import Logo from '@/static/img/logo-bom.svg'
import XLogo from '@/static/img/icon-x-white.svg'
export default {
  components: {
    TeaserMenu,
    IconUser,
    Logo,
    XLogo
  },
  data() {
    return {
      showMenu: false,
      scrollY: 0,
      navHeight: 79
    }
  },
  methods: {
    handleScroll(e, el) {
      if (process.client) {
        this.scrollY = window.scrollY
      }
    },
    returnShowMenu() {
      return (this.showMenu = !this.showMenu)
    }
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TeaserNavigationBar {
  display: flex;
  justify-content: flex-between;
  width: 100%;
  height: 60px;
  white-space: nowrap;
  background-color: transparent;
  transition: all 250ms ease-in-out;

  @media (min-width: 1026px) {
    width: 1206px;
    margin: auto;
  }
  @media (min-width: 751px) {
    height: 79px;
    padding: 15px 0;
  }
  &._isFixed {
    /* @media (min-width: 751px) { */
    background-color: var(--c-black-base);
    transition: all 250ms ease-in-out;
    /* } */
  }
  &_Logo {
    width: 60px;
    height: 100%;
    background-color: var(--c-black-base);
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 751px) {
      width: 125px;
      height: 38.86px;
      margin: auto 0;
      background-color: initial;
    }
    &._isFixed {
      padding: 15px 0 15px;
      background-image: url('/img/logo-2.png');
      background-position: 50%;
      @media (min-width: 751px) {
        padding: 0;
        background-image: none;
      }
    }
  }
  &_LogoImage {
    width: 100%;
    height: 100%;
    padding: 15px 7.3px 15.5px 7px;
    @media (min-width: 751px) {
      padding: 0;
    }
  }
  &_Message {
    padding: 14.5px 0 13.5px;
    margin-left: 16.8px;
    font-family: Avenir;
    font-size: 12px;
    line-height: 1.4;
    color: var(--c-white-base);
    letter-spacing: 0.2em;
    @media (min-width: 751px) {
      display: none;
    }
  }
  &_HumbergerButton {
    width: 48px;
    height: 100%;
    margin-left: auto;
    @media (min-width: 751px) {
      display: none;
    }
  }
  &_HumbergerButtonImage {
    width: 16px;
    height: 18px;
    margin-left: 16px;
  }
  &_About {
    display: none;
    @media (min-width: 751px) {
      position: relative;
      display: block;
      margin: auto 0 auto 44.7px;
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      letter-spacing: 0.1em;
    }
    &:hover:after {
      @media (min-width: 751px) {
        position: absolute;
        bottom: -5px;
        display: block;
        width: 100%;
        height: 2px;
        content: '';
        background-color: var(--c-red-base);
      }
    }
  }
  &_Pricing {
    display: none;
    @media (min-width: 751px) {
      position: relative;
      display: block;
      margin: auto 0 auto 34px;
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      letter-spacing: 0.1em;
    }
    &:hover:after {
      @media (min-width: 751px) {
        position: absolute;
        bottom: -5px;
        display: block;
        width: 100%;
        height: 2px;
        content: '';
        background-color: var(--c-red-base);
      }
    }
  }
  &_Blog {
    display: none;
    @media (min-width: 751px) {
      position: relative;
      display: block;
      margin: auto 0 auto 35px;
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      letter-spacing: 0.1em;
    }
    &:hover:after {
      @media (min-width: 751px) {
        position: absolute;
        bottom: -5px;
        display: block;
        width: 100%;
        height: 2px;
        content: '';
        background-color: var(--c-red-base);
      }
    }
  }
  &_Help {
    display: none;
    @media (min-width: 751px) {
      position: relative;
      display: block;
      margin: auto 0 auto 33px;
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      letter-spacing: 0.1em;
    }
    &:hover:after {
      @media (min-width: 751px) {
        position: absolute;
        bottom: -5px;
        display: block;
        width: 100%;
        height: 2px;
        content: '';
        background-color: var(--c-red-base);
      }
    }
  }
  &_Buttons {
    display: none;
    @media (min-width: 751px) {
      display: flex;
      height: 50px;
      margin: auto 0 auto auto;
    }
  }
  &_Login {
    @media (min-width: 751px) {
      position: relative;
      height: 50px;
      padding: 9px 18px;
      margin-right: 14px;
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      line-height: 2;
    }
  }
  &_Register {
    @media (min-width: 751px) {
      height: 50px;
      padding: 16px 26px;
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      line-height: 1.1;
      letter-spacing: 0.05em;
      background-color: var(--c-black-base);
      border: 2px solid var(--c-green-3);
      border-radius: 32px;
      transition: all 250ms ease-in-out;
    }
    &._isFixed {
      @media (min-width: 751px) {
        background-color: var(--c-green-4);
        transition: all 250ms ease-in-out;
      }
    }
  }
  &_IconUser {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: var(--c-white-base);
    transition: all 250ms ease-in-out;
    &._isFixed {
      @media (min-width: 751px) {
        fill: var(--c-gray-3);
        transition: all 250ms ease-in-out;
      }
    }
  }
  /* menu animation */
  &-enter,
  &-leave-to {
    transform: translateX(100%) translateX(0%);
  }
  &-enter-active,
  &-leave-active {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
</style>
