<template>
  <div v-if="loading" class="TheLoading">
    <div class="TheLoading_LoadingWrapper">
      <div class="TheLoading_IeLoading">
        <svg class="TheLoading_SvgCircle" viewBox="0 0 128 128">
          <circle
            cx="64"
            cy="64"
            r="50"
            class="TheLoading_CircleLoader"
          ></circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: this.loaded
    }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '@/assets/css/variables';
.TheLoading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zi-theloading);
  background: var(--c-black-1);
  &_LoadingWrapper {
    @util center;
    width: 100px;
    height: 100px;
  }
  &_IeLoading {
    display: inline-block;
    transform-origin: 50%;
    animation: ie-load 1s linear infinite;
  }
  &_SvgCircle {
    width: 100px;
    vertical-align: top;
  }
  &_CircleLoader {
    fill: none;
    stroke: var(--c-white-base);
    stroke-dasharray: 314;
    stroke-dashoffset: 520;
    stroke-linecap: round;
    stroke-width: 14px;
    transform-origin: 50%;
    animation: loader 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
}

@keyframes loader {
  0% {
    stroke-dashoffset: 520;
    transform: rotate(0);
  }

  40% {
    stroke-dashoffset: 314;
  }

  100% {
    stroke-dashoffset: 0;
    transform: rotate(270deg);
  }
}

@keyframes ie-load {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
