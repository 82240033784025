import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5bb98993 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _b89629d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _6de67c1c = () => interopDefault(import('../pages/callback-spotify.vue' /* webpackChunkName: "pages/callback-spotify" */))
const _24848a8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _0bfe05f0 = () => interopDefault(import('../pages/link-page.vue' /* webpackChunkName: "pages/link-page" */))
const _4519076f = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _60317e5c = () => interopDefault(import('../pages/mockup/index.vue' /* webpackChunkName: "pages/mockup/index" */))
const _3e99c5dc = () => interopDefault(import('../pages/new-password.vue' /* webpackChunkName: "pages/new-password" */))
const _9f2d0468 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _6bd687fe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _521c4d0c = () => interopDefault(import('../pages/privacy-policy-campaign.vue' /* webpackChunkName: "pages/privacy-policy-campaign" */))
const _8c0db1e6 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _08f06d82 = () => interopDefault(import('../pages/specified-commercial-transaction.vue' /* webpackChunkName: "pages/specified-commercial-transaction" */))
const _1a39b116 = () => interopDefault(import('../pages/statement.vue' /* webpackChunkName: "pages/statement" */))
const _bcef63a2 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _6d5c8e2f = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _23408e4d = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _78299951 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _c47f87da = () => interopDefault(import('../pages/blog/posts/index.vue' /* webpackChunkName: "pages/blog/posts/index" */))
const _1d0e0412 = () => interopDefault(import('../pages/mockup/admin/index.vue' /* webpackChunkName: "pages/mockup/admin/index" */))
const _ad56d2f6 = () => interopDefault(import('../pages/mockup/forgot-password.vue' /* webpackChunkName: "pages/mockup/forgot-password" */))
const _cce2382e = () => interopDefault(import('../pages/mockup/login.vue' /* webpackChunkName: "pages/mockup/login" */))
const _db05b950 = () => interopDefault(import('../pages/mockup/new-password.vue' /* webpackChunkName: "pages/mockup/new-password" */))
const _799d4053 = () => interopDefault(import('../pages/mockup/register.vue' /* webpackChunkName: "pages/mockup/register" */))
const _f2d919ee = () => interopDefault(import('../pages/mockup/username/index.vue' /* webpackChunkName: "pages/mockup/username/index" */))
const _8dd96080 = () => interopDefault(import('../pages/non-registered/edit/index.vue' /* webpackChunkName: "pages/non-registered/edit/index" */))
const _5ad2c43d = () => interopDefault(import('../pages/sp/forgot-password.vue' /* webpackChunkName: "pages/sp/forgot-password" */))
const _2ba057a1 = () => interopDefault(import('../pages/sp/login.vue' /* webpackChunkName: "pages/sp/login" */))
const _e766dec0 = () => interopDefault(import('../pages/sp/new-password.vue' /* webpackChunkName: "pages/sp/new-password" */))
const _d2f2dcca = () => interopDefault(import('../pages/sp/register.vue' /* webpackChunkName: "pages/sp/register" */))
const _3294b1ae = () => interopDefault(import('../pages/mockup/admin/login.vue' /* webpackChunkName: "pages/mockup/admin/login" */))
const _09268ecb = () => interopDefault(import('../pages/mockup/admin/users/index.vue' /* webpackChunkName: "pages/mockup/admin/users/index" */))
const _9c06ae00 = () => interopDefault(import('../pages/mockup/username/analytics/index.vue' /* webpackChunkName: "pages/mockup/username/analytics/index" */))
const _7ebde8d0 = () => interopDefault(import('../pages/mockup/username/create/index.vue' /* webpackChunkName: "pages/mockup/username/create/index" */))
const _8f17e934 = () => interopDefault(import('../pages/mockup/username/edit/index.vue' /* webpackChunkName: "pages/mockup/username/edit/index" */))
const _28fae4a0 = () => interopDefault(import('../pages/mockup/username/profile.vue' /* webpackChunkName: "pages/mockup/username/profile" */))
const _58b292f2 = () => interopDefault(import('../pages/sp/non-registered/edit/index.vue' /* webpackChunkName: "pages/sp/non-registered/edit/index" */))
const _0aef8335 = () => interopDefault(import('../pages/mockup/admin/users/links/index.vue' /* webpackChunkName: "pages/mockup/admin/users/links/index" */))
const _0c6e2ba7 = () => interopDefault(import('../pages/mockup/username/create/scan.vue' /* webpackChunkName: "pages/mockup/username/create/scan" */))
const _4da34fc3 = () => interopDefault(import('../pages/mockup/username/create/setup.vue' /* webpackChunkName: "pages/mockup/username/create/setup" */))
const _b9363512 = () => interopDefault(import('../pages/mockup/admin/users/links/edit.vue' /* webpackChunkName: "pages/mockup/admin/users/links/edit" */))
const _a63a200a = () => interopDefault(import('../pages/blog/posts/_postId.vue' /* webpackChunkName: "pages/blog/posts/_postId" */))
const _3325ecdc = () => interopDefault(import('../pages/sp/user/_userId/index.vue' /* webpackChunkName: "pages/sp/user/_userId/index" */))
const _2aed7246 = () => interopDefault(import('../pages/admin/users/_userId/dashboards/index.vue' /* webpackChunkName: "pages/admin/users/_userId/dashboards/index" */))
const _7a1ef791 = () => interopDefault(import('../pages/admin/users/_userId/links/index.vue' /* webpackChunkName: "pages/admin/users/_userId/links/index" */))
const _210b3713 = () => interopDefault(import('../pages/sp/user/_userId/analytics/index.vue' /* webpackChunkName: "pages/sp/user/_userId/analytics/index" */))
const _57009c74 = () => interopDefault(import('../pages/sp/user/_userId/connect.vue' /* webpackChunkName: "pages/sp/user/_userId/connect" */))
const _389c6e65 = () => interopDefault(import('../pages/sp/user/_userId/create/index.vue' /* webpackChunkName: "pages/sp/user/_userId/create/index" */))
const _0cd8851a = () => interopDefault(import('../pages/sp/user/_userId/edit/index.vue' /* webpackChunkName: "pages/sp/user/_userId/edit/index" */))
const _90823800 = () => interopDefault(import('../pages/sp/user/_userId/member.vue' /* webpackChunkName: "pages/sp/user/_userId/member" */))
const _0d5a815e = () => interopDefault(import('../pages/sp/user/_userId/notification.vue' /* webpackChunkName: "pages/sp/user/_userId/notification" */))
const _4ef114ca = () => interopDefault(import('../pages/admin/users/_userId/links/edit.vue' /* webpackChunkName: "pages/admin/users/_userId/links/edit" */))
const _e87d86c0 = () => interopDefault(import('../pages/sp/user/_userId/account/preferences.vue' /* webpackChunkName: "pages/sp/user/_userId/account/preferences" */))
const _703c2ed1 = () => interopDefault(import('../pages/sp/user/_userId/account/profile.vue' /* webpackChunkName: "pages/sp/user/_userId/account/profile" */))
const _8a4ce514 = () => interopDefault(import('../pages/sp/user/_userId/create/complete.vue' /* webpackChunkName: "pages/sp/user/_userId/create/complete" */))
const _2176898c = () => interopDefault(import('../pages/sp/user/_userId/create/scan.vue' /* webpackChunkName: "pages/sp/user/_userId/create/scan" */))
const _74c29ae0 = () => interopDefault(import('../pages/sp/user/_userId/create/setup.vue' /* webpackChunkName: "pages/sp/user/_userId/create/setup" */))
const _54ea982e = () => interopDefault(import('../pages/admin/users/_userId/dashboards/_dashboards/domains.vue' /* webpackChunkName: "pages/admin/users/_userId/dashboards/_dashboards/domains" */))
const _6eb1bd9c = () => interopDefault(import('../pages/admin/users/_userId/dashboards/_dashboards/preferences.vue' /* webpackChunkName: "pages/admin/users/_userId/dashboards/_dashboards/preferences" */))
const _cc0c4864 = () => interopDefault(import('../pages/user/_userId/index.vue' /* webpackChunkName: "pages/user/_userId/index" */))
const _8b02c0f6 = () => interopDefault(import('../pages/user/_userId/analytics/index.vue' /* webpackChunkName: "pages/user/_userId/analytics/index" */))
const _01a995bc = () => interopDefault(import('../pages/user/_userId/campaigns/index.vue' /* webpackChunkName: "pages/user/_userId/campaigns/index" */))
const _589496e6 = () => interopDefault(import('../pages/user/_userId/connect.vue' /* webpackChunkName: "pages/user/_userId/connect" */))
const _ce05a99a = () => interopDefault(import('../pages/user/_userId/create/index.vue' /* webpackChunkName: "pages/user/_userId/create/index" */))
const _c60d547e = () => interopDefault(import('../pages/user/_userId/edit/index.vue' /* webpackChunkName: "pages/user/_userId/edit/index" */))
const _5f9e47d5 = () => interopDefault(import('../pages/user/_userId/linkcore-import.vue' /* webpackChunkName: "pages/user/_userId/linkcore-import" */))
const _21fdccde = () => interopDefault(import('../pages/user/_userId/linkfire-import.vue' /* webpackChunkName: "pages/user/_userId/linkfire-import" */))
const _2b68d34e = () => interopDefault(import('../pages/user/_userId/member.vue' /* webpackChunkName: "pages/user/_userId/member" */))
const _59b37c1f = () => interopDefault(import('../pages/user/_userId/notification.vue' /* webpackChunkName: "pages/user/_userId/notification" */))
const _d83d7fae = () => interopDefault(import('../pages/user/_userId/sfp-import.vue' /* webpackChunkName: "pages/user/_userId/sfp-import" */))
const _65f1ae02 = () => interopDefault(import('../pages/user/_userId/account/plan.vue' /* webpackChunkName: "pages/user/_userId/account/plan" */))
const _18f1c7dc = () => interopDefault(import('../pages/user/_userId/account/preferences.vue' /* webpackChunkName: "pages/user/_userId/account/preferences" */))
const _09af9743 = () => interopDefault(import('../pages/user/_userId/account/profile.vue' /* webpackChunkName: "pages/user/_userId/account/profile" */))
const _0defb9dc = () => interopDefault(import('../pages/user/_userId/campaigns/create.vue' /* webpackChunkName: "pages/user/_userId/campaigns/create" */))
const _2724c1ea = () => interopDefault(import('../pages/user/_userId/campaigns/edit.vue' /* webpackChunkName: "pages/user/_userId/campaigns/edit" */))
const _e27134ca = () => interopDefault(import('../pages/user/_userId/create/batch.vue' /* webpackChunkName: "pages/user/_userId/create/batch" */))
const _544cf5e8 = () => interopDefault(import('../pages/user/_userId/create/complete.vue' /* webpackChunkName: "pages/user/_userId/create/complete" */))
const _8edba6a8 = () => interopDefault(import('../pages/user/_userId/create/scan.vue' /* webpackChunkName: "pages/user/_userId/create/scan" */))
const _25ff6f5e = () => interopDefault(import('../pages/user/_userId/create/setup.vue' /* webpackChunkName: "pages/user/_userId/create/setup" */))
const _7b716458 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _5bb98993,
    name: "about"
  }, {
    path: "/admin",
    component: _b89629d0,
    name: "admin"
  }, {
    path: "/callback-spotify",
    component: _6de67c1c,
    name: "callback-spotify"
  }, {
    path: "/forgot-password",
    component: _24848a8b,
    name: "forgot-password"
  }, {
    path: "/link-page",
    component: _0bfe05f0,
    name: "link-page"
  }, {
    path: "/login",
    component: _4519076f,
    name: "login"
  }, {
    path: "/mockup",
    component: _60317e5c,
    name: "mockup"
  }, {
    path: "/new-password",
    component: _3e99c5dc,
    name: "new-password"
  }, {
    path: "/pricing",
    component: _9f2d0468,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _6bd687fe,
    name: "privacy-policy"
  }, {
    path: "/privacy-policy-campaign",
    component: _521c4d0c,
    name: "privacy-policy-campaign"
  }, {
    path: "/register",
    component: _8c0db1e6,
    name: "register"
  }, {
    path: "/specified-commercial-transaction",
    component: _08f06d82,
    name: "specified-commercial-transaction"
  }, {
    path: "/statement",
    component: _1a39b116,
    name: "statement"
  }, {
    path: "/terms-of-service",
    component: _bcef63a2,
    name: "terms-of-service"
  }, {
    path: "/admin/login",
    component: _6d5c8e2f,
    name: "admin-login"
  }, {
    path: "/admin/statistics",
    component: _23408e4d,
    name: "admin-statistics"
  }, {
    path: "/admin/users",
    component: _78299951,
    name: "admin-users"
  }, {
    path: "/blog/posts",
    component: _c47f87da,
    name: "blog-posts"
  }, {
    path: "/mockup/admin",
    component: _1d0e0412,
    name: "mockup-admin"
  }, {
    path: "/mockup/forgot-password",
    component: _ad56d2f6,
    name: "mockup-forgot-password"
  }, {
    path: "/mockup/login",
    component: _cce2382e,
    name: "mockup-login"
  }, {
    path: "/mockup/new-password",
    component: _db05b950,
    name: "mockup-new-password"
  }, {
    path: "/mockup/register",
    component: _799d4053,
    name: "mockup-register"
  }, {
    path: "/mockup/username",
    component: _f2d919ee,
    name: "mockup-username"
  }, {
    path: "/non-registered/edit",
    component: _8dd96080,
    name: "non-registered-edit"
  }, {
    path: "/sp/forgot-password",
    component: _5ad2c43d,
    name: "sp-forgot-password"
  }, {
    path: "/sp/login",
    component: _2ba057a1,
    name: "sp-login"
  }, {
    path: "/sp/new-password",
    component: _e766dec0,
    name: "sp-new-password"
  }, {
    path: "/sp/register",
    component: _d2f2dcca,
    name: "sp-register"
  }, {
    path: "/mockup/admin/login",
    component: _3294b1ae,
    name: "mockup-admin-login"
  }, {
    path: "/mockup/admin/users",
    component: _09268ecb,
    name: "mockup-admin-users"
  }, {
    path: "/mockup/username/analytics",
    component: _9c06ae00,
    name: "mockup-username-analytics"
  }, {
    path: "/mockup/username/create",
    component: _7ebde8d0,
    name: "mockup-username-create"
  }, {
    path: "/mockup/username/edit",
    component: _8f17e934,
    name: "mockup-username-edit"
  }, {
    path: "/mockup/username/profile",
    component: _28fae4a0,
    name: "mockup-username-profile"
  }, {
    path: "/sp/non-registered/edit",
    component: _58b292f2,
    name: "sp-non-registered-edit"
  }, {
    path: "/mockup/admin/users/links",
    component: _0aef8335,
    name: "mockup-admin-users-links"
  }, {
    path: "/mockup/username/create/scan",
    component: _0c6e2ba7,
    name: "mockup-username-create-scan"
  }, {
    path: "/mockup/username/create/setup",
    component: _4da34fc3,
    name: "mockup-username-create-setup"
  }, {
    path: "/mockup/admin/users/links/edit",
    component: _b9363512,
    name: "mockup-admin-users-links-edit"
  }, {
    path: "/blog/posts/:postId",
    component: _a63a200a,
    name: "blog-posts-postId"
  }, {
    path: "/sp/user/:userId",
    component: _3325ecdc,
    name: "sp-user-userId"
  }, {
    path: "/admin/users/:userId/dashboards",
    component: _2aed7246,
    name: "admin-users-userId-dashboards"
  }, {
    path: "/admin/users/:userId/links",
    component: _7a1ef791,
    name: "admin-users-userId-links"
  }, {
    path: "/sp/user/:userId?/analytics",
    component: _210b3713,
    name: "sp-user-userId-analytics"
  }, {
    path: "/sp/user/:userId?/connect",
    component: _57009c74,
    name: "sp-user-userId-connect"
  }, {
    path: "/sp/user/:userId?/create",
    component: _389c6e65,
    name: "sp-user-userId-create"
  }, {
    path: "/sp/user/:userId?/edit",
    component: _0cd8851a,
    name: "sp-user-userId-edit"
  }, {
    path: "/sp/user/:userId?/member",
    component: _90823800,
    name: "sp-user-userId-member"
  }, {
    path: "/sp/user/:userId?/notification",
    component: _0d5a815e,
    name: "sp-user-userId-notification"
  }, {
    path: "/admin/users/:userId/links/edit",
    component: _4ef114ca,
    name: "admin-users-userId-links-edit"
  }, {
    path: "/sp/user/:userId?/account/preferences",
    component: _e87d86c0,
    name: "sp-user-userId-account-preferences"
  }, {
    path: "/sp/user/:userId?/account/profile",
    component: _703c2ed1,
    name: "sp-user-userId-account-profile"
  }, {
    path: "/sp/user/:userId?/create/complete",
    component: _8a4ce514,
    name: "sp-user-userId-create-complete"
  }, {
    path: "/sp/user/:userId?/create/scan",
    component: _2176898c,
    name: "sp-user-userId-create-scan"
  }, {
    path: "/sp/user/:userId?/create/setup",
    component: _74c29ae0,
    name: "sp-user-userId-create-setup"
  }, {
    path: "/admin/users/:userId/dashboards/:dashboards/domains",
    component: _54ea982e,
    name: "admin-users-userId-dashboards-dashboards-domains"
  }, {
    path: "/admin/users/:userId/dashboards/:dashboards/preferences",
    component: _6eb1bd9c,
    name: "admin-users-userId-dashboards-dashboards-preferences"
  }, {
    path: "/user/:userId",
    component: _cc0c4864,
    name: "user-userId"
  }, {
    path: "/user/:userId?/analytics",
    component: _8b02c0f6,
    name: "user-userId-analytics"
  }, {
    path: "/user/:userId?/campaigns",
    component: _01a995bc,
    name: "user-userId-campaigns"
  }, {
    path: "/user/:userId?/connect",
    component: _589496e6,
    name: "user-userId-connect"
  }, {
    path: "/user/:userId?/create",
    component: _ce05a99a,
    name: "user-userId-create"
  }, {
    path: "/user/:userId?/edit",
    component: _c60d547e,
    name: "user-userId-edit"
  }, {
    path: "/user/:userId?/linkcore-import",
    component: _5f9e47d5,
    name: "user-userId-linkcore-import"
  }, {
    path: "/user/:userId?/linkfire-import",
    component: _21fdccde,
    name: "user-userId-linkfire-import"
  }, {
    path: "/user/:userId?/member",
    component: _2b68d34e,
    name: "user-userId-member"
  }, {
    path: "/user/:userId?/notification",
    component: _59b37c1f,
    name: "user-userId-notification"
  }, {
    path: "/user/:userId?/sfp-import",
    component: _d83d7fae,
    name: "user-userId-sfp-import"
  }, {
    path: "/user/:userId?/account/plan",
    component: _65f1ae02,
    name: "user-userId-account-plan"
  }, {
    path: "/user/:userId?/account/preferences",
    component: _18f1c7dc,
    name: "user-userId-account-preferences"
  }, {
    path: "/user/:userId?/account/profile",
    component: _09af9743,
    name: "user-userId-account-profile"
  }, {
    path: "/user/:userId?/campaigns/create",
    component: _0defb9dc,
    name: "user-userId-campaigns-create"
  }, {
    path: "/user/:userId?/campaigns/edit",
    component: _2724c1ea,
    name: "user-userId-campaigns-edit"
  }, {
    path: "/user/:userId?/create/batch",
    component: _e27134ca,
    name: "user-userId-create-batch"
  }, {
    path: "/user/:userId?/create/complete",
    component: _544cf5e8,
    name: "user-userId-create-complete"
  }, {
    path: "/user/:userId?/create/scan",
    component: _8edba6a8,
    name: "user-userId-create-scan"
  }, {
    path: "/user/:userId?/create/setup",
    component: _25ff6f5e,
    name: "user-userId-create-setup"
  }, {
    path: "/",
    component: _7b716458,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  const router = new Router(routerOptions)
  const resolve = router.resolve.bind(router)

  // encodeURI(decodeURI()) ~> support both encoded and non-encoded urls
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = encodeURI(decodeURI(to))
    }
    return resolve(to, current, append)
  }

  return router
}
