import { repository } from '../plugins/axios'
const prefix = '/api'
const resource = '/admin'

export default {
  getUsers: () => {
    return repository.get(`${prefix}${resource}/users`)
  },
  getUsersOffset: (offset) => {
    return repository.get(`${prefix}${resource}/users?offset=${offset}`)
  },
  getUsersByName: (word) => {
    return repository.get(
      `${prefix}${resource}/users?filter_profile_name=${word}`
    )
  },
  getUsersByMail: (mail) => {
    return repository.get(`${prefix}${resource}/users?filter_email=${mail}`)
  },
  getUsersByUserId: (id) => {
    return repository.get(`${prefix}${resource}/users?filter_user_id=${id}`)
  },
  getUserById: (id) => {
    return repository.get(`${prefix}${resource}/users/${id}`)
  },
  getUserListFile: (params) => {
    return repository.get(`${prefix}${resource}/users/file`, { params })
  },
  getLinks: (id, params) => {
    return repository.get(`${prefix}${resource}/users/${id}/links`, { params })
  },
  getLink: (userId, linkId) => {
    return repository.get(
      `${prefix}${resource}/users/${userId}/links/${linkId}`
    )
  },
  putLinksByIdWithParams: (userId, linkId, params) => {
    return repository.put(
      `${prefix}${resource}/users/${userId}/links/${linkId}`,
      params
    )
  },
  getDashboards: (id) => {
    return repository.get(`${prefix}${resource}/users/${id}/dashboards`)
  },
  getSubscription: (userId, dashboard) => {
    return repository.get(
      `${prefix}${resource}/users/${userId}/dashboards/${dashboard}/subscription`
    )
  },
  postSubscription: (userId, dashboard, params) => {
    return repository.post(
      `${prefix}${resource}/users/${userId}/dashboards/${dashboard}/subscription`,
      params
    )
  },
  getStatisticsUsers: (params) => {
    return repository.get(`${prefix}${resource}/statistics/users`, { params })
  },
  getStatisticsUsersTotalFile: (params) => {
    return repository.get(`${prefix}${resource}/statistics/users/total-file`)
  },
  getStatisticsLinks: (params) => {
    return repository.get(`${prefix}${resource}/statistics/links`, { params })
  },
  getStatisticsLinksTotalFile: (params) => {
    return repository.get(`${prefix}${resource}/statistics/links/total-file`)
  },
  putLinksImages: (params, options = {}) => {
    return repository.put(`${prefix}${resource}/images`, params, options)
  }
}
