import { repository } from '../plugins/axios'
const prefix = '/api'
const resource = '/non-registered'

export default {
  getRandomPath: () => {
    return repository.get(`${prefix}${resource}/url`)
  },
  postLinks: (params) => {
    return repository.post(`${prefix}${resource}/links`, params)
  },
  getLinkById: (linkId) => {
    return repository.get(`${prefix}${resource}/links/detail/${linkId}`)
  },
  putLinkById: (linkId, params) => {
    return repository.put(`${prefix}${resource}/links/detail/${linkId}`, params)
  },
  getLinkFormats: (linkId) => {
    return repository.get(`${prefix}${resource}/links/detail/${linkId}/formats`)
  },
  postLinkFormat: (linkId, params) => {
    return repository.post(
      `${prefix}${resource}/links/detail/${linkId}/formats`,
      params
    )
  },
  putLinksImages: (params, options = {}) => {
    return repository.put(`${prefix}${resource}/links/images`, params, options)
  },
  getServiceByUrl(url) {
    return repository.get(`${prefix}${resource}/services/url?url=${url}`)
  }
}
