<template>
  <div
    class="navbar bd-navbar flex-column flex-md-row navbar-dark navbar-expand bg-dark"
  >
    <ul class="navbar-nav bd-navbar-nav flex-row">
      <li class="nav-item">
        <n-link to="/mockup/username/analytics" style="color: #fff;">
          ・アナリティクス（全体）
        </n-link>
      </li>
      <li class="nav-item">
        <n-link to="/mockup/username/" style="color: #fff;">
          ・リンク一覧
        </n-link>
      </li>
    </ul>
    <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex">
      <li class="nav-item text-white">
        <img
          class="img-fluid"
          src="http://placehold.jp/50x50.png"
          alt="User Image"
        />
      </li>
      <li class="nav-item dropdown">
        <b-dropdown
          id="dropdown-1"
          text="ユーザー名"
          class="m-md-2"
          variant="primary"
        >
          <b-dropdown-item to="/mockup/username/profile">
            プロフィール設定
          </b-dropdown-item>
          <b-dropdown-item to="/mockup/login">
            ログアウト
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>
  </div>
</template>
<script>
export default {}
</script>
