import { repository } from '../plugins/axios'
const endpoint = process.env.SERVICE_API_URL
// api mock用のprefix
// const prefix = ''
const prefix = '/api'

export default {
  getPublicCampaign: (params) => {
    return repository.get(`${endpoint}${prefix}/campaigns`, { params })
  },
  postCampaignApply: (params) => {
    return repository.post(`${endpoint}${prefix}/campaigns-apply`, params)
  }
}
