import { repository } from '../plugins/axios'
const endpoint = process.env.SERVICE_API_URL
const defaultDomain = process.env.LINK_PAGE_DEFAULT_DOMAIN
const swaggerEndpoint = process.env.SWAGGER_API_URL

export default {
  getLinksFromDefaultDomain: (path) => {
    return repository.get(
      `${endpoint}/links?domain=${defaultDomain}&path=${path}`
    )
  },
  getLinksFromSwaggerManualDomain: (domain, path) => {
    return repository.get(
      `${swaggerEndpoint}/links?domain=${domain}&path=${path}`
    )
  }
}
