export default ({ store }, inject) => {
  // リンク作成用サービスオブジェクト取得
  const getServiceObject = (serviceUrl) => {
    const servicesArray = []
    let service
    for (let i = 0; i < store.state.supportedServices.length; i++) {
      const supportedService = store.state.supportedServices[i]
      if (serviceUrl.match(supportedService.pattern)) {
        service = Object.assign({}, supportedService)
        service.url = encodeURI(serviceUrl)
        servicesArray.push(service)
      }
    }
    // BOMの対応外のサービスの場合、テキストでリンクを追加する
    if (servicesArray.length === 0) {
      service = {
        name: 'other',
        pattern: '',
        title: serviceUrl.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1],
        type: 'audio',
        destinationText: 'リンク',
        url: serviceUrl
      }
      servicesArray.push(service)
    }
    return servicesArray
  }

  const getServiceObjectByName = (serviceObj) => {
    const servicesArray = []
    let service
    for (let i = 0; i < store.state.supportedServices.length; i++) {
      const supportedService = store.state.supportedServices[i]
      if (serviceObj.name === supportedService.name) {
        service = Object.assign({}, supportedService)
        service.url = serviceObj.url
        servicesArray.push(service)
      }
    }
    // BOMの対応外のサービスの場合、テキストでリンクを追加する
    if (servicesArray.length === 0) {
      service = {
        name: 'other',
        pattern: '',
        title: serviceObj.url.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1],
        type: 'audio',
        destinationText: 'リンク',
        url: serviceObj.url
      }
      servicesArray.push(service)
    }
    return servicesArray
  }

  // 文字列をシャッフルする
  const shuffleString = (inputString) => {
    if (!inputString) return inputString
    const resultArray = []
    const inputArray = inputString.split('')
    const inputArrayLength = inputArray.length
    for (let i = 0; i < inputArrayLength; i++) {
      const random = Math.floor(Math.random() * inputArray.length)
      resultArray.push(inputArray[random])
      inputArray.splice(random, 1)
    }
    return resultArray.join('')
  }

  // 一意な半角英数字を生成する
  const randomString = (num) => {
    const useString = 'abcdefghijklmnopqrstuvwxyz0123456789'
    if (typeof num !== 'number') return ''
    return Array.from(crypto.getRandomValues(new Uint8Array(num)))
      .map((n) => useString[n % useString.length])
      .join('')
  }

  inject('getServiceObject', getServiceObject)
  inject('getServiceObjectByName', getServiceObjectByName)
  inject('shuffleString', shuffleString)
  inject('randomString', randomString)
}
