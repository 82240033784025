import { repository } from '../plugins/axios'
// api mock用のprefix
// const prefix = ''
const prefix = '/api'
const resource = '/me'

export default {
  getMembers: () => {
    return repository.get(`${prefix}${resource}/members`)
  },
  putMembers: (id, params) => {
    return repository.put(`${prefix}${resource}/members/${id}`, params)
  },
  deleteMembers: (id) => {
    return repository.delete(`${prefix}${resource}/members/${id}`)
  },
  postMembers: (params) => {
    return repository.post(`${prefix}${resource}/members`, params)
  },
  putMembersRole: (id, params) => {
    return repository.put(`${prefix}${resource}/members/role/${id}`, params)
  },
  getMembersApplications: () => {
    return repository.get(`${prefix}${resource}/members/applications`)
  },
  postMembersApplications: (params) => {
    return repository.post(`${prefix}${resource}/members/applications`, params)
  },
  putMembersApplications: (id, params) => {
    return repository.put(
      `${prefix}${resource}/members/applications/${id}`,
      params
    )
  },
  getMembersInvitations: () => {
    return repository.get(`${prefix}${resource}/members/invitations`)
  },
  postMembersInvitations: (params) => {
    return repository.post(`${prefix}${resource}/members/invitations`, params)
  },
  putMembersInvitations: (id, params) => {
    return repository.put(
      `${prefix}${resource}/members/invitations/${id}`,
      params
    )
  }
}
